/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2020-12-15 11:32:08
 * @Last Modified by: hejk
 * @Last Modified time: 2021-07-27 19:12:20
 * @Description: Description
 * @Route: Route
 */
const MedicalList = () => import('@/pages/hxcm/settle/hospital-pay/information-inquiry/consumable/list-medical');
const MedicalListSub = () => import('@/pages/hxcm/settle/hospital-pay/information-inquiry/consumable/list-medical-sub');
const MedicalSettleDetail = () => import('@/pages/hxcm/settle/hospital-pay/information-inquiry/consumable/settle-detail-medical');
const MedicalPayDetail = () => import('@/pages/hxcm/settle/hospital-pay/information-inquiry/consumable/pay-detail-medical');
const MedicalTracking = () => import('@/pages/hxcm/settle/hospital-pay/information-inquiry/consumable/tracking-medical');
const MedicalTrackingSub = () => import('@/pages/hxcm/settle/hospital-pay/information-inquiry/consumable/tracking-medical-sub');
const TrackingDetail = () => import('@/pages/hxcm/settle/hospital-pay/information-inquiry/consumable/tracking-detail');


const routesMedicalInfo = [
    {
        path: 'medical-list',
        component: MedicalList,
        meta: {
            title: '应付款查询'
        }
    },
    {
        path: 'medical-list/settle-detail',
        component: MedicalSettleDetail,
        meta: {
            display: 'none',
            title: '结算单明细'
        }
    },
    {
        path: 'medical-list/pay-detail',
        component: MedicalPayDetail,
        meta: {
            display: 'none',
            title: '支付单明细'
        }
    },
    // 医联体
    {
        path: 'medical-list-sub',
        component: MedicalListSub,
        meta: {
            title: '应付款查询'
        }
    },
    {
        path: 'medical-list-sub/settle-detail',
        component: MedicalSettleDetail,
        meta: {
            display: 'none',
            title: '结算单明细'
        }
    },
    {
        path: 'medical-list-sub/pay-detail',
        component: MedicalPayDetail,
        meta: {
            display: 'none',
            title: '支付单明细'
        }
    },
    {
        path: 'medical-tracking',
        component: MedicalTracking,
        meta: {
            title: '结算明细追踪'
        }
    },
    {
        path: 'medical-tracking-sub',
        component: MedicalTrackingSub,
        meta: {
            title: '结算明细追踪'
        }
    },
    {
        path: 'medical-tracking/detail',
        component: TrackingDetail,
        meta: {
            display: 'none',
            title: '查看'
        }
    },
    {
        path: 'medical-tracking-sub/detail',
        component: TrackingDetail,
        meta: {
            display: 'none',
            title: '查看'
        }
    }
];
export default routesMedicalInfo;
