/*
 * @Author: xiaoyu
 * @Email: wangxy2@guahao.com
 * @Date: 2019-12-11
 * @Last Modified by: hejk
 * @Last Modified time: 2021-03-25 11:09:37
 * @Description: 药品目录产品管理
 */

const DrugList = () => import(/* webpackChunkName: "DrugList" */ '@/pages/hxcm/tender/catalog-manage/product/drug/list');
const DrugAdd = () => import(/* webpackChunkName: "add" */ '@/pages/hxcm/tender/catalog-manage/product/drug/add');

export default [
    {
        path: 'product/drug/list',
        component: DrugList,
        meta: {
            title: '目录产品管理'
        }
    },
    {
        path: 'product/list/add',
        component: DrugAdd,
        meta: {
            title: '新增',
            display: 'none'
        }
    }
];
