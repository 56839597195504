import View from '@/components/view';

const routes = [
    {
        path: 'project-maintain',
        component: View,
        meta: {
            title: '项目维护'
        },
        children: [
            {
                path: 'province',
                component: () => import('@/pages/hxcm/settle/common/project-maintain/province'),
                meta: {
                    title: '项目维护【省级】'
                }
            },
            {
                path: 'province/audit',
                component: () => import('@/pages/hxcm/settle/common/project-maintain/audit'),
                meta: {
                    title: '审核',
                    display: 'none'
                }
            }
        ]
    }
];
export default routes;
