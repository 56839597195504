/*
 * @Author: yanshuai
 * @Email: yanshuai@guahao.com
 * @Date: 2021-03-03 10:36:28
 * @Description: 支付模块 - 管理单位
 */
import View from '@/components/view';

const paymentSummary = () => import(/* webpackChunkName: "payment-summary" */ '@/pages/hxcm/pay/management-unit/pay-module/payment-summary');
// 管理单位结算查询
const managementSettlement = () => import(/* webpackChunkName: "management-settlement" */ '@/pages/hxcm/pay/management-unit/pay-module/management-settlement');
// 配送企业应收汇总
const distributionSummary = () => import(/* webpackChunkName: "distribution-summary" */ '@/pages/hxcm/pay/management-unit/pay-module/distribution-summary');
// 医疗机构应付汇总
const medicalSummary = () => import(/* webpackChunkName: "medical-summary" */ '@/pages/hxcm/pay/management-unit/pay-module/medical-summary');
// 交易流水查询
const transaction = () => import(/* webpackChunkName: "medical-summary" */ '@/pages/hxcm/pay/management-unit/pay-module/transaction');
// 收款付款管理
// const List = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/pay/management-unit/collection/list');
const PayDetail = () => import(/* webpackChunkName: "createPay" */ '@/pages/hxcm/pay/medical/collection/detail');
// 支付管理
const paymentList = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/pay/management-unit/payment/list');
const particulars = () => import(/* webpackChunkName: "particulars" */ '@/pages/hxcm/pay/management-unit/payment/particulars');
const log = () => import(/* webpackChunkName: "payment-log" */ '@/pages/hxcm/pay/management-unit/payment/payment-log');
const WithdrawalRecord = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/pay/delivery/pay-module/withdrawal-record');

export default [
    {
        path: 'management-unit',
        component: View,
        meta: {
            title: '支付模块'
        },
        children: [
            {
                path: 'payment-summary',
                component: paymentSummary,
                meta: {
                    title: '应付汇总'
                }
            },
            {
                path: 'management-settlement',
                component: managementSettlement,
                meta: {
                    title: '管理单位结算查询'
                }
            },
            {
                path: 'distribution-summary',
                component: distributionSummary,
                meta: {
                    title: '配送企业应收汇总'
                }
            },
            {
                path: 'medical-summary',
                component: medicalSummary,
                meta: {
                    title: '医疗机构应付汇总',
                    keepAlive: false
                }
            },
            // 医疗机构应付汇总 发票列表
            {
                path: 'medical-summary/checking-list',
                component: () => import('@/pages/hxcm/settle/common/account-checking/delivery-checking'),
                meta: {
                    title: '发票列表',
                    display: 'none'
                }
            },
            // 医疗机构应付汇总 发票详情
            {
                path: 'medical-summary/checking-list/detail',
                component: () => import('@/pages/hxcm/settle/common/settlement-apply/delivery-add'),
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'transaction',
                component: transaction,
                meta: {
                    title: '交易流水查询'
                }
            },
            /**
             * @description 以下为四级审核路由meta配置说明，公用一套vue组件即List
             * undo 对应未审核参数
             * done 对应审核参数
             * params 对应查询的切换参数前面三级对应applstas,最后一级审核对应参数为chkStas值为2
             * type 对应审批通过的url最后一级地址，check为初审 recheck为复审 review为复核  approval为审批
             */
            {
                path: 'collection/check-examine',
                component: () => import('@/pages/hxcm/pay/management-unit/collection/list-first'),
                meta: {
                    title: '业务经办初审',
                    examineType: {
                        undo: {
                            params: 'applstas',
                            value: '1'
                        },
                        done: {
                            params: 'applstasSet',
                            value: ['2', '3', '4']
                        },
                        type: 'check'
                    }
                }
            },
            {
                path: 'collection/recheck-examine',
                component: () => import('@/pages/hxcm/pay/management-unit/collection/list-second'),
                meta: {
                    title: '业务负责人复审',
                    examineType: {
                        undo: {
                            params: 'applstas',
                            value: '2'
                        },
                        done: {
                            params: 'applstasSet',
                            value: ['3', '4']
                        },
                        type: 'recheck'
                    }
                }
            },
            {
                path: 'collection/review-examine',
                component: () => import('@/pages/hxcm/pay/management-unit/collection/list-third'),
                meta: {
                    title: '财务部门复核',
                    examineType: {
                        undo: {
                            params: 'applstas',
                            value: '3'
                        },
                        done: {
                            params: 'applstasSet',
                            value: ['4']
                        },
                        type: 'review'
                    }
                }
            },
            {
                path: 'collection/approval-examine',
                component: () => import('@/pages/hxcm/pay/management-unit/collection/list-fourth'),
                meta: {
                    title: '经办负责人审批',
                    examineType: {
                        undo: {
                            params: 'applstas',
                            value: '4'
                        },
                        done: {
                            params: 'chkStas',
                            value: '2'
                        },
                        type: 'approval'
                    }
                }
            },
            // 支付审核跳转到支付单列表
            {
                path: 'collection/check-examine/list/items',
                component: () => import(/* webpackChunkName: "superviseCreatePayList" */ '@/pages/hxcm/settle/settlement-payment/drug/supervise-create-pay-list'),
                meta: {
                    title: '支付单列表',
                    display: 'none'
                }
            },
            // 支付审核跳转到结算单列表
            {
                path: 'collection/check-examine/list/items/settle-list',
                component: () => import('@/pages/hxcm/settle/settlement-payment/drug/supervise-create-settle-list'),
                meta: {
                    title: '结算单列表',
                    display: 'none'
                }
            },
            // 支付审核跳转到结算单明细
            {
                path: 'collection/check-examine/list/items/settle-list/regulator-list',
                component: () => import('@/pages/hxcm/settle/settlement-payment/drug/regulator-list'),
                meta: {
                    title: '结算单明细',
                    display: 'none'
                }
            },
            // 支付审核 发票列表
            {
                path: 'collection/check-examine/list/items/settle-list/regulator-list/checking-list',
                component: () => import('@/pages/hxcm/settle/common/account-checking/delivery-checking'),
                meta: {
                    title: '发票列表',
                    display: 'none'
                }
            },
            // 支付审核 发票详情
            {
                path: 'collection/check-examine/list/items/settle-list/regulator-list/checking-list/detail',
                component: () => import('@/pages/hxcm/settle/common/settlement-apply/delivery-add'),
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },

            // 支付审核跳转到支付单列表 业务负责人复审
            {
                path: 'collection/recheck-examine/list/items',
                component: () => import(/* webpackChunkName: "superviseCreatePayList" */ '@/pages/hxcm/settle/settlement-payment/drug/supervise-create-pay-list'),
                meta: {
                    title: '支付单列表',
                    display: 'none'
                }
            },
            // 支付审核跳转到结算单列表 业务负责人复审
            {
                path: 'collection/recheck-examine/list/items/settle-list',
                component: () => import('@/pages/hxcm/settle/settlement-payment/drug/supervise-create-settle-list'),
                meta: {
                    title: '结算单列表',
                    display: 'none'
                }
            },
            // 支付审核跳转到结算单明细 业务负责人复审
            {
                path: 'collection/recheck-examine/list/items/settle-list/regulator-list',
                component: () => import('@/pages/hxcm/settle/settlement-payment/drug/regulator-list'),
                meta: {
                    title: '结算单明细',
                    display: 'none'
                }
            },
            // 支付审核 发票列表 业务负责人复审
            {
                path: 'collection/recheck-examine/list/items/settle-list/regulator-list/checking-list',
                component: () => import('@/pages/hxcm/settle/common/account-checking/delivery-checking'),
                meta: {
                    title: '发票列表',
                    display: 'none'
                }
            },
            // 支付审核 发票详情 业务负责人复审
            {
                path: 'collection/recheck-examine/list/items/settle-list/regulator-list/checking-list/detail',
                component: () => import('@/pages/hxcm/settle/common/settlement-apply/delivery-add'),
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            // 支付审核跳转到支付单列表 财务部门复核
            {
                path: 'collection/review-examine/list/items',
                component: () => import(/* webpackChunkName: "superviseCreatePayList" */ '@/pages/hxcm/settle/settlement-payment/drug/supervise-create-pay-list'),
                meta: {
                    title: '支付单列表',
                    display: 'none'
                }
            },
            // 支付审核跳转到结算单列表 财务部门复核
            {
                path: 'collection/review-examine/list/items/settle-list',
                component: () => import('@/pages/hxcm/settle/settlement-payment/drug/supervise-create-settle-list'),
                meta: {
                    title: '结算单列表',
                    display: 'none'
                }
            },
            // 支付审核跳转到结算单明细 财务部门复核
            {
                path: 'collection/review-examine/list/items/settle-list/regulator-list',
                component: () => import('@/pages/hxcm/settle/settlement-payment/drug/regulator-list'),
                meta: {
                    title: '结算单明细',
                    display: 'none'
                }
            },
            // 支付审核 发票列表 财务部门复核
            {
                path: 'collection/review-examine/list/items/settle-list/regulator-list/checking-list',
                component: () => import('@/pages/hxcm/settle/common/account-checking/delivery-checking'),
                meta: {
                    title: '发票列表',
                    display: 'none'
                }
            },
            // 支付审核 发票详情 财务部门复核
            {
                path: 'collection/review-examine/list/items/settle-list/regulator-list/checking-list/detail',
                component: () => import('@/pages/hxcm/settle/common/settlement-apply/delivery-add'),
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            // 支付审核跳转到支付单列表 经办负责人审批
            {
                path: 'collection/approval-examine/list/items',
                component: () => import(/* webpackChunkName: "superviseCreatePayList" */ '@/pages/hxcm/settle/settlement-payment/drug/supervise-create-pay-list'),
                meta: {
                    title: '支付单列表',
                    display: 'none'
                }
            },
            // 支付审核跳转到结算单列表 经办负责人审批
            {
                path: 'collection/approval-examine/list/items/settle-list',
                component: () => import('@/pages/hxcm/settle/settlement-payment/drug/supervise-create-settle-list'),
                meta: {
                    title: '结算单列表',
                    display: 'none'
                }
            },
            // 支付审核跳转到结算单明细 经办负责人审批
            {
                path: 'collection/approval-examine/list/items/settle-list/regulator-list',
                component: () => import('@/pages/hxcm/settle/settlement-payment/drug/regulator-list'),
                meta: {
                    title: '结算单明细',
                    display: 'none'
                }
            },
            // 支付审核 发票列表 经办负责人审批
            {
                path: 'collection/approval-examine/list/items/settle-list/regulator-list/checking-list',
                component: () => import('@/pages/hxcm/settle/common/account-checking/delivery-checking'),
                meta: {
                    title: '发票列表',
                    display: 'none'
                }
            },
            // 支付审核 发票详情 经办负责人审批
            {
                path: 'collection/approval-examine/list/items/settle-list/regulator-list/checking-list/detail',
                component: () => import('@/pages/hxcm/settle/common/settlement-apply/delivery-add'),
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'collection/list/detail',
                component: PayDetail,
                meta: {
                    title: '支付单明细',
                    display: 'none'
                }

            },
            {
                path: 'payment/list',
                component: paymentList,
                meta: {
                    title: '支付管理'
                }
            },
            {
                path: 'payment/list/apply',
                component: () => import(/* webpackChunkName: "paymentListApply" */ '@/pages/hxcm/pay/management-unit/payment/apply'),
                meta: {
                    title: '多笔付款',
                    display: 'none'
                }
            },
            {
                path: 'payment/list/items',
                component: () => import(/* webpackChunkName: "superviseCreatePayList" */ '@/pages/hxcm/settle/settlement-payment/drug/supervise-create-pay-list'),
                meta: {
                    title: '支付单列表',
                    display: 'none'
                }
            },
            {
                path: 'payment/list/items/settle-list',
                component: () => import('@/pages/hxcm/settle/settlement-payment/drug/supervise-create-settle-list'),
                meta: {
                    title: '结算单列表',
                    display: 'none'
                }
            },
            {
                path: 'payment/list/items/settle-list/regulator-list',
                component: () => import('@/pages/hxcm/settle/settlement-payment/drug/regulator-list'),
                meta: {
                    title: '结算单明细',
                    display: 'none'
                }
            },
            {
                path: 'payment/list/items/settle-list/regulator-list/detail',
                component: () => import('@/pages/hxcm/pay/components/detail-regulator'),
                meta: {
                    title: '对账单详情',
                    display: 'none'
                }
            },
            {
                path: 'payment/list/items/settle-list/regulator-list/checking-list',
                component: () => import('@/pages/hxcm/settle/common/account-checking/delivery-checking'),
                // component: () => import('@/pages/hxcm/settle/mgr/checking-manage/done'),
                meta: {
                    title: '发票列表',
                    display: 'none'
                }
            },
            {
                path: 'payment/list/items/settle-list/regulator-list/checking-list/detail',
                component: () => import('@/pages/hxcm/settle/common/settlement-apply/delivery-add'),
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },

            {
                path: 'payment/list/particulars',
                component: particulars,
                meta: {
                    title: '支付单明细',
                    display: 'none'
                }
            },
            {
                path: 'payment/list/log',
                component: log,
                meta: {
                    title: '日志',
                    display: 'none'
                }
            },
            {
                path: 'withdrawal-record',
                component: WithdrawalRecord,
                meta: {
                    title: '提现记录查询'
                }
            },
            {
                path: 'account-management',
                component: () => import('@/pages/hxcm/pay/management-unit/account-management'),
                meta: {
                    title: '结算账户管理'
                }
            },
            {
                path: 'account-management/read',
                component: () => import('@/pages/hxcm/pay/management-unit/account-management/add'),
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'account-list',
                component: () => import('@/pages/hxcm/pay/management-unit/account-list/account-list'),
                meta: {
                    title: '账户列表'
                }
            },
            {
                path: 'account-list/bank',
                component: () => import('@/pages/hxcm/pay/management-unit/account-list/bank-list'),
                meta: {
                    title: '银行卡管理'
                }
            }
        ]
    }
];
