/*
 * @Author: maozg
 * @Email: maozg@guahao.com
 * @Date: 2019-11-11 13:58:41
 * @Last Modified by: maozg
 * @Last Modified time: 2019-11-11 14:37:09
 * @Description: 收退货管理模块
 */

import Drug from './drug/index';
import Consumable from './consumable/index';

export default [
    ...Drug,
    ...Consumable
];