import View from '@/components/view';

// 结算-复核管理-药品-管理单位
const settlementReview = () => import(/* webpackChunkName: "settlementReview" */ '@/pages/hxcm/settle/review-manage/drug/settlement-review');
const settlementReviewRead = () => import(/* webpackChunkName: "settlementReviewRead" */ '@/pages/hxcm/settle/review-manage/drug/settlement-review-read');
const settlementCountercheck = () => import(/* webpackChunkName: "settlementCountercheck" */ '@/pages/hxcm/settle/review-manage/drug/settlement-countercheck');
const settlementCountercheckRead = () => import(/* webpackChunkName: "settlementCountercheck" */ '@/pages/hxcm/settle/review-manage/drug/settlement-countercheck-read');

const routes = [
    {
        path: 'review-manage/drug',
        component: View,
        meta: {
            title: '复核管理'
        },
        children: [
            {
                path: 'settlement-review/list',
                component: settlementReview,
                meta: {
                    title: '结算复核'
                }
            },
            {
                path: 'settlement-review/list/read',
                component: settlementReviewRead,
                meta: {
                    display: 'none',
                    title: '查看'
                }
            },
            {
                path: 'settlement-countercheck/list',
                component: settlementCountercheck,
                meta: {
                    title: '结算复查'
                }
            },
            {
                path: 'settlement-countercheck/list/read',
                component: settlementCountercheckRead,
                meta: {
                    display: 'none',
                    title: '查看'
                }
            }
        ]
    }
];

export default routes;
