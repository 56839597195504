// 耗材-结算账户管理-医疗/配送/管理
import View from '@/components/view';

// 医疗机构
const medicalAccountManagement = () => import(/* webpackChunkName: "medicalAccountManagement" */ '@/pages/hxcm/settle/account-management/consumable/medical-account-management');
const medicalAccountManagementAdd = () => import(/* webpackChunkName: "medicalAccountManagementAdd" */ '@/pages/hxcm/settle/account-management/consumable/medical-account-management-add');
const medicalAccountManagementEdit = () => import(/* webpackChunkName: "medicalAccountManagementAdd" */ '@/pages/hxcm/settle/account-management/consumable/medical-account-management-add');
const medicalAccountManagementRead = () => import(/* webpackChunkName: "medicalAccountManagementAdd" */ '@/pages/hxcm/settle/account-management/consumable/medical-account-management-add');
const commissionedToApply = () => import(/* webpackChunkName: "commissionedToApply" */ '@/pages/hxcm/settle/account-management/consumable/commissioned-to-apply');
const medicalCommissionedTemplate = () => import(/* webpackChunkName: "commissionedToApply" */ '@/pages/hxcm/settle/account-management/consumable/medical-commissioned-template');
const medicalTemplate = () => import(/* webpackChunkName: "commissionedToApply" */ '@/pages/hxcm/settle/account-management/consumable/medical-template');

// 配送企业
const dispatchingAccountManagement = () => import(/* webpackChunkName: "dispatchingAccountManagement" */ '@/pages/hxcm/settle/account-management/consumable/dispatching-account-management');
const dispatchingAccountManagementAdd = () => import(/* webpackChunkName: "dispatchingAccountManagementAdd" */ '@/pages/hxcm/settle/account-management/consumable/dispatching-account-management-add');
const dispatchingAccountManagementEdit = () => import(/* webpackChunkName: "dispatchingAccountManagementAdd" */ '@/pages/hxcm/settle/account-management/consumable/dispatching-account-management-add');
const dispatchingAccountManagementRead = () => import(/* webpackChunkName: "dispatchingAccountManagementAdd" */ '@/pages/hxcm/settle/account-management/consumable/dispatching-account-management-add');
const dispatchingTemplate = () => import(/* webpackChunkName: "commissionedToApply" */ '@/pages/hxcm/settle/account-management/consumable/dispatching-template');

// 管理单位
const commissionedAudit = () => import(/* webpackChunkName: "commissionedAudit" */ '@/pages/hxcm/settle/account-management/consumable/commissioned-audit');
const superviseAccountManagement = () => import(/* webpackChunkName: "superviseAccountManagement" */ '@/pages/hxcm/settle/account-management/consumable/supervise-account-management');


const routes = [
    {
        path: 'account-management/medical/consumable',
        component: View,
        meta: {
            title: '结算账户管理'
        },
        children: [
            {
                path: 'commissioned-to-apply/list',
                component: commissionedToApply,
                meta: {
                    title: '电子结算委托申请'
                }
            },
            {
                path: 'commissioned-to-apply/list/template',
                component: medicalCommissionedTemplate,
                meta: {
                    title: '申请开通结算账户',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list',
                component: medicalAccountManagement,
                meta: {
                    title: '结算账户管理'
                }
            },
            {
                path: 'account-management/list/template', // 签订
                component: medicalTemplate,
                meta: {
                    title: '申请开通结算账户',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/add',
                component: medicalAccountManagementAdd,
                meta: {
                    title: '申请开通结算账户',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/edit',
                component: medicalAccountManagementEdit,
                meta: {
                    title: '编辑',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/read',
                component: medicalAccountManagementRead,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            }
        ]
    },
    {
        path: 'account-management/dispatching/consumable',
        component: View,
        meta: {
            title: '结算账户管理'
        },
        children: [
            {
                path: 'account-management/list',
                component: dispatchingAccountManagement,
                meta: {
                    title: '结算账户管理'
                }
            },
            {
                path: 'account-management/list/template', // 签订
                component: dispatchingTemplate,
                meta: {
                    title: '申请开通结算账户',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/add',
                component: dispatchingAccountManagementAdd,
                meta: {
                    title: '申请开通结算账户',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/edit',
                component: dispatchingAccountManagementEdit,
                meta: {
                    title: '编辑',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/read',
                component: dispatchingAccountManagementRead,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            }
        ]
    },
    {
        path: 'account-management/supervise/consumable',
        component: View,
        meta: {
            title: '结算账户管理'
        },
        children: [
            {
                path: 'commissioned-audit/list',
                component: commissionedAudit,
                meta: {
                    title: '医疗机构结算委托审核'
                }
            },
            {
                path: 'account-management/list',
                component: superviseAccountManagement,
                meta: {
                    title: '结算账户管理'
                }
            },
            {
                path: 'account-management/list/look',
                component: dispatchingAccountManagementRead,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
