/*
 * @Author: maozg
 * @Email: maozg@guahao.com
 * @Date: 2019-10-31 15:54:46
 * @Last Modified by: Stonerererer
 * @Last Modified time: 2022-04-06 15:24:32
 * @Description: 票据管理
 */
import View from '@/components/view';

const billList = () => import('@/pages/hxcm/settle/bill-manage/consumable/list');
const ImportPic = () => import('@/pages/hxcm/settle/bill-manage/consumable/import-pic');
const ImportExcel = () => import('@/pages/hxcm/settle/bill-manage/consumable/import-excel');
const billListHospital = () => import('@/pages/hxcm/settle/bill-manage/consumable/hospital-pay-list');


export default [
    {
        path: 'consumable/bill-manage',
        component: View,
        meta: {
            title: '票据管理'
        },
        children: [
            {
                path: 'bill-list',
                component: billList,
                meta: {
                    title: '票据维护'
                }
            },
            {
                path: 'bill-list/import-excel',
                component: ImportExcel,
                meta: {
                    title: '批量导入票据信息',
                    display: 'none'
                }
            },
            {
                path: 'bill-list/import-pic',
                component: ImportPic,
                meta: {
                    title: '批量导入发票/清单图片',
                    display: 'none'
                }
            },
            {
                path: 'bill-list-hospital',
                component: billListHospital,
                meta: {
                    title: '票据维护'
                }
            },
            {
                path: 'bill-list-hospital/import-excel',
                component: ImportExcel,
                meta: {
                    title: '批量导入票据信息',
                    display: 'none'
                }
            },
            {
                path: 'bill-list-hospital/import-pic',
                component: ImportPic,
                meta: {
                    title: '批量导入发票/清单图片',
                    display: 'none'
                }
            }
        ]
    }
];
