/*
 * @Author: maozg
 * @Email: maozg@guahao.com
 * @Date: 2019-11-11 14:00:56
 * @Last Modified by: hejk
 * @Last Modified time: 2021-12-06 11:28:27
 * @Description: 收退货管理模块
 */

import View from '@/components/view';

const ReceiveList = () => import('@/pages/hxcm/settle/goods-manage/drug/receive-list');
const ReceiveDetails = () => import('@/pages/hxcm/settle/goods-manage/drug/receive-details');
const ReceviceConfirm = () => import('@/pages/hxcm/settle/goods-manage/drug/recevice-confirm');
const ReturnList = () => import('@/pages/hxcm/settle/goods-manage/drug/return-list');
const ReturnApply = () => import('@/pages/hxcm/settle/goods-manage/drug/return-apply');
const ReturnDetail = () => import('@/pages/hxcm/settle/goods-manage/drug/return-detail');
const StoreManage = () => import('@/pages/hxcm/settle/goods-manage/drug/store-manage');
const arrivalList = () => import('@/pages/hxcm/settle/goods-manage/drug/arrival-list');


export default [
    {
        path: 'drug/goods-manage',
        component: View,
        meta: {
            title: '收退货管理'
        },
        children: [
            {
                path: 'receive-list',
                component: ReceiveList,
                meta: {
                    title: '收货管理'
                }
            },
            {
                path: 'receive-list/details',
                component: ReceiveDetails,
                meta: {
                    title: '订单明细',
                    display: 'none'
                }
            },
            {
                path: 'receive-list/confirm',
                component: ReceviceConfirm,
                meta: {
                    title: '确认收货',
                    display: 'none'
                }
            },

            {
                path: 'return-list',
                component: ReturnList,
                meta: {
                    title: '退货管理'
                }
            },
            {
                path: 'return-list/apply',
                component: ReturnApply,
                meta: {
                    title: '申请退货',
                    display: 'none'
                }
            },
            {
                path: 'return-list/apply/detail',
                component: ReturnDetail,
                meta: {
                    title: '申请退货详情',
                    display: 'none'
                }
            },
            {
                path: 'store-manage',
                component: StoreManage,
                meta: {
                    title: '库房管理'
                }
            },
            {
                path: 'arrival-list',
                component: arrivalList,
                meta: {
                    title: '到货修改'
                }
            }
        ]
    }
];
