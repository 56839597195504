import View from '@/components/view';
import Manage from '@/pages/hxcm/settle/mgr/trading-manage/manage';

const OrderDetail = () => import('@/pages/hxcm/settle/order-manage/drug/order-details');
const OrderTracking = () => import('@/pages/hxcm/settle/mgr/trading-manage/order-tracking');
const Log = () => import('@/pages/hxcm/settle/mgr/trading-manage/log');

const routes = [
    {
        path: 'trading-manage/drug',
        component: View,
        meta: {
            title: '交易管理'
        },
        children: [
            {
                path: 'manage',
                component: Manage,
                meta: {
                    title: '采购单管理'
                }
            },
            {
                path: 'manage/order-detail',
                component: OrderDetail,
                meta: {
                    title: '订单明细',
                    display: 'none'
                }
            },
            {
                path: 'manage/order-detail/log',
                component: Log,
                meta: {
                    title: '日志',
                    display: 'none'
                }
            },
            {
                path: 'order-tracking',
                component: OrderTracking,
                meta: {
                    title: '订单明细追踪'
                }
            },
            {
                path: 'order-tracking/log',
                component: Log,
                meta: {
                    title: '日志',
                    display: 'none'
                }
            },
            {
                path: 'order-plan-audit',
                component: () => import('@/pages/hxcm/settle/mgr/trading-manage/order-plan-audit'),
                meta: {
                    title: '采购计划审核'
                }
            },
            {
                path: 'order-plan-audit/review',
                component: () => import('@/pages/hxcm/settle/plan-manage/drug/review'),
                meta: {
                    title: '审核',
                    display: 'none'
                }
            },
            {
                path: 'order-plan-audit/detail',
                component: () => import('@/pages/hxcm/settle/plan-manage/drug/review'),
                meta: {
                    title: '详情',
                    display: 'none'
                }
            }
        ]
    }
];
export default routes;
