import View from '@/components/view';

const routes = [
    {
        path: 'project-maintain',
        component: View,
        meta: {
            title: '项目维护'
        },
        children: [
            {
                path: 'index',
                component: () => import('@/pages/hxcm/trade/common/project-maintain/province'),
                meta: {
                    title: '项目维护【配送端】'
                }
            },
            {
                path: 'index/deal',
                component: () => import('@/pages/hxcm/trade/common/project-maintain/audit'),
                meta: {
                    title: '处理',
                    display: 'none'
                }
            }          
        ]
    }
];
export default routes;