import View from '@/components/view';

const Review = () => import('@/pages/hxcm/settle/mgr/trade-review');
const SecondReview = () => import('@/pages/hxcm/settle/mgr/trade-review/second');
const ReviewCheck = () => import(/* webpackChunkName: "ReviewCheck-settlement-apply-add" */ '@/pages/hxcm/settle/common/settlement-apply/delivery-add');
const routes = [
    {
        path: 'trade-review/consumable',
        component: View,
        meta: {
            title: '结算复核/复查'
        },
        children: [
            {
                path: 'index',
                component: Review,
                meta: {
                    title: '结算复核'
                }
            },
            {
                path: 'index/check',
                component: ReviewCheck,
                meta: {
                    title: '审核',
                    display: 'none'
                }
            },
            {
                path: 'index/look',
                component: ReviewCheck,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'index/back',
                component: ReviewCheck,
                meta: {
                    title: '撤回',
                    display: 'none'
                }
            },
            {
                path: 'second',
                component: SecondReview,
                meta: {
                    title: '结算复查'
                }
            },
            {
                path: 'second/check',
                component: ReviewCheck,
                meta: {
                    title: '审核',
                    display: 'none'
                }
            },
            {
                path: 'second/look',
                component: ReviewCheck,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'second/back',
                component: ReviewCheck,
                meta: {
                    title: '撤回',
                    display: 'none'
                }
            }
        ]
    }
];
export default routes;
