import View from '@/pages/index';
// 工作台
import home from './home/index';
// 按照功能-交易
// 医疗机构端
import catalogManagement from './catalog-management/index';// 引入目录管理模块路由-医疗机构
import PlanManage from './plan-manage';
import GoodsManage from './goods-manage/index'; // 收退货管理
import OrderManage from './order-manage/index'; // 采购订单管理
import shortageMessage from './shortage-message/index';// 引入短缺信息/备案采购管理-医疗机构
import AgreementManage from './agreement-manage/index'; // 购销协议管理
import Purchase from './purchase/index'; // 带量采购统计

// 配送企业端
import distributionDirectory from './distribution-directory/index';// 引入目录管理模块路由-配送企业
import sendReturnMessage from './send-return-message/index';// 发货退货管理-配送企业
import BillManage from './bill-manage/index'; // 票据管理
import shortageDistribution from './shortage-distribution/index';// 短缺药品/耗材信息填报-配送企业

// 管理端
import hangingManagement from './hanging-management/index';// 挂网目录查询-管理机构
import recordPurchase from './record-purchase/index';// 备案采购管理-管理机构
import secondNegotiation from './second-negotiation/index';// 二次议价信息管理-管理机构

// 按照功能-结算
import SettlementManage from './settlement-manage/index'; // 结算管理-配送企业、医疗机构
import reviewManage from './review-manage/index';// 复核管理-管理单位
import checkingManag from './checking-manage/index';// 对账管理
import informationInquiry from './information-query/index';// 信息查询
import solutionConfiguration from './solution-configuration/index';// 结算方式配置-管理单位
import accountManagement from './account-management/index';// 结算账户管理-管理单位/医疗机构/配送企业
import settlementPayment from './settlement-payment/index';// 结算账户管理-管理单位/医疗机构

// 国家项目报量管理
import QuantityManage from './quantity-manage'; // 国家项目报量管理

// 三方协议管理
import threeTripartite from './three-tripartite';
// 生产企业-二次议价
import productionCatalog from './production-catalog';

// 带量采购管理
import item from './item';

// 支付管理
import PaymentManage from './payment-manage'; 

// 医院自付
import HospitalPay from './hospital-pay';

import settlementApply from './settlement-apply';
import AccountChecking from './account-checking';

import Mgr from './mgr';
import Product from './product';
import Medical from './medical';
import Delivery from './delivery';

import linkagePriceReduction from './linkage-price-reduction';
import errDataDeal from './err-data-deal';
import ReceivablePayable from './receivable-payable';

const routes = [
    {
        path: 'trade',
        component: View,
        meta: {
            title: '交易结算系统'
        },
        children: [
            ...home,
            ...catalogManagement,
            ...PlanManage,
            ...GoodsManage,
            ...OrderManage,
            ...shortageMessage,
            ...AgreementManage,
            ...distributionDirectory,
            ...sendReturnMessage,
            ...BillManage,
            ...shortageDistribution,
            ...hangingManagement,
            ...recordPurchase,
            ...secondNegotiation,
            // 结算
            ...SettlementManage,
            ...reviewManage,
            ...checkingManag,
            ...informationInquiry,
            ...solutionConfiguration,
            ...accountManagement,
            ...settlementPayment,
            ...QuantityManage,
            // 三方协议管理
            ...threeTripartite,
            // 生产企业-二次议价
            ...productionCatalog,
            // 带量采购统计
            ...Purchase,
            ...HospitalPay,
            ...settlementApply,
            ...AccountChecking,
            ...Mgr,
            ...item,
            ...PaymentManage,
            ...Product,
            ...Medical,

            ...Delivery,
            ...linkagePriceReduction,
            ...errDataDeal,
            ...ReceivablePayable
        ]
    }
];

export default routes;
