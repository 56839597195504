import View from '@/components/view';
import TradingManage from '../pagesRoutes/trading-manage';
import { drugRoutes } from '../pagesRoutes/chosed';
import SignRoutes from '../pagesRoutes/contract-signing';

const routes = [
    {
        path: 'drug',
        component: View,
        meta: {
            title: '药品'
        },
        children: [
            ...TradingManage,
            ...drugRoutes,
            ...SignRoutes
            
        ]   
    }
];
export default routes;