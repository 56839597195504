import View from '@/components/view';

// import LimitPriceSearch from '@/pages/hxcm/trade/catalog-management/consumable/limit-price-search';
const LimitPriceSearch = () => import(/* webpackChunkName: "limit-price-search" */ '@/pages/hxcm/trade/catalog-management/consumable/limit-price-search');
const AddProductSearch = () => import(/* webpackChunkName: "add-product-search" */ '@/pages/hxcm/trade/catalog-management/consumable/add-product-search');
const CatalogAdjustSearch = () => import(/* webpackChunkName: "catalog-adjust-search" */ '@/pages/hxcm/trade/catalog-management/consumable/catalog-adjust-search');

const routes = [
    {
        path: 'catalog-management',
        component: View,
        meta: {
            title: '目录管理'
        },
        children: [
            {
                path: 'limit-price-search',
                component: LimitPriceSearch,
                meta: {
                    title: '限价挂网目录查询'
                }
            },
            {
                path: 'add-product-search',
                component: AddProductSearch,
                meta: {
                    title: '新增产品查询'
                }
            },
            {
                path: 'catalog-adjust-search',
                component: CatalogAdjustSearch,
                meta: {
                    title: '目录调整查询'
                }
            }
        ]
    }
];
export default routes;