/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2021-08-20 10:51:28
 * @Description: 错误数据处理
 */
import Drug from './drug';

export default [
    ...Drug
];
