/*
 * @Author: yanghy5
 * @Email: yanghy5@guahao.com
 * @Date: 2019-10-28 10:12:33
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-03-17 14:15:14
 * @Description: 资质库管理
 * @Route: Route
 */

import View from '@/components/view';
import Enterprise from './enterprise';

export default [
    {
        path: 'qualify-manage',
        component: View,
        meta: {
            title: '资质管理'
        },
        children: [
            ...Enterprise
        ]
    }
];
