/*
 * @Author: maozg
 * @Email: maozg@guahao.com
 * @Date: 2019-10-31 15:54:46
 * @Last Modified by: hejk
 * @Last Modified time: 2021-07-16 16:05:49
 * @Description: 采购订单管理模块
 */
import View from '@/components/view';

const List = () => import('@/pages/hxcm/settle/order-manage/drug/order-list');
const OrderDetail = () => import('@/pages/hxcm/settle/order-manage/drug/order-details');
const PlanDetail = () => import('@/pages/hxcm/settle/order-manage/drug/plan-details');
const OrderTracking = () => import('@/pages/hxcm/settle/order-manage/drug/order-tracking');
const Log = () => import('@/pages/hxcm/settle/order-manage/drug/log');
const OrderEvaluate = () => import('@/pages/hxcm/settle/order-manage/drug/order-evaluate');


export default [
    {
        path: 'drug/order-manage',
        component: View,
        meta: {
            title: '采购订单管理'
        },
        children: [
            {
                path: 'order-list',
                component: List,
                meta: {
                    title: '采购订单管理'
                }
            },
            {
                path: 'order-list/log',
                component: Log,
                meta: {
                    title: '日志',
                    display: 'none'

                }
            },
            {
                path: 'order-list/detail',
                component: OrderDetail,
                meta: {
                    title: '订单明细',
                    display: 'none'
                }
            },
            {
                path: 'order-list/plan-detail',
                component: PlanDetail,
                meta: {
                    title: '采购计划明细',
                    display: 'none'
                }
            },
            {
                path: 'order-tracking',
                component: OrderTracking,
                meta: {
                    title: '订单明细追踪'

                }
            },
            {
                path: 'order-tracking/log',
                component: Log,
                meta: {
                    title: '日志',
                    display: 'none'

                }
            },
            {
                path: 'order-evaluate',
                component: OrderEvaluate,
                meta: {
                    title: '采购订单评价'
                }
            },
            {
                path: 'order-evaluate/detail',
                component: OrderDetail,
                meta: {
                    title: '订单明细',
                    display: 'none'
                }
            },
            {
                path: 'order-evaluate/plan-detail',
                component: PlanDetail,
                meta: {
                    title: '采购计划明细',
                    display: 'none'
                }
            }
        ]
    }
];
