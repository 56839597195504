/*
 * @Author: maozg
 * @Email: maozg@guahao.com
 * @Date: 2020-01-01 14:17:41
 * @Last Modified by: maozg
 * @Last Modified time: 2020-03-03 18:06:09
 * @Description: 耗材-工作台
 */
const MedHome = () => import('@/pages/hxcm/trade/home/consumable/med-home');
const DelvHome = () => import('@/pages/hxcm/trade/home/consumable/delv-home');
const AdmHome = () => import('@/pages/hxcm/trade/home/consumable/adm-home');

const routes = [
    {
        path: 'consumable/med/home',
        component: MedHome,
        meta: {
            title: '工作台'
        }
    },
    {
        path: 'consumable/delv/home',
        component: DelvHome,
        meta: {
            title: '工作台'
        }
    },
    {
        path: 'consumable/adm/home',
        component: AdmHome,
        meta: {
            title: '工作台'
        }
    }
];

export default routes;