const host = '//pre-api.bqex.com';
export default {
    baseUrl: `${host}/code`,
    // wedataOrigin: 'http://192.168.94.22:9000',
    // 服务集合
    // baseOss: {
    //     '/base': 'http://hxbase.heisea.cn/'
    // },
    // 上传
    filePath: `${host}/code/hxTcmeSettle/web/trade/comp/file/query`, // 文件信息
    uploadPath: `${host}/code/hxTcmeSettle/web/trade/comp/file/upload`
};
