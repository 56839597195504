/*
 * @Author: maozg
 * @Email: maozg@guahao.com
 * @Date: 2019-12-12 16:00:54
 * @Last Modified by: zhujial
 * @Last Modified time: 2023-08-04 14:02:05
 * @Description: 交易结算公共接口
 */
import Fetch from '@/utils/fetch';
import Configs from '@/config';

// const isUpms = process.env.MODE === 'upms';

export default {
    // 模糊查询匹配企业
    queryOrg: '/web/trans/basesys/queryord', // 0运营平台,1监管机构（医保单位）,2医疗机构（定点医院）,3生产企业,4配送企业,5代理企业,6生产及代理,7生产及配送,8代理及配送,9生产代理及配送,10采购单位（定点药店）,11医院仓库
    // 查询配送企业信息
    queryDevl: '/web/trans/delvlist/query_by_id',
    // 查询所有的医保机构 
    medinsQuery: '/web/trans/medinshi/hi_query',
    // 结算查询文件共用接口（列表使用，目前只应用到结算pdf列表预览,单个文件查询）
    // 结算查询文件共用接口（列表使用，目前只应用到票据维护/结算pdf列表预览,单个文件查询）
    filesQuery: Configs.filePath,
    // 票据维护上传（列表使用，目前只应用到票据维护）
    fileUpload: Configs.uploadPath,

    // 结算查询文件共用接口（单独使用，目前只应用到结算pdf列表预览,单个文件查询）
    fileSingle: params => Fetch({
        url: Configs.uploadPath,
        method: 'get',
        params
    })
};
