/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-04-17 10:24:05
 * @Last Modified by: Stoner
 * @Last Modified time: 2022-04-08 10:23:17
 * @Description: 中药饮片管理后台模块路由
 */

import View from '@/pages/index';
import settle from './settle';
import pay from './pay';
import Trade from './trade'; // 交易结算模块路由
import tender from './tender';
// 公告管理
export default [{
    path: '/cm-settle',
    component: View,
    meta: {
        title: '中药饮片管理后台',
        noTag: true
    },
    children: [
        ...settle,
        ...pay,
        ...Trade,
        ...tender
    ]
}];
// 新原型图路由结构
// export default [
//     {
//         path: '/hxcm',
//         component: View,
//         meta: {
//             title: '中药饮片管理后台',
//             noTag: true
//         },

//     },
//     ...declare,
//     ...productManage,
//     ...order,
//     ...varieties,
//     ...notice,
//     ...InforInq
// ];
