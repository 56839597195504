/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2021-05-21 09:24:42
 * @Last Modified by: caoyp
 * @Last Modified time: 2021-06-08 20:15:05
 * @Description: 审核数据统计
 */

const ReviewList = () => import(/* webpackChunkName: "statistics-list" */ '@/pages/hxcm/tender/data-statistics/list');

export default [
    {
        path: 'data-statistics/list',
        component: ReviewList,
        meta: {
            title: '审核数据统计'
        }
    }
];
