import Field from '@heisea/field';
import dyField from './dy-field';

export default {
    data() {
        return {
            tableConfigData: [],
            searchConfigData: [],
            operation: [],
            extFieldObj: {},
            fieldObj: null,
            defaultListCode: ''
        };
    },
    created() {
        this.fieldObj = new Field({
            desc: 'Val',
            data: {
                ...dyField,
                ...this.extFieldObj
            }
        });
    },
    methods: {
        getFormInfo(keys, attr, s) {
            return this.fieldObj.getFormInfo(keys, attr, s);
        },
        getTableInfo(keys, attr, s) {
            return this.fieldObj.getTableInfo(keys, attr, s);
        },
        getFieldName(key) {
            return this.fieldObj.getFieldName(key);
        },
        /**
         * 获取动态配置
         * @param {*} options 
         * params: 请求参数 选填
         * callback: 自定义回调函数 选填  callback({ table: 列表展示内容（不含操作）,search: 搜索展示内容 })
         */
        async getFieldConfig({ params, callback } = {}) {
            await this.$fetch({
                url: '/web/trans/base/listFld/find_by_key',
                // url: '/test/filed',
                method: 'get',
                params: {
                    listCode: this.defaultListCode,
                    ...params
                }
            }).then(res => {
                if (res.code !== 0 && !Array.isArray(res.data)) {
                    return;
                }
                const searchArr = [];
                const tableArr = [];
                res.data.forEach(item => {
                    const { disp, quryFlag, quryType, fldName, fldKey } = item;
                    const filed = {
                        label: fldName,
                        setName: fldKey
                    };
                    if (disp === '0') {
                        tableArr.push(filed);
                    }
                    if (quryFlag === '0') {
                        if (quryType === '1') {
                            filed.type = 'input';
                        } else if (quryType === '2') {
                            filed.name = `${fldName}SELECT`;
                            filed.type = 'select';
                        } else if (quryType === '3') {
                            filed.name = `${fldName}SEARCH`;
                            filed.type = 'search';
                        } else if (quryType === '4') {
                            let defaultExtFields = [`${fldKey}Begin`, `${fldKey}End`];
                            if (fldKey.includes('-')) {
                                defaultExtFields = fldKey.split('-');
                            }
                            filed.extraFields = defaultExtFields;
                            filed.noSubmit = true;
                            filed.type = 'daterange';
                        }
                        searchArr.push(filed);
                    }
                });
                const tableConfigData = this.getTableInfo(tableArr);
                const searchConfigData = this.getFormInfo(searchArr, {}, ['searcher']);
                this.tableConfigData = tableConfigData;
                this.searchConfigData = searchConfigData;
                if (typeof callback === 'function') {
                    callback({
                        table: tableConfigData,
                        search: searchConfigData,
                        data: res.data
                    });
                } else {
                    this.tableConfig.data = [...tableConfigData, ...this.operation];
                    this.searchConfig.data[0].data = searchConfigData;
                    this.tbLoad = true;
                }
            });
        }
    }
};
