/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2021-07-03 16:32:21
 * @Last Modified by: yanchunl
 * @Last Modified time: 2021-08-05 10:16:39
 * @Description: 限价调整管理
 */
import View from '@/components/view';

const Publicity = () => import(/* webpackChunkName: "publicity" */ '@/pages/hxcm/tender/price-limit-adjust/publicity');
const Promulgate = () => import(/* webpackChunkName: "promulgate" */ '@/pages/hxcm/tender/price-limit-adjust/promulgate');
const Launch = () => import(/* webpackChunkName: "price-limit-launch" */ '@/pages/hxcm/tender/price-limit-adjust/launch');
const Sure = () => import(/* webpackChunkName: "price-limit-sure" */ '@/pages/hxcm/tender/price-limit-adjust/sure');
const Detail = () => import(/* webpackChunkName: "price-limit-detail" */ '@/pages/hxcm/tender/price-limit-adjust/detail');


export default [
    {
        path: 'price-limit-adjust',
        meta: {
            title: '限价调整管理'
        },
        component: View,
        children: [
            {
                path: 'publicity',
                meta: {
                    title: '限价调整公示'
                },
                component: Publicity
            },
            {
                path: 'promulgate',
                meta: {
                    title: '限价调整公示' // 企业端公示
                },
                component: Promulgate
            },
            {
                path: 'launch',
                meta: {
                    title: '发起限价调整'
                },
                component: Launch
            },
            {
                path: 'launch/detail',
                meta: {
                    title: '详情',
                    display: 'none'
                },
                component: Detail
            },
            {
                path: 'launch/add',
                meta: {
                    title: '发起限价',
                    display: 'none'
                },
                component: Detail
            },
            {
                path: 'sure',
                meta: {
                    title: '限价调整确认'
                },
                component: Sure
            },
            {
                path: 'sure/detail',
                meta: {
                    title: '详情',
                    display: 'none'
                },
                component: Detail
            }
        ]
    }
];
