/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-11
 * @lastModifiedBy yanghy
 * @lastModifiedTime 18:14
 * @route
 * @description 管理端首页
 */

const Index = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/home-page/outside-item/regulator-org/index');
const NoticeList = () => import(/* webpackChunkName: "notice-list" */ '@/pages/hxcm/tender/home-page/inside-item/notice-list');
const NoticeDetail = () => import(/* webpackChunkName: "notice-detail" */ '@/pages/hxcm/tender/home-page/inside-item/notice-detail');
const OuterNoticeList = () => import(/* webpackChunkName: "outer-notice-list" */ '@/pages/hxcm/tender/home-page/outside-item/regulator-org/outer-notice-list');
const OuterNoticeDetail = () => import(/* webpackChunkName: "outer-notice-detail" */ '@/pages/hxcm/tender/home-page/outside-item/regulator-org/outer-notice-detail');

export default [
    {
        path: 'outside-item/regulator-org/index',
        component: Index,
        meta: {
            title: '项目工作台'
        }
    },
    {
        path: 'outside-item/regulator-org/index/notice-list',
        component: OuterNoticeList,
        meta: {
            title: '公告通知列表',
            display: 'none'
        }
    },
    {
        path: 'outside-item/regulator-org/index/notice-list/detail',
        component: OuterNoticeDetail,
        meta: {
            title: '公告通知详情',
            display: 'none'
        }
    },
    {
        path: 'outside-item/regulator-org/index/policy-list',
        component: OuterNoticeList,
        meta: {
            title: '政策文件列表',
            display: 'none'
        }
    },
    {
        path: 'outside-item/regulator-org/index/policy-list/detail',
        component: OuterNoticeDetail,
        meta: {
            title: '政策文件详情',
            display: 'none'
        }
    },
    {
        path: 'inside/project-index/notice-list',
        component: NoticeList,
        meta: {
            title: '通知公告列表',
            display: 'none'
        }
    },
    {
        path: 'inside/project-index/notice-list/detail',
        component: NoticeDetail,
        meta: {
            title: '通知公告详情',
            display: 'none'
        }
    },
    {
        path: 'inside/project-index/help-list',
        component: NoticeList,
        meta: {
            title: '帮助中心列表',
            display: 'none'
        }
    },
    {
        path: 'inside/project-index/help-list/detail',
        component: NoticeDetail,
        meta: {
            title: '帮助中心详情',
            display: 'none'
        }
    }
];
