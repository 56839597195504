
/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 11:38
 * @route
 * @description 配送方案管理
 */

import ProductManage from './product-manage';
import ExpressManage from './express-manage';
import RegulatroManage from './regulator-manage';

const ExpressSign = () => import(/* webpackChunkName: "confirm" */ '@/pages/hxcm/tender/express-manage/list');
const ExpressDrugDetail = () => import(/* webpackChunkName: "drug-detail" */ '@/pages/hxcm/tender/express-manage/drug-detail');
const ExpressConsDetail = () => import(/* webpackChunkName: "consumable-detail" */ '@/pages/hxcm/tender/express-manage/consumable-detail');
const SignDetail = () => import(/* webpackChunkName: "detail" */ '@/pages/hxcm/tender/express-manage/read');
const Sign = () => import(/* webpackChunkName: "detail" */ '@/pages/hxcm/tender/express-manage/sign');
export default [
    ...ProductManage,
    ...ExpressManage,
    ...RegulatroManage,
    {
        path: 'express-manage/regulator/express-sign',
        component: ExpressSign,
        meta: {
            title: '配送签约查询'
            // display: 'none'
        }
    },
    {
        path: 'express-manage/product/express-sign',
        component: ExpressSign,
        meta: {
            title: '配送签约管理'
            // display: 'none'
        }
    },
    {
        path: 'express-manage/regulator/express-sign/drug-detail',
        component: ExpressDrugDetail,
        meta: {
            title: '明细',
            display: 'none'
        }
    },
    {
        path: 'express-manage/regulator/express-sign/consumable-detail',
        component: ExpressConsDetail,
        meta: {
            title: '明细',
            display: 'none'
        }
    },
    {
        path: 'express-manage/product/express-sign/drug-detail',
        component: ExpressDrugDetail,
        meta: {
            title: '明细',
            display: 'none'
        }
    },
    {
        path: 'express-manage/product/express-sign/consumable-detail',
        component: ExpressConsDetail,
        meta: {
            title: '明细',
            display: 'none'
        }
    },
    {
        path: 'express-manage/product/express-sign/detail',
        component: SignDetail,
        meta: {
            title: '查看',
            display: 'none'
        }
    },
    {
        path: 'express-manage/regulator/express-sign/detail',
        component: SignDetail,
        meta: {
            title: '查看',
            display: 'none'
        }
    },
    {
        path: 'express-manage/enterprise/express-sign/sign',
        component: Sign,
        meta: {
            title: '签订',
            display: 'none'
        }
    }
    
];
