/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-11
 * @lastModifiedBy yanghy
 * @lastModifiedTime 18:14
 * @route
 * @description 发布结果查询
 */

import View from '@/components/view';
import Negotiate from './negotiate';
import Bidding from './bidding';

export default [
    {
        path: 'publish-result',
        component: View,
        meta: {
            title: '发布结果查询'
        },
        children: [
            ...Negotiate,
            ...Bidding
        ]
    }
];
