/*
 * @Author: shandan
 * @Email: shandan@guahao.com
 * @Date: 2019-11-30
 * @Last Modified by: shandan
 * @Last Modified time: 2021-05-24 09:18:30
 * @Description: 管理端-资质预警-药品
 */

const enterpriseList = () => import(/* webpackChunkName: "enterpriseList" */ '@/pages/hxcm/tender/qualify-warning/drug/enterprise-list');
const enterpriseDetail = () => import(/* webpackChunkName: "enterpriseList" */ '@/pages/hxcm/tender/qualify-warning/drug/enterprise-detail');
const productList = () => import(/* webpackChunkName: "enterpriseList" */ '@/pages/hxcm/tender/qualify-warning/drug/product-list');
const productDetail = () => import(/* webpackChunkName: "enterpriseList" */ '@/pages/hxcm/tender/qualify-warning/drug/product-detail');

export default [
    {
        path: 'drug/enterprise-list',
        component: enterpriseList,
        meta: {
            title: '企业资质预警'
            // display: 'none'
        }
    },
    {
        path: 'drug/enterprise-list/detail',
        component: enterpriseDetail,
        meta: {
            title: '详情'
            // display: 'none'
        }
    },
    {
        path: 'drug/product-list',
        component: productList,
        meta: {
            title: '产品资质预警'
            // display: 'none'
        }
    },
    {
        path: 'drug/product-list/detail',
        component: productDetail,
        meta: {
            title: '产品资质预警'
            // display: 'none'
        }
    }
];
