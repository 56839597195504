import devConfig from './config.dev';
import prodConfig from './config.prod';
import preConfig from './config.pre';
import testConfig from './config.test';

const env = process.env.NODE_ENV;

let config;
switch (env) {
case 'development':
    config = devConfig;
    break;
case 'fiveProd':
    config = prodConfig;
    break;
case 'fiveTest':
    config = testConfig;
    break;
case 'fivePre':
    config = preConfig;
    break;
default:
    break;
}

export default Object.assign({}, config);
