/*
 * @Author: shandan
 * @Email: shandan@guahao.com
 * @Date: 2019-11-19 15:56:30
 * @Last Modified by: shandan
 * @Last Modified time: 2019-12-09 17:30:33
 * @Description: 基药状态
 */
export default [
    // {
    //     label: '全部',
    //     value: ''
    // },
    {
        label: '基药',
        value: '1'
    },
    {
        label: '非基药',
        value: '0'
    }
];