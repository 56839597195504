import url from '@/api/trade/index'; 
import basePowderArr from '@/utils/options/base-powder'; 
import netStateArr from '@/utils/options/hang-net-status'; 
import bargainStateArr from '@/utils/options/bargain-state';
import { apiOrgName } from '@/api/tender/fuzzy-search';
import { getUrlParame } from '@/utils/utils';

export default {
    品名: {
        name: 'prodName',
        type: 'input'
    },
    剂型: {
        name: 'dosform',
        type: 'input'
    },
    规格: {
        name: 'prodSpec',
        type: 'input'
    },
    包装: {
        name: 'prodPac',
        type: 'input'
    },
    生产企业: {
        name: 'prodentpName',
        type: 'input'
    },
    生产企业SEARCH: {
        label: '生产企业',
        name: 'prodentpCode',
        type: 'search',
        http: {
            url: url.queryOrg,
            params: {
                default: 'orgName',
                orgTypeCode: '3' // 0运营平台,1监管机构（医保单位）,2医疗机构（定点医院）,3生产企业,4配送企业,5代理企业,
            },
            method: 'post',
            initSwitch: false
        },
        props: {
            label: 'orgName',
            value: 'entpCode'
        }
    },
    配送企业SEARCH: {
        name: 'delventpCode',
        type: 'search',
        label: '配送企业',
        http: {
            url: url.queryOrg,
            params: {
                default: 'orgName',
                orgTypeCode: '4'
            },
            method: 'post',
            initSwitch: false
        },
        props: {
            label: 'orgName',
            value: 'entpCode'
        }
    },
    生产企业SELECT: {
        label: '生产企业',
        name: 'prodentpCode',
        type: 'select',
        http: {
            url: url.queryOrg,
            params: {
                default: 'orgName',
                orgTypeCode: '3' // 0运营平台,1监管机构（医保单位）,2医疗机构（定点医院）,3生产企业,4配送企业,5代理企业,
            },
            method: 'post'
        },
        props: {
            label: 'orgName',
            value: 'entpCode'
        }
    },
    药品统一编码: {
        name: 'prodCode',
        type: 'input'
    },
    基药属性: {
        name: 'essdrugTypeVal',
        type: 'select',
        options: basePowderArr
    },
    项目名称: {
        name: 'itemname'
    },
    项目名称SEARCH: {
        name: 'tenditmId',
        label: '项目名称',
        type: 'search',
        options: [],
        http: {
            method: 'post',
            url: '/web/trans/item/list_select',
            onBefore: query => {
                const gCode = getUrlParame('gCode');
                const prodType = gCode === 'dTrans' ? 1 : gCode === 'cTrans' ? 2 : 3;
                return {
                    itemname: query,
                    prodType
                };
            }
        }
    },
    备案采购: {
        name: 'trnsDataSouc',
        type: 'select',
        options: [
            {
                label: '是',
                value: '2'
            },
            {
                label: '否',
                value: '1'
            }
        ],
        formExtend: {
            type: 'select',
            options: [
                {
                    label: '是',
                    value: '2'
                },
                {
                    label: '否',
                    value: '1'
                }
            ],
            readonly: true
        }
    },
    '医院采购价（元）': {
        name: 'hospPurcpric',
        extend: {
            align: 'right'
        }
    },
    包装材质: {
        name: 'pacMatl'
    },
    '挂网价（元）': {
        name: 'pubonlnPric',
        extend: {
            align: 'right'
        }
    },
    '最高销售限价（元）': {
        name: 'highSelLmtpric',
        extend: {
            align: 'right'
        }
    },
    '医保支付标准（元）': {
        name: 'hiPayPric',
        extend: {
            align: 'right'
        }
    },
    产品来源: {
        name: 'prodSoucVal'
    },
    '批准文号/注册证号': {
        name: 'aprvno'
    },
    采购数量: {
        name: 'purcCnt'
    },
    配送企业: {
        name: 'delventpName'
    },
    代理企业: {
        name: 'prodentpName',
        type: 'input'
    },
    代理企业SEARCH: {
        label: '代理企业',
        name: 'dclaEntpCode',
        type: 'search',
        http: {
            url: url.queryOrg,
            params: {
                default: 'orgName',
                orgTypeCode: '5'
            },
            method: 'post',
            initSwitch: false
        },
        props: {
            label: 'orgName',
            value: 'entpCode'
        }
    },
    代理企业SELECT: {
        label: '代理企业',
        name: 'dclaEntpCode',
        type: 'select',
        http: {
            url: url.queryOrg,
            params: {
                default: 'orgName',
                orgTypeCode: '5'
            },
            method: 'post'
        },
       
        props: {
            label: 'orgName',
            value: 'entpCode'
        }
    },
    挂网状态: {
        name: 'pubonlnStasVal'
    },
    挂网状态SELECT: {
        name: 'pubonlnStas',
        label: '挂网状态',
        type: 'select',
        options: netStateArr
    },
    议价状态SELECT: {
        name: 'negoPricStas',
        type: 'select',
        label: '议价状态',
        options: bargainStateArr
    },
    医疗机构SEARCH: {
        name: 'medinsCode',
        type: 'search',
        label: '医疗机构',
        http: {
            url: url.queryOrg,
            params: {
                default: 'orgName',
                orgTypeCode: '2' // 0运营平台,1监管机构（医保单位）,2医疗机构（定点医院）,3生产企业,4配送企业,5代理企业,
            },
            method: 'post',
            initSwitch: false
        },
        props: {
            label: 'orgName',
            value: 'entpCode'
        }
    },
    医疗机构SELECT: {
        name: 'medinsCode',
        type: 'select',
        label: '医疗机构',
        http: {
            url: url.queryOrg,
            params: {
                default: 'orgName',
                orgTypeCode: '2' // 0运营平台,1监管机构（医保单位）,2医疗机构（定点医院）,3生产企业,4配送企业,5代理企业,
            },
            method: 'post'
        },
        props: {
            label: 'orgName',
            value: 'entpCode'
        }
    },
    作废类型SELECT: {
        name: 'cnclType',
        type: 'select',
        label: '作废类型',
        options: [
            {
                label: '医疗机构主动作废',
                value: '1'
            },
            {
                label: '配送企业七天未发货',
                value: '2'
            },
            {
                label: '补录单作废',
                value: '3'
            }
        ]
    },
    备案采购目录: {
        name: 'trnsDataSouc',
        label: '备案采购目录',
        type: 'select',
        options: [
            {
                label: '是',
                value: '2'
            },
            {
                label: '否',
                value: '1'
            }
        ],
        formExtend: {
            type: 'select',
            options: [
                {
                    label: '是',
                    value: '2'
                },
                {
                    label: '否',
                    value: '1'
                }
            ],
            readonly: true
        }
    },
    阅读状态SELECT: {
        name: 'readFlag',
        label: '阅读状态',
        type: 'select',
        options: [
            {
                value: '0',
                label: '未读'
            },
            {
                value: '1',
                label: '已读'
            }
        ]
    },
    订单编号: {
        name: 'ordCode',
        label: '订单编号',
        type: 'input'
    },
    类别: {
        name: 'mgtType',
        type: 'select',
        http: {
            url: '/web/trans/base/dicdata/list_select',
            params: {
                grpKey: 'MCS_MGT_TYPE'
            }
        }
    },
    报名企业SEARCH: {
        label: '投标企业',
        name: 'dclaEntpUscc',
        type: 'search',
        grid: 3,
        placeholder: '请输入',
        http: {
            url: apiOrgName,
            method: 'post',
            params: {
                default: 'orgName',
                orgTypeList: [3, 4, 5, 6, 7, 8, 9]
            },
            initSwitch: false
        },
        props: {
            value: 'uscc',
            label: 'orgName'
        }
    },
    投标企业SEARCH: {
        label: '投标企业',
        name: 'dclaEntpUscc',
        type: 'search',
        grid: 3,
        placeholder: '请输入',
        http: {
            url: apiOrgName,
            method: 'post',
            params: {
                default: 'orgName',
                orgTypeList: [3, 4, 5, 6, 7, 8, 9]
            },
            initSwitch: false
        },
        props: {
            value: 'uscc',
            label: 'orgName'
        }
    },
    试剂类别: {
        name: 'reagentType',
        type: 'select',
        options: [
            {
                value: '0',
                label: '专机专用' 
            },
            {
                value: '1',
                label: '通用'
            }
        ]
    },
    注册证是否过期: {
        name: 'expyStas',
        type: 'select',
        http: {
            url: '/web/trans/base/enums/query_list',
            params: {
                enumsName: 'ExpyStasEnum'
            }
        }

    },
    目录属性: {
        name: 'listAttrCode',
        type: 'select',
        http: {
            url: '/web/trans/base/dicdata/list_select',
            params: {
                grpKey: 'LIST_ATTR_CODE'
            }
        }
    },
    管理类别SELECT: {
        name: 'mgtType',
        label: '管理类别',
        type: 'select',
        http: {
            url: '/web/trans/base/dicdata/list_select',
            params: {
                grpKey: 'MCS_MGT_TYPE'
            }
        }
    },
    带量常用目录: {
        name: 'prodTag',
        type: 'select',
        http: {
            url: '/web/trans/base/dicdata/list_select',
            params: {
                grpKey: 'PROD_TAG'
            }
        }
    },
    药品属性SELECT: {
        name: 'drugAttrName',
        label: '药品属性',
        type: 'select',
        http: {
            url: '/web/trans/hosp_prod/drug/list_select_drugAttrName',
            params: {
                default: 'drugAttrName'
            }
        },
        props: {
            label: 'label',
            value: 'label'
        }
    },
    药品属性SEARCH: {
        name: 'drugAttrName',
        label: '药品属性',
        type: 'search',
        http: {
            url: '/web/trans/hosp_prod/drug/list_select_drugAttrName',
            params: {
                default: 'drugAttrName'
            }
        },
        props: {
            label: 'label',
            value: 'label'
        }
    }
};
