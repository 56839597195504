import View from '@/components/view';


// 医疗机构
const MedicalList = () => import('@/pages/hxcm/settle/information-inquiry/consumable/list-medical');
const MedicalListSub = () => import('@/pages/hxcm/settle/information-inquiry/consumable/list-medical-sub');
const MedicalSettleDetail = () => import('@/pages/hxcm/settle/information-inquiry/consumable/settle-detail-medical');
const MedicalPayDetail = () => import('@/pages/hxcm/settle/information-inquiry/consumable/pay-detail-medical');
const MedicalTracking = () => import('@/pages/hxcm/settle/information-inquiry/consumable/tracking-medical');
const MedicalTrackingSub = () => import('@/pages/hxcm/settle/information-inquiry/consumable/tracking-medical-sub');

// 配送企业
const ExpressList = () => import('@/pages/hxcm/settle/information-inquiry/consumable/list-express');
const ExpressSettleDetail = () => import('@/pages/hxcm/settle/information-inquiry/consumable/settle-detail-express');
const ExpressPayDetail = () => import('@/pages/hxcm/settle/information-inquiry/consumable/pay-detail-express');
const ExpressTracking = () => import('@/pages/hxcm/settle/information-inquiry/consumable/tracking-express');

// 管理机构
const RegulatorlList = () => import('@/pages/hxcm/settle/information-inquiry/consumable/list-regulator');
const RegulatorlListTop = () => import('@/pages/hxcm/settle/information-inquiry/consumable/list-regulator-top');
const RegulatorSettleDetail = () => import('@/pages/hxcm/settle/information-inquiry/consumable/settle-detail-regulator');
const RegulatorPayDetail = () => import('@/pages/hxcm/settle/information-inquiry/consumable/pay-detail-regulator');
const RegulatorTracking = () => import('@/pages/hxcm/settle/information-inquiry/consumable/tracking-regulator');
const TrackingDetail = () => import('@/pages/hxcm/settle/information-inquiry/consumable/tracking-detail');
const settlementQuery = () => import('@/pages/hxcm/settle/information-inquiry/consumable/settlement-query');
const BillTrack = () => import('@/pages/hxcm/settle/information-inquiry/consumable/bill-track');

const routes = [
    {
        path: 'information-inquiry/consumable',
        component: View,
        meta: {
            title: '信息查询'
        },
        children: [
            // 医疗机构
            {
                path: 'medical-list',
                component: MedicalList,
                meta: {
                    title: '应付款查询'
                }
            },
            {
                path: 'medical-list/settle-detail',
                component: MedicalSettleDetail,
                meta: {
                    display: 'none',
                    title: '结算单明细'
                }
            },
            {
                path: 'medical-list/pay-detail',
                component: MedicalPayDetail,
                meta: {
                    display: 'none',
                    title: '支付单明细'
                }
            },
            {
                path: 'medical-tracking',
                component: MedicalTracking,
                meta: {
                    title: '结算明细追踪'
                }
            },
            {
                path: 'medical-tracking/detail',
                component: TrackingDetail,
                meta: {
                    display: 'none',
                    title: '查看'
                }
            },
            // 医疗机构
            {
                path: 'medical-list-sub',
                component: MedicalListSub,
                meta: {
                    title: '应付款查询'
                }
            },
            {
                path: 'medical-list-sub/settle-detail',
                component: MedicalSettleDetail,
                meta: {
                    display: 'none',
                    title: '结算单明细'
                }
            },
            {
                path: 'medical-list-sub/pay-detail',
                component: MedicalPayDetail,
                meta: {
                    display: 'none',
                    title: '支付单明细'
                }
            },
            {
                path: 'medical-tracking-sub',
                component: MedicalTrackingSub,
                meta: {
                    title: '结算明细追踪'
                }
            },
            {
                path: 'medical-tracking-sub/detail',
                component: TrackingDetail,
                meta: {
                    display: 'none',
                    title: '查看'
                }
            },

            // 配送企业
            {
                path: 'express-list',
                component: ExpressList,
                meta: {
                    title: '应收款查询'

                }
            },
            {
                path: 'express-list/settle-detail',
                component: ExpressSettleDetail,
                meta: {
                    display: 'none',
                    title: '结算单明细'

                }
            },
            {
                path: 'express-list/pay-detail',
                component: ExpressPayDetail,
                meta: {
                    display: 'none',
                    title: '支付单明细'

                }
            },
            {
                path: 'express-tracking',
                component: ExpressTracking,
                meta: {
                    title: '结算明细追踪'
                }
            },
            {
                path: 'express-tracking/detail',
                component: TrackingDetail,
                meta: {
                    display: 'none',
                    title: '查看'
                }
            },

            // 管理机构
            {
                path: 'regulator-list',
                component: RegulatorlList,
                meta: {
                    title: '医疗机构结算支付信息查询'

                }
            },
            {
                path: 'top/regulator-list',
                component: RegulatorlListTop,
                meta: {
                    title: '医疗机构结算支付信息查询（上级）'

                }
            },
            {
                path: 'regulator-list/pay-detail',
                component: RegulatorPayDetail,
                meta: {
                    display: 'none',
                    title: '支付单明细'
                }
            },
            {
                path: 'regulator-list/settle-detail',
                component: RegulatorSettleDetail,
                meta: {
                    display: 'none',
                    title: '结算单明细'
                }
            },
            {
                path: 'regulator-tracking',
                component: RegulatorTracking,
                meta: {
                    title: '结算明细追踪'
                }
            },
            {
                path: 'regulator-tracking/detail',
                component: TrackingDetail,
                meta: {
                    display: 'none',
                    title: '查看'
                }
            },
            {
                path: 'settlement-query',
                component: settlementQuery,
                meta: {
                    title: '医疗机构结算支付信息查询'

                }
            },
            {
                path: 'bill-track',
                component: BillTrack,
                meta: {
                    title: '结算发票追踪'
                }
            },
            {
                path: 'bill-track/detail',
                component: () => import('@/pages/hxcm/settle/common/settlement-apply/delivery-add'),
                meta: {
                    title: '查看',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
