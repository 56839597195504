/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 11:38
 * @route
 * @description 分组管理/目录分组管理
 */

const List = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/group-manage/catalog/list');
const Log = () => import(/* webpackChunkName: "log" */ '@/pages/hxcm/tender/group-manage/catalog/log');

export default [
    {
        path: 'catalog/list',
        component: List,
        meta: {
            title: '目录分组管理'
        }
    },
    {
        path: 'catalog/list/log',
        component: Log,
        meta: {
            title: '调整日志',
            display: 'none'
        }
    }
];
