// 挂网目录查询-耗材-管理机构
import View from '@/components/view';

const hangingManagement = () => import(/* webpackChunkName: "recordPurchase" */ '@/pages/hxcm/settle/hanging-management/consumable/hanging-management');

const routes = [
    {
        component: View,
        path: 'hanging-management',
        meta: {
            title: '交易管理'
        },
        children: [
            {
                path: 'consumable/hanging-management',
                component: hangingManagement,
                meta: {
                    title: '挂网目录查询'
                }
            }
        ]

    }
];

export default routes;
