/*
 * @Author: xiaoyu
 * @Email: wangxy2@guahao.com
 * @Date: 2019-12-09
 * @Last Modified by: hejk
 * @Last Modified time: 2021-03-25 11:14:06
 * @Description: 项目内耗材-企业端页面路由
 */

const businessRegister = () => import(/* webpackChunkName: "business-register" */ '@/pages/hxcm/tender/signup-manage/enterprise/consumable/sign-up/business-registration');
const historyDetails = () => import(/* webpackChunkName: "history-details" */ '@/pages/hxcm/tender/signup-manage/enterprise/consumable/sign-up/history-details');
const catalogCheck = () => import(/* webpackChunkName: "catalog-check" */ '@/pages/hxcm/tender/signup-manage/catlog-checkout');

export default [
    {
        path: 'enterprise/consumable/register',
        component: businessRegister,
        meta: {
            title: '企业报名'
            // display: 'none'
        }
    },
    {
        path: 'enterprise/consumable/register/history',
        component: historyDetails,
        meta: {
            title: '历史详情',
            display: 'none'
        }
    },
    {
        path: 'enterprise/consumable/catalog',
        component: catalogCheck,
        meta: {
            title: '目录查看',
            display: 'none'
        }
    }
];
