/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2021-09-14 14:07:04
 * @Last Modified by: xujh
 * @Last Modified time: 2021-09-14 14:08:46
 * @Description: 配送关系型路由
 */
import View from '@/components/view';


const routes = [
    {
        path: 'delivery-relation-manage',
        component: View,
        meta: {
            title: '配送关系管理'
        },
        children: [
            {
                path: 'index',
                component: () => import(/* webpackChunkName: "delivery-relation-manage" */ '@/pages/hxcm/trade/product/delivery-relation-manage'),
                meta: {
                    title: '配送关系管理'
                }
            }
        ]
    }
];
export default routes;