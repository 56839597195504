

/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 11:38
 * @route
 * @description 直接挂网管理/直接挂网管理
 */

const ConsumableList = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/spread-net-manage/spread-net/product-org/consumable/list');
const DrugList = () => import(/* webpackChunkName: "d-list" */ '@/pages/hxcm/tender/spread-net-manage/spread-net/product-org/drug/list');
const ConsumableEntry = () => import(/* webpackChunkName: "rd-list-entry" */ '@/pages/hxcm/tender/spread-net-manage/spread-net/regulator-org/consumable/entry');

const RConsumableList = () => import(/* webpackChunkName: "RConsumableList" */ '@/pages/hxcm/tender/spread-net-manage/spread-net/regulator-org/consumable/list');
const RDrugList = () => import(/* webpackChunkName: "RDrugList" */ '@/pages/hxcm/tender/spread-net-manage/spread-net/regulator-org/drug/list');
const RDrugListEntry = () => import(/* webpackChunkName: "rd-list-entry" */ '@/pages/hxcm/tender/spread-net-manage/spread-net/regulator-org/drug/entry');

export default [
    {
        path: 'product-org/consumable/list',
        component: ConsumableList,
        meta: {
            title: '直接挂网管理'
        }
    },
    {
        path: 'product-org/drug/list',
        component: DrugList,
        meta: {
            title: '直接挂网管理'
        }
    },
    {
        path: 'regulator-org/consumable/entry',
        component: ConsumableEntry,
        meta: {
            title: '直接挂网管理'
        }
    },
    {
        path: 'regulator-org/consumable/entry/list',
        component: RConsumableList,
        meta: {
            title: '直接挂网详情',
            display: 'none'
        }
    },
    {
        path: 'regulator-org/drug/entry/list',
        component: RDrugList,
        meta: {
            title: '直接挂网详情',
            display: 'none'
        }
    },
    {
        path: 'regulator-org/drug/entry',
        component: RDrugListEntry,
        meta: {
            title: '直接挂网管理'
        }
    }
];
