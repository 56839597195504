/*
 * @Author: lina
 * @Email: lina5@guahao.com
 * @Date: 2020-03-03 10:28:43
 * @Last Modified by: hejk
 * @Last Modified time: 2021-03-25 11:10:27
 * @Description: 采购数据统计
 * @Route: Route
 */

const PurTotal = () => import(/* webpackChunkName: "purchase-list" */ '@/pages/hxcm/tender/quantity-statistics/drug/data-list');
const DrugFill = () => import(/* webpackChunkName: "quantity-fill" */ '@/pages/hxcm/tender/quantity-manage/drug/fill');

export default [
    {
        path: 'drug/list',
        component: PurTotal,
        meta: {
            title: '采购数据汇总',
            flag: 'quantity'
        }
    },
    {
        path: 'drug/list/fill',
        component: DrugFill,
        meta: {
            title: '采购填报',
            flag: 'quantity',
            display: 'none'
        }
    }
];
