import View from '@/components/view';


const administrationList = () => import(/* webpackChunkName: "settlementConfiguration" */ '@/pages/hxcm/trade/three-tripartite/consumable/administration-list');
const distributionList = () => import(/* webpackChunkName: "productSettlementConfiguration" */ '@/pages/hxcm/trade/three-tripartite/consumable/distribution-list');
const medicalList = () => import(/* webpackChunkName: "settlementProcess" */ '@/pages/hxcm/trade/three-tripartite/consumable/medical-list');
const productionList = () => import(/* webpackChunkName: "medicalTreatmentAlliance" */ '@/pages/hxcm/trade/three-tripartite/consumable/production-list');
// 新增三方协议
const agreetmentProductList = () => import(/* webpackChunkName: "medicalTreatmentAlliance" */ '@/pages/hxcm/trade/three-tripartite/consumable/agreetment-product-list');
// 修改约定量
const agreedQuantity = () => import(/* webpackChunkName: "administrationList" */ '@/pages/hxcm/trade/three-tripartite/consumable/agreed-quantity');
// 三方协议-签章
const sign = () => import(/* webpackChunkName: "sign" */ '@/pages/hxcm/trade/three-tripartite/consumable/sign');

const routes = [
    {
        path: 'consumable/three-tripartite',
        component: View,
        meta: {
            title: '三方协议管理'
        },
        children: [
            {
                path: 'administration-list',
                component: administrationList, // 管理端
                meta: {
                    title: '三方协议管理'
                }
            },
            {
                path: 'administration-list/quantity',
                component: agreedQuantity, // 管理修改约定量
                meta: {
                    title: '修改约定量',
                    display: 'none'
                }
            },
            {
                path: 'distribution-list',
                component: distributionList, // 配送
                meta: {
                    title: '三方协议管理'
                }
            },
            {
                path: 'medical-list',
                component: medicalList, // 医疗
                meta: {
                    title: '三方协议管理'
                }
            },
            {
                path: 'medical-list/add-agreement',
                component: agreetmentProductList, // 医疗新增三方协议
                meta: {
                    title: '选择产品',
                    display: 'none'
                }
            },
            {
                path: 'production-list', // 生产
                component: productionList,
                meta: {
                    title: '三方协议管理'
                }
            },
            {
                path: 'sign',
                component: sign, // 签章
                meta: {
                    title: '签订',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;