/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2020-12-15 12:16:43
 * @Last Modified by: hejk
 * @Last Modified time: 2021-07-27 10:44:50
 * @Description: Description
 * @Route: Route
 */
const createSettle = () => import(/* webpackChunkName: "createSettle" */ '@/pages/hxcm/settle/hospital-pay/settlement-payment/drug/create-settle');
const createSettleConsumable = () => import(/* webpackChunkName: "createSettle" */ '@/pages/hxcm/settle/hospital-pay/settlement-payment/consumable/create-settle');

const settleLog = () => import(/* webpackChunkName: "createPay" */ '@/pages/hxcm/settle/hospital-pay/settlement-payment/drug/settle-log');
const detailRead = () => import(/* webpackChunkName: "createPay" */ '@/pages/hxcm/settle/hospital-pay/settlement-payment/drug/detail-read');
const settleDetail = () => import(/* webpackChunkName: "createPay" */ '@/pages/hxcm/settle/hospital-pay/settlement-payment/drug/settle-detail');
const detailReadDetail = () => import(/* webpackChunkName: "createPay" */ '@/pages/hxcm/settle/hospital-pay/settlement-payment/drug/detail-read');

// 支付单
const createPay = () => import(/* webpackChunkName: "createPay" */ '@/pages/hxcm/settle/hospital-pay/settlement-payment/drug/create-pay');
const createPayConsumable = () => import(/* webpackChunkName: "createPay" */ '@/pages/hxcm/settle/hospital-pay/settlement-payment/consumable/create-pay');

const PayDetail = () => import(/* webpackChunkName: "createPay" */ '@/pages/hxcm/settle/hospital-pay/settlement-payment/drug/pay-detail');
const PayDetailConsumable = () => import(/* webpackChunkName: "createPay" */ '@/pages/hxcm/settle/hospital-pay/settlement-payment/consumable/pay-detail');
const payLog = () => import(/* webpackChunkName: "createPay" */ '@/pages/hxcm/settle/hospital-pay/settlement-payment/drug/pay-log');
const medicalPaymentManagement = () => import(/* webpackChunkName: "medicalPaymentManagement" */ '@/pages/hxcm/settle/hospital-pay/settlement-payment/drug/medical-payment-management');// 支付管理
const medicalPaymentManagementConsumable = () => import(/* webpackChunkName: "medicalPaymentManagement" */ '@/pages/hxcm/settle/hospital-pay/settlement-payment/consumable/medical-payment-management');// 支付管理

const medicalPaymentLog = () => import(/* webpackChunkName: "medicalPaymentManagement" */ '@/pages/hxcm/settle/hospital-pay/settlement-payment/drug/payment-log');
const readLog = () => import(/* webpackChunkName: "createPay" */ '@/pages/hxcm/settle/hospital-pay/settlement-payment/drug/read-log');
const medicalParticulars = () => import(/* webpackChunkName: "medicalPaymentManagement" */ '@/pages/hxcm/settle/hospital-pay/settlement-payment/drug/particulars');

const routesMedicalPayment = [
    {
        path: 'create-settle/list',
        component: createSettle,
        meta: {
            title: '生成结算单'
        }
    },
    {
        path: 'create-settle/list/log',
        component: settleLog,
        meta: {
            title: '任务日志',
            display: 'none'
        }
    },
    {
        path: 'create-settle/list/read',
        component: detailRead,
        meta: {
            title: '查看',
            display: 'none'
        }
    },
    {
        path: 'create-settle/list/detail',
        component: settleDetail,
        meta: {
            title: '结算单明细',
            display: 'none'
        }
    },
    {
        path: 'create-settle/list/detail/detail',
        component: detailReadDetail,
        meta: {
            title: '查看',
            display: 'none'
        }
    },
    {
        path: 'create-pay/list',
        component: createPay,
        meta: {
            title: '生成支付单'
        }
    },
    {
        path: 'create-pay/list/detail',
        component: PayDetail,
        meta: {
            title: '结算单明细',
            display: 'none'
        }
    },
    {
        path: 'create-pay/list/pay-detail',
        component: PayDetail,
        meta: {
            title: '支付单明细',
            display: 'none'
        }
    },
    {
        path: 'create-pay/list/log',
        component: payLog,
        meta: {
            title: '任务日志',
            display: 'none'
        }
    },
    {
        path: 'settlement-payment/list',
        component: medicalPaymentManagement,
        meta: {
            title: '支付管理'
        }
    },
    {
        path: 'settlement-payment/list/log',
        component: medicalPaymentLog,
        meta: {
            title: '任务日志',
            display: 'none'
        }
    },
    {
        path: 'settlement-payment/list/read-log',
        component: readLog,
        meta: {
            title: '任务日志',
            display: 'none'
        }
    },
    {
        path: 'settlement-payment/list/particulars',
        component: medicalParticulars,
        meta: {
            title: '支付单明细',
            display: 'none'
        }
    }
];
const routesMedicalPaymentConsumable = [
    {
        path: 'create-settle/list',
        component: createSettleConsumable,
        meta: {
            title: '生成结算单'
        }
    },
    {
        path: 'create-settle/list/log',
        component: settleLog,
        meta: {
            title: '任务日志',
            display: 'none'
        }
    },
    {
        path: 'create-settle/list/read',
        component: detailRead,
        meta: {
            title: '查看',
            display: 'none'
        }
    },
    {
        path: 'create-settle/list/detail',
        component: settleDetail,
        meta: {
            title: '结算单明细',
            display: 'none'
        }
    },
    {
        path: 'create-settle/list/detail/detail',
        component: detailReadDetail,
        meta: {
            title: '查看',
            display: 'none'
        }
    },
    {
        path: 'create-pay/list',
        component: createPayConsumable,
        meta: {
            title: '生成支付单'
        }
    },
    {
        path: 'create-pay/list/detail',
        component: PayDetailConsumable,
        meta: {
            title: '结算单明细',
            display: 'none'
        }
    },
    {
        path: 'create-pay/list/pay-detail',
        component: PayDetailConsumable,
        meta: {
            title: '支付单明细',
            display: 'none'
        }
    },
    {
        path: 'create-pay/list/log',
        component: payLog,
        meta: {
            title: '任务日志',
            display: 'none'
        }
    },
    {
        path: 'settlement-payment/list',
        component: medicalPaymentManagementConsumable,
        meta: {
            title: '支付管理'
        }
    },
    {
        path: 'settlement-payment/list/log',
        component: medicalPaymentLog,
        meta: {
            title: '任务日志',
            display: 'none'
        }
    },
    {
        path: 'settlement-payment/list/read-log',
        component: readLog,
        meta: {
            title: '任务日志',
            display: 'none'
        }
    },
    {
        path: 'settlement-payment/list/particulars',
        component: medicalParticulars,
        meta: {
            title: '支付单明细',
            display: 'none'
        }
    }
];
const routesDispathPayment = [
    {
        path: 'settlement-payment/list',
        component: medicalPaymentManagement,
        meta: {
            title: '支付管理'
        }
    },
    {
        path: 'settlement-payment/list/log',
        component: medicalPaymentLog,
        meta: {
            title: '任务日志',
            display: 'none'
        }
    },
    {
        path: 'settlement-payment/list/read-log',
        component: readLog,
        meta: {
            title: '任务日志',
            display: 'none'
        }
    },
    {
        path: 'settlement-payment/list/particulars',
        component: medicalParticulars,
        meta: {
            title: '支付单明细',
            display: 'none'
        }
    }
];
export {
    routesMedicalPayment,
    routesDispathPayment,
    routesMedicalPaymentConsumable
};
