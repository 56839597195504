/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-13
 * @lastModifiedBy yanghy
 * @lastModifiedTime 11:13
 * @route
 * @description 目录管理
*/

import View from '@/components/view';
import Catalog from './catalog';
import Product from './product';
import VolumePrice from './volume-price';
import PurchaseCatalog from './purchase-catalog';
import CatalogProd from './catalog-prod';

export default [
    {
        path: 'catalog-manage',
        component: View,
        meta: {
            title: '目录管理'
        },
        children: [
            ...Catalog,
            ...Product,
            ...VolumePrice,
            ...PurchaseCatalog,
            ...CatalogProd
        ]
    }
];
