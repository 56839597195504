/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2021-08-04 15:15:03
 * @Last Modified by: hejk
 * @Last Modified time: 2021-08-04 15:15:03
 * @Description: 联动降价
 */

import Drug from './drug/index';

export default [
    ...Drug
];
