import View from '@/components/view';

const operation = () => import(/* webpackChunkName: "drug-operation" */ '@/pages/hxcm/settle/linkage-price-reduction/drug/operation');
const reportView = () => import(/* webpackChunkName: "drug-report-view" */ '@/pages/hxcm/settle/linkage-price-reduction/drug/report-view');
const report = () => import(/* webpackChunkName: "drug-report" */ '@/pages/hxcm/settle/linkage-price-reduction/drug/report');

const routes = [
    {
        path: 'linkage-price-reduction/drug',
        component: View,
        meta: {
            title: '联动降价'
        },
        children: [
            {
                path: 'operation',
                component: operation,
                meta: {
                    title: '联动降价操作'
                }
            },
            {
                path: 'report-view',
                component: reportView,
                meta: {
                    title: '联动降价上报查看'
                }
            },
            {
                path: 'report',
                component: report,
                meta: {
                    title: '联动降价上报'
                }
            }
        ]
    }
];

export default routes;
