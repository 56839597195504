/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2021-05-22 10:26:28
 * @Last Modified by: xujh
 * @Last Modified time: 2021-09-23 16:16:12
 * @Description: 采购单管理
 * @Route: Route
 */
<template>
    <div>
        <bk-list v-if="tbLoad"
                 ref="table"
                 :search-config="searchConfig"
                 :button-config="buttonConfig"
                 :table-config="tableConfig">
            <div slot="button-before"
                 class="tips tip-button-before">
                <span class="list-tit">提示:统计类功能,您可在T+1日后进行数据查看</span>
            </div>
        </bk-list>
        <OrgDialog ref="org"
                   :org-code="orgCode"
                   :prod-id="prodId" />
    </div>
</template>

<script>
import dyMethod from '@/mixins/dy-method';
import OrgDialog from '@/pages/hxcm/settle/components/org-dialog';

export default {
    components: {
        OrgDialog
    },
    mixins: [dyMethod],
    data() {
        return {
            orgCode: '', // 企业编码
            prodId: '', // 产品id
            tabVal: '1',
            defaultListCode: '采购单管理-采购单管理',
            tbLoad: false,
            searchConfig: {
                layout: 'flow',
                draw: true,
                tabClear: true,
                results: {
                    startSendTime: '',
                    endSendTime: '',
                    sendTime: []
                },
                screen: {
                    name: 'tab',
                    data: [
                        {
                            label: '采购单管理',
                            value: '1'
                        },
                        {
                            label: '退货单管理',
                            value: '2'
                        }
                    ],
                    extend: {
                        'tab-click': async tabValue => {
                            await this.changeTabs(tabValue);
                        }
                    }
                },
                data: [
                    {
                        data: []
                    }
                ]
            },
            buttonConfig: {
                data: [
                    {
                        name: 'export',
                        label: '导出',
                        scene: 'export',
                        visible: true,
                        selection: false,
                        http: {
                            url: '/web/trans/order/order/export',
                            method: 'post',
                            onSuccess: res => {
                                if (res.data) {
                                    window.open(res.data);
                                }
                                return false;
                            }
                        }
                    },
                    {
                        name: 'export2',
                        label: '导出',
                        scene: 'export',
                        visible: false,
                        selection: false,
                        http: {
                            url: '/web/trans/retn/export',
                            method: 'post',
                            onSuccess: res => {
                                if (res.data) {
                                    window.open(res.data);
                                }
                                return false;
                            }
                        }
                    }
                ]
            },
            tableConfig: {
                autoHeight: true,
                http: {
                    method: 'post',
                    url: '/web/trans/order/query_page'
                },
                selection: false,
                order: false,
                data: []
            },
            extFieldObj: {
                订单编号: {
                    label: '订单编号',
                    name: 'ordCode',
                    width: 170,
                    scene: 'skip',
                    http: {
                        url: '/cm-settle/trade/mgr/trading-manage/drug/manage/order-detail?showActual=true',
                        toString: true,
                        targetKeys: ['ordId'],
                        onBefore: params => {
                            params.showActual = this.tabVal === '1';
                        }
                    }
                },
                配送企业: {
                    name: 'delventpName',
                    label: '配送企业',
                    scene: 'default',
                    callback: tb => {
                        this.orgCode = tb.operationRow.delventpCode;
                        // this.prodId = tb.operationRow.prodCde;
                        this.$nextTick(() => {
                            this.$refs.org.dialogVisible.info = true;
                        });
                    }
                },
                生产企业: {
                    name: 'prodentpName',
                    label: '生产企业',
                    scene: 'default',
                    callback: tb => {
                        this.orgCode = tb.operationRow.prodentpCode;
                        // this.prodId = tb.operationRow.prodCode;
                        this.$nextTick(() => {
                            this.$refs.org.dialogVisible.info = true;
                        });
                    }
                }
            }
        };
    },
    created() {
        this.getCurrent();
        this.getFieldConfig();
    },
    methods: {
        getCurrent() {
            const date = new Date();
            const year = date.getFullYear();
            const months = date.getMonth() + 1;
            const day = date.getDate();
            // console.log(this.$refs.table)
            const first = `${year}-${months - 1}-${day} 00:00:00`;
            const second = `${year}-${months}-${day} 23:59:59`;
            setTimeout(() => {
                this.searchConfig.results.sendTimeBegin = first;
                this.searchConfig.results.sendTimeEnd = second;
                this.searchConfig.results.sendTime = [first, second];
            }, 6);
        },
        async changeTabs(tab) {
            const { label, name } = tab;
            const listCode = `采购单管理-${label}`;
            let url;
            const { data } = this.buttonConfig;
            const exp1 = data.find(i => i.name === 'export');
            const exp2 = data.find(i => i.name === 'export2');
            exp1.visible = name === '1';
            exp2.visible = name === '2';
            this.tabVal = name;
            if (name === '1') {
                url = '/web/trans/order/query_page';
            } else {
                url = '/web/trans/retn/query_page';
            }
            this.tableConfig.http.url = url;
            this.getCurrent();
            await this.getFieldConfig({
                params: { listCode }
            });
        }
    }
};
</script>
<style lang="less" scoped>
.list-tit {
    color: red;
}
</style>
