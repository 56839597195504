import View from '@/components/view';

const routes = [
    {
        path: 'drug',
        component: View,
        meta: {
            title: '药品'
        },
        children: [
            {
                path: 'addtional-settle-detail',
                component: () => import(/* webpackChunkName: "drug-addtional-settle-detail" */'@/pages/hxcm/trade/delivery/drug-addtional-settle-detail'),
                meta: {
                    title: '配送数据维护'
                }
            },
            {
                path: 'addtional-settle-detail/confirm',
                component: () => import(/* webpackChunkName: "drug-addtional-settle-confrim" */'@/pages/hxcm/trade/delivery/drug-addtional-settle-confirm'),
                meta: {
                    title: '配送数据维护',
                    display: 'none'
                }
            },
            {
                path: 'data-change-list',
                component: () => import(/* webpackChunkName: "drug-data-change-list" */'@/pages/hxcm/trade/delivery/drug-data-change-list'),
                meta: {
                    title: '配送数据变更'
                }
            }
        ]   
    }
];
export default routes;