/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-11
 * @lastModifiedBy yanghy
 * @lastModifiedTime 18:14
 * @route
 * @description 专家库管理
 */

const List = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/expert-manage/list');

export default [
    {
        path: 'tendering-manage/list/expert-manage',
        component: List,
        meta: {
            title: '专家库管理'
        }
    }
];
