import View from '@/components/view';

const routes = [
    {
        path: 'bill-track',
        component: View,
        meta: {
            title: '结算发票追踪'
        },
        children: [
            {
                path: 'index',
                component: () => import('@/pages/hxcm/settle/delivery/bill-track'),
                meta: {
                    title: '结算发票追踪'
                }
            },
            {
                path: 'index/detail',
                component: () => import('@/pages/hxcm/settle/common/settlement-apply/delivery-add'),
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'hospital',
                component: () => import('@/pages/hxcm/settle/delivery/bill-track-hospital'),
                meta: {
                    title: '结算发票追踪'
                }
            },
            {
                path: 'hospital/detail',
                component: () => import('@/pages/hxcm/settle/common/settlement-apply/delivery-add'),
                meta: {
                    title: '查看',
                    display: 'none'
                }
            }
        ]
    }
];
export default routes;
