/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-11
 * @lastModifiedBy yanghy
 * @lastModifiedTime 18:19
 * @route
 * @description 我的产品库
*/

import View from '@/components/view';
import MyProduct from './my-product'; // 我的产品库
import SignUp from './signup-manage'; // 报名管理
import Bindding from './bidding-manage'; // 竞价管理
import Negotiate from './negotiate-manage'; // 谈判管理
import PublishResult from './publish-result'; // 发布结果查询
import SpreadNet from './spread-net-manage'; // 直接挂网管理
import EnterpriseStatement from './enterprise-statement'; // 企业申述
import DynamicAdjust from './dynamic-adjust'; // 动态调整管理
import Catalog from './catalog-manage'; // 目录管理
import ProjectConfig from './project-config'; // 项目配置
import Announcement from './announcement-manage'; // 公告管理
import GroupManage from './group-manage'; // 分组调整
import StateHandle from './statement-handle'; // 申投诉处理
import ExpertManage from './expert-manage'; // 专家库管理
import HomePage from './home-page'; // 项目首页及项目工作台
import TenderManage from './tendering-manage'; //
import ExpressManage from './express-manage'; // 配送签约管理
import QualifyManage from './qualify-manage'; // 管理端项目外资质库管理
import NationalProjectReportManagement from './national-project-report-management'; // 国家项目报量管理
import NationalProjectExecutionManagement from './national-project-execution-management'; // 国家项目报量管理
import BiddingMode from './bidding-mode'; // 招标模式管理
import MyBusinessInfo from './my-business-info'; // 我的企业信息
import FillPurchaseQuality from './fill-purchase-quality';
import QuantityManage from './quantity-manage';
import QuantityStatistics from './quantity-statistics';
import ProvinceReport from './province-report-management'; // 省级报量项目管理
import SunshineHanging from './sunshine-hanging'; // 阳光挂网
import DeclareManage from './declare-manage'; // 资质增补
import QualifyWarning from './qualify-warning';// 资质预警
import ReagentClassification from './reagent-classification'; // 试剂分类管理
 
import ReviewAssign from './review-assign'; // 审核人员分配
import catalogAdjustment from './catalog-adjustment'; // 目录调整
import PriceLimitAdjust from './price-limit-adjust';// 限价调整管理
import DataStatistics from './data-statistics'; // 审核数据统计
import AdjustMent from './adjustment'; // 动态调整管理
import ProductConversion from './product-conversion'; // 产品转产审核


export default [
    {
        path: 'tender',
        component: View,
        meta: {
            title: '招采系统'
        },
        children: [
            ...MyProduct,
            ...SignUp,
            ...Bindding,
            ...Negotiate,
            ...PublishResult,
            ...SpreadNet,
            ...EnterpriseStatement,
            ...DynamicAdjust,
            ...Catalog,
            ...ProjectConfig,
            ...Announcement,
            ...GroupManage,
            ...StateHandle,
            ...ExpertManage,
            ...HomePage,
            ...TenderManage,
            ...ExpressManage,
            ...QualifyManage,
            ...MyBusinessInfo,
            ...NationalProjectReportManagement,
            ...NationalProjectExecutionManagement,
            ...BiddingMode,
            ...FillPurchaseQuality,
            ...QuantityManage,
            ...QuantityStatistics,
            ...ProvinceReport,
            ...SunshineHanging,
            ...QualifyWarning,
            ...DeclareManage,
            ...ReagentClassification,
            ...ReviewAssign,
            ...catalogAdjustment,
            ...PriceLimitAdjust,
            ...DataStatistics,
            ...AdjustMent,
            ...ProductConversion
        ]
    }
];
