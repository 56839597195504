// 短缺药品信息填报-药品-配送企业
const shortageOfMedicine = () => import(/* webpackChunkName: "recordUrchase" */ '@/pages/hxcm/trade/shortage-distribution/drug/shortage-of-medicine');
const shortageOfMedicineAdd = () => import(/* webpackChunkName: "recordUrchase" */ '@/pages/hxcm/trade/shortage-distribution/drug/shortage-of-medicine-add');

const routes = [
    {
        path: 'shortage-distribution/drug/shortage-of-medicine/list',
        component: shortageOfMedicine,
        meta: {
            title: '短缺药品信息填报'
        }
    },
    {
        path: 'shortage-distribution/drug/shortage-of-medicine/list/add',
        component: shortageOfMedicineAdd,
        meta: {
            title: '新增',
            display: 'none'
        }
    }
];

export default routes;