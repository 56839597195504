import View from '@/components/view';

// const dispatchingAccountManagement = () => import(/* webpackChunkName: "dispatchingAccountManagement" */ '@/pages/hxcm/trade/account-management/drug/dispatching-account-management');

const delivery = () => import(/* webpackChunkName: "delivery-settlement-apply" */'@/pages/hxcm/trade/common/settlement-apply/delivery');
const deliveryAdd = () => import(/* webpackChunkName: "delivery-settlement-apply" */ '@/pages/hxcm/trade/common/settlement-apply/delivery-add');
const medical = () => import(/* webpackChunkName: "medical-settlement-apply" */'@/pages/hxcm/trade/common/settlement-apply/medical');

const dispatchingSettlement = [
    {
        path: 'settlement-apply',
        component: View,
        meta: {
            title: '结算申请(耗材)'
        },
        children: [
            {
                path: 'index',
                component: delivery,
                meta: {
                    title: '结算申请(配送)'
                }
            },
            {
                path: 'index/add',
                component: deliveryAdd,
                meta: {
                    title: '维护结算明细',
                    display: 'none'
                }
            }
        ]
    }
];
const medicalSettlement = [
    {
        path: 'settlement-apply',
        component: View,
        meta: {
            title: '结算申请(耗材)'
        },
        children: [
            {
                path: 'index',
                component: medical,
                meta: {
                    title: '结算申请(医疗)'
                }
            },
            {
                path: 'index/add',
                component: deliveryAdd,
                meta: {
                    title: '审核',
                    display: 'none'
                }
            }
        ]
    }
];
export {
    dispatchingSettlement,
    medicalSettlement
};