/*
 * @Author: xiaoyu
 * @Email: wangxy2@guahao.com
 * @Date: 2019-11-30
 * @Last Modified by: guoxy
 * @Last Modified time: 2021-01-28 16:50:33 16:50:33 16:50:33
 * @Description: 招采药品-管理端-企业资料审核路由
 */

const DrugList = () => import(/* webpackChunkName: "DrugList" */ '@/pages/hxcm/tender/qualify-manage/enterprise/drug/index');
const AuditDetail = () => import(/* webpackChunkName: "AuditDetail" */ '@/pages/hxcm/tender/qualify-manage/enterprise/drug/audit-details');
const AuditedDetail = () => import(/* webpackChunkName: "AuditedDetail" */ '@/pages/hxcm/tender/qualify-manage/enterprise/drug/audited-details');
const ComparaDetail = () => import(/* webpackChunkName: "ComparaDetail" */ '@/pages/hxcm/tender/qualify-manage/enterprise/drug/compare-details');
const CheckHistory = () => import(/* webpackChunkName: "ComparaDetail" */ '@/pages/hxcm/tender/qualify-manage/enterprise/drug/check-history');
// 不审核
const DrugListPush = () => import(/* webpackChunkName: "DrugList" */ '@/pages/hxcm/tender/qualify-manage/enterprise/drug/index-push');
const AuditedDetailPush = () => import(/* webpackChunkName: "AuditedDetail" */ '@/pages/hxcm/tender/qualify-manage/enterprise/drug/audited-details-push');
export default [
    {
        path: 'enterprise/drug/index',
        component: DrugList,
        meta: {
            title: '企业资料审核'
            // display: 'none'
        }
    },
    {
        path: 'enterprise/drug/index/details',
        component: AuditDetail,
        meta: {
            title: '企业资料审核详情',
            display: 'none'
        }
    },
    {
        path: 'enterprise/drug/index/audited-details',
        component: AuditedDetail,
        meta: {
            title: '企业资料审核完成详情',
            display: 'none'
        }
    },
    {
        path: 'enterprise/drug/index/compare-details',
        component: ComparaDetail,
        meta: {
            title: '比对详情',
            display: 'none'
        }
    },
    {
        path: 'enterprise/drug/index/check-history',
        component: CheckHistory,
        meta: {
            title: '查看历史',
            display: 'none'
        }
    },
    {
        path: 'enterprise/drug/push-index',
        component: DrugListPush,
        meta: {
            title: '企业库'
            // display: 'none'
        }
    },
    {
        path: 'enterprise/drug/push-index/audited-details',
        component: AuditedDetailPush,
        meta: {
            title: '查看企业详情',
            display: 'none'
        }
    }
];
