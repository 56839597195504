import View from '@/components/view';


const routes = [
    {
        path: 'consumable',
        component: View,
        meta: {
            title: '耗材'
        },
        children: [
            {
                path: 'qualification-warn',
                component: () => import('@/pages/hxcm/trade/delivery/qualification-warn'),
                meta: {
                    title: '企业资历预警'
                }
            },
            {
                path: 'qualification-warn/detail',
                component: () => import('@/pages/hxcm/trade/components/consumable/qualification-detail'),
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'delivery-relation-manage',
                component: () => import('@/pages/hxcm/trade/delivery/delivery-relation-manage'),
                meta: {
                    title: '配送企业关系管理    '
                }
            },
            {           
                path: 'secondary-bargaining-list',
                component: () => import('@/pages/hxcm/trade/delivery/secondary-bargaining-list'),
                meta: {
                    title: '议价管理'
                }
            },
            {
                path: 'addtional-settle-detail',
                component: () => import('@/pages/hxcm/trade/delivery/addtional-settle-detail'),
                meta: {
                    title: '配送数据维护'
                }
            },
            {
                path: 'addtional-settle-detail/confirm',
                component: () => import('@/pages/hxcm/trade/delivery/addtional-settle-confirm'),
                meta: {
                    title: '配送数据维护',
                    display: 'none'
                }
            },
            {
                path: 'data-change-list',
                component: () => import('@/pages/hxcm/trade/delivery/data-change-list'),
                meta: {
                    title: '配送数据变更'
                }
            }
        ]   
    }
];
export default routes;
