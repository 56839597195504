import View from '@/components/view';

// 目录管理-耗材-医疗机构
const hangingNetCatalogSearch = () => import(/* webpackChunkName: "hangingNetCatalogSearch" */ '@/pages/hxcm/settle/catalog-management/consumable/hanging-net-catalog-search-list');
const catalogSearchListRead = () => import(/* webpackChunkName: "catalogSearchListRead" */ '@/pages/hxcm/settle/catalog-management/consumable/catalog-search-list-read');
const hospitalCatalogManageList = () => import(/* webpackChunkName: "hospitalCatalogManageList" */ '@/pages/hxcm/settle/catalog-management/consumable/hospital-catalog-manage-list');
const secondArgaininManagement = () => import(/* webpackChunkName: "secondArgaininManagement" */ '@/pages/hxcm/settle/catalog-management/consumable/second-argainin-management');
const secondArgaininManagementAdd = () => import(/* webpackChunkName: "secondArgaininManagementAdd" */ '@/pages/hxcm/settle/catalog-management/consumable/second-argainin-management-add');
const DeliveryLinkManSearch = () => import(/* webpackChunkName: "McsDeliveryLinkManSearch" */ '@/pages/hxcm/settle/catalog-management/consumable/delivery-linkman-search');
const OrgLinkManManage = () => import(/* webpackChunkName: "McsOrgLinkManManage" */ '@/pages/hxcm/settle/catalog-management/consumable/org-linkman-manage');

const prodManage = () => import(/* webpackChunkName: "prod-manage" */ '@/pages/hxcm/settle/catalog-management/consumable/prod-manage');
const routes = [
    {
        path: 'catalog-management/consumable',
        component: View,
        meta: {
            title: '目录管理'
        },
        children: [
            {
                path: 'hanging-net-catalog-search/list',
                component: hangingNetCatalogSearch,
                meta: {
                    title: '挂网目录查询'
                }
            },
            {
                path: 'hanging-net-catalog-search/list/read',
                component: catalogSearchListRead,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'hospital-catalog-manage-list',
                component: hospitalCatalogManageList,
                meta: {
                    title: '院内目录管理'
                }
            },
            {
                path: 'second-argainin-management/list',
                component: secondArgaininManagement,
                meta: {
                    title: '议价管理'
                }
            },
            {
                path: 'second-argainin-management/list/add',
                component: secondArgaininManagementAdd,
                meta: {
                    title: '新增',
                    display: 'none'
                }
            },
            {
                path: 'delivery-linkman-search',
                component: DeliveryLinkManSearch,
                meta: {
                    title: '企业联系人查询'
                }
            },
            {
                path: 'org-linkman-manage',
                component: OrgLinkManManage,
                meta: {
                    title: '企业联系人管理'
                }
            },
            {
                path: 'prod-manage',
                component: prodManage,
                meta: {
                    title: '产品管理'
                }
            }
        ]
    }
];

export default routes;
