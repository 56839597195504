
const consumableQualification = [
    {
        path: 'product-qualification',
        component: () => import('@/pages/hxcm/settle/product/product-qualification'),
        meta: {
            title: '产品资质预警'
        }
    },
    {
        path: 'product-qualification/detail',
        component: () => import('@/pages/hxcm/settle/components/consumable/product-qualification-detail'),
        meta: {
            title: '注册证详情',
            display: 'none'
        }
    }
];
const drugRoutes = {};
export {
    drugRoutes,
    consumableQualification
};
