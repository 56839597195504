/*
 * @Author: huanggk
 * @Email: huanggk@guahao.com
 * @Date: 2019-10-31 15:54:46
 * @Last Modified by: huanggk
 * @Last Modified time: 2021-03-04 17:49:37
 * @Description: 带量采购管理
 */

import Drug from './drug/index';
import Consumable from './consumable/index';

export default [
    ...Drug,
    ...Consumable
];
