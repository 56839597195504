/*
 * @Author: yanshuai
 * @Email: yanshuai@guahao.com
 * @Date: 2021-03-03 11:15:31
 * @Last Modified by: shandan
 * @Last Modified time: 2021-03-09 15:46:19
 * @Description: 耗材 - 支付模块
 */
import View from '@/components/view';

import Delivery from './delivery/index';
import ManagementUnit from './management-unit/index';
import Medical from './medical/index';

export default [
    {
        path: 'payment-manage/consumables',
        component: View,
        meta: {
            title: '耗材 - 支付模块'
        },
        children: [
            ...Delivery,
            ...ManagementUnit,
            ...Medical
        ]
    }
];