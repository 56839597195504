/*
 * @Author: yanshuai
 * @Email: yanshuai@guahao.com
 * @Date: 2021-03-03 10:36:28
 * @Last Modified by: yanshuai
 * @Last Modified time: 2021-03-03 11:20:33
 * @Description: 支付管理
 */
import Drug from './drug/index';
import Consumables from './consumables/index';

// 支付管理
export default [
    ...Drug,
    ...Consumables
];