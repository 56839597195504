/*
 * @Author: xiaoyu
 * @Email: wangxy2@guahao.com
 * @Date: 2019-12-11
 * @Last Modified by: hejk
 * @Last Modified time: 2021-03-25 11:18:02
 * @Description: 耗材目录产品管理
 */

const ConsumableList = () => import(/* webpackChunkName: "ConsumableList" */ '@/pages/hxcm/tender/catalog-manage/product/consumable/list');

export default [
    {
        path: 'product/consumable/lists',
        component: ConsumableList,
        meta: {
            title: '目录产品管理'
        }
    }
];
