
import View from '@/components/view';
import { routesDispath, routesMedical } from '../drug/settlement-manage';
import { routesMedicalPaymentConsumable } from '../drug/settlement-payment';
import { routesDispathInfo } from '../drug/information-inquiry';
import routesMedicalInfo from './information-inquiry';
import { dispatchingSettlement, medicalSettlement } from './settlement-apply';
import { dispatchingAccountChecking, medicalAccountChecking } from './account-checking';

const AccountManagement = () => import(/* webpackChunkName: "dispatchingAccountManagement" */ '@/pages/hxcm/trade/hospital-pay/account-management/drug');
const superviseAccountManagement = () => import(/* webpackChunkName: "superviseAccountManagement" */ '@/pages/hxcm/trade/hospital-pay/account-management/components/supervise-account-management');
const RegulatorlList = () => import('@/pages/hxcm/trade/hospital-pay/information-inquiry/drug/list-regulator');
const dispatchingTemplate = () => import(/* webpackChunkName: "commissionedToApply" */ '@/pages/hxcm/trade/hospital-pay/account-management/components/template');
const dispatchingAccountManagementRead = () => import(/* webpackChunkName: "dispatchingAccountManagementAdd" */ '@/pages/hxcm/trade/account-management/drug/dispatching-account-management-add');
const TrackingDetail = () => import('@/pages/hxcm/trade/information-inquiry/consumable/tracking-detail');
const RegulatorSettleDetail = () => import('@/pages/hxcm/trade/information-inquiry/consumable/settle-detail-regulator');
const RegulatorPayDetail = () => import('@/pages/hxcm/trade/information-inquiry/consumable/pay-detail-regulator');

const BillTrack = () => import('@/pages/hxcm/trade/hospital-pay/information-inquiry/consumable/bill-track');
const routes = [
    {
        path: 'hospital-pay/account-management/dispatching/consumable',
        component: View,
        meta: {
            title: '医院自付结算管理'
        },
        children: [
            ...routesDispath,
            {
                path: 'checking-manage',
                component: () => import('@/pages/hxcm/trade/hospital-pay/checking-manage/consumable/list-express'),
                meta: {
                    title: '对账管理'
                }
            },
            {
                path: 'checking-manage/checking',
                component: () => import('@/pages/hxcm/trade/hospital-pay/checking-manage/consumable/checking-express'),
                meta: {
                    title: '对账/继续对账',
                    display: 'none'
                }
            },
            {
                path: 'checking-manage/detail',
                component: () => import('@/pages/hxcm/trade/hospital-pay/checking-manage/consumable/detail-express'),
                meta: {
                    title: '对账详情',
                    display: 'none'
                }
            },
            {
                path: 'receivable-payment-manage',
                component: () => import('@/pages/hxcm/trade/hospital-pay/pay-reconciliation/delivery'),
                meta: {
                    title: '账期管理'
                }
            },
            ...routesDispathInfo,
            // {
            //     path: 'information-inquiry',
            //     component: () => import('@/pages/hxcm/trade/hospital-pay/information-inquiry/component/tracking-express'),
            //     meta: {
            //         title: '结算明细追踪'
            //     }
            // },
            {
                path: 'account-management/list',
                component: AccountManagement,
                meta: {
                    title: '结算账户管理'
                }
            },
            {
                path: 'account-management/list/add',
                component: () => import('@/pages/hxcm/trade/hospital-pay/account-management/components/add'),
                meta: {
                    title: '申请开通结算账户',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/template', // 签订
                component: dispatchingTemplate,
                meta: {
                    title: '申请开通结算账户',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/loan-withdraw',
                component: () => import('@/pages/hxcm/trade/hospital-pay/account-management/components/loan-withdraw'),
                meta: {
                    title: '贷款提现',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/review-flow',
                component: () => import('@/pages/hxcm/trade/hospital-pay/account-management/components/review-flow'),
                meta: {
                    title: '查看流水',
                    display: 'none'
                }
            },
            ...dispatchingSettlement,
            ...dispatchingAccountChecking
        ]
    },
    // 医疗机构
    {
        path: 'hospital-pay/account-management/medical/consumable',
        component: View,
        meta: {
            title: '医院自付结算管理'
        },
        children: [
            ...routesMedical,
            {
                path: 'checking-manage',
                component: () => import('@/pages/hxcm/trade/hospital-pay/checking-manage/consumable/list-medical'),
                meta: {
                    title: '对账管理'
                }
            },
            {
                path: 'checking-manage/checking',
                component: () => import('@/pages/hxcm/trade/hospital-pay/checking-manage/consumable/checking-medical'),
                meta: {
                    title: '对账/继续对账',
                    display: 'none'
                }
            },
            {
                path: 'checking-manage/detail',
                component: () => import('@/pages/hxcm/trade/hospital-pay/checking-manage/consumable/detail-medical'),
                meta: {
                    title: '对账详情',
                    display: 'none'
                }
            },
            ...routesMedicalPaymentConsumable,
            ...routesMedicalInfo,
            {
                path: 'receivable-payment-manage',
                component: () => import('@/pages/hxcm/trade/hospital-pay/pay-reconciliation/medical'),
                meta: {
                    title: '账期管理'
                }
            },
            {
                path: 'account-management/list',
                component: AccountManagement,
                meta: {
                    title: '结算账户管理'
                }
            },
            {
                path: 'account-management/list/template', // 签订
                component: dispatchingTemplate,
                meta: {
                    title: '申请开通结算账户',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/add',
                component: () => import('@/pages/hxcm/trade/hospital-pay/account-management/components/add'),
                meta: {
                    title: '申请开通结算账户',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/template', // 签订
                component: dispatchingTemplate,
                meta: {
                    title: '申请开通结算账户',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/loan-withdraw',
                component: () => import('@/pages/hxcm/trade/hospital-pay/account-management/components/loan-withdraw'),
                meta: {
                    title: '贷款提现',
                    display: 'none'
                }
            },
            {
                path: 'account-management/list/review-flow',
                component: () => import('@/pages/hxcm/trade/hospital-pay/account-management/components/review-flow'),
                meta: {
                    title: '查看流水',
                    display: 'none'
                }
            },
            {
                path: 'check-receivable/settleDetail',
                component: () => import('@/pages/hxcm/trade/hospital-pay/check-receivable/settle-detail'),
                meta: {
                    title: '查看结算单明细',
                    display: 'none'
                }
            },
            {
                path: 'check-receivable/payDetail',
                component: () => import('@/pages/hxcm/trade/hospital-pay/check-receivable/pay-detail'),
                meta: {
                    title: '支付单明细',
                    display: 'none'
                }
            },
            ...medicalSettlement,
            ...medicalAccountChecking
        ]
    },
    // 管理单位
    {
        path: 'hospital-pay/account-management/supervise/consumable',
        component: View,
        meta: {
            title: '医院自付结算管理'
        },
        children: [
            {
                path: 'pay-reconciliation',
                component: () => import('@/pages/hxcm/trade/hospital-pay/pay-reconciliation/supervise'),
                meta: {
                    title: '对账管理'
                }
            },
            // {
            //     path: 'flow-query',
            //     component: () => import('@/pages/hxcm/trade/hospital-pay/flow-query/index'),
            //     meta: {
            //         title: '结算账户流水查询'
            //     }
            // },
            {
                path: 'regulator-list',
                component: RegulatorlList,
                meta: {
                    title: '医疗机构结算信息查询'
                }
            },
            {
                path: 'regulator-list/settle-detail',
                component: RegulatorSettleDetail,
                meta: {
                    display: 'none',
                    title: '结算单明细' 
                }
            },
            {
                path: 'regulator-list/pay-detail',
                component: RegulatorPayDetail,
                meta: {
                    display: 'none',
                    title: '支付单明细' 
                }
            },             
            {
                path: 'information-inquiry',
                component: () => import('@/pages/hxcm/trade/hospital-pay/information-inquiry/component/tracking-regulator'),
                meta: {
                    title: '结算明细追踪'
                }
            },
            {
                path: 'information-inquiry/detail',
                component: TrackingDetail,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },            
            {
                path: 'account-management/list',
                component: superviseAccountManagement,
                meta: {
                    title: '结算账户审核'
                }
            },
            {
                path: 'account-management/list/look',
                component: dispatchingAccountManagementRead,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'bill-track',
                component: BillTrack,
                meta: {
                    title: '结算发票追踪'
                }
            },
            {
                path: 'bill-track/detail',
                component: () => import('@/pages/hxcm/trade/common/settlement-apply/delivery-add'),
                meta: {
                    title: '查看',
                    display: 'none'
                }
            }              
            // {
            //     path: 'settlement-config',
            //     component: () => import('@/pages/hxcm/trade/hospital-pay/settlement-config/index'),
            //     meta: {
            //         title: '结算账户配置'
            //     }
            // }
        ]
    }
];
export default routes;
