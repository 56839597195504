
/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 10:33
 * @route
 * @description 报名管理/产品报名
 */
const ConsumableList = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/signup-manage/product/consumable/list');
const ConsumableProductDetail = () => import(/* webpackChunkName: "sign-up" */ '@/pages/hxcm/tender/signup-manage/product/consumable/product-detail');
const ConsumableEdit = () => import(/* webpackChunkName: "edit" */ '@/pages/hxcm/tender/signup-manage/product/consumable/edit');
const ConsumableDetail = () => import(/* webpackChunkName: "detail" */ '@/pages/hxcm/tender/signup-manage/product/consumable/detail');
const ConsumableAduit = () => import(/* webpackChunkName: "aduit" */ '@/pages/hxcm/tender/signup-manage/product/consumable/aduit');
const ConsumableAduitList = () => import(/* webpackChunkName: "aduit-list" */ '@/pages/hxcm/tender/signup-manage/product/consumable/aduit-list');
const ConsumableAdminDetail = () => import(/* webpackChunkName: "ConsumableAduitDetail" */ '@/pages/hxcm/tender/signup-manage/product/consumable/admin-detail');
const ConsumableHistory = () => import(/* webpackChunkName: "ConsumableAduitDetail" */ '@/pages/hxcm/tender/signup-manage/product/consumable/history');

const DrugList = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/signup-manage/product/drug/list');
const DrugProductDetail = () => import(/* webpackChunkName: "sign-up" */ '@/pages/hxcm/tender/signup-manage/product/drug/product-detail');
const DrugEdit = () => import(/* webpackChunkName: "edit" */ '@/pages/hxcm/tender/signup-manage/product/drug/edit');
const DrugDetail = () => import(/* webpackChunkName: "detail" */ '@/pages/hxcm/tender/signup-manage/product/drug/detail');
const DrugHistory = () => import(/* webpackChunkName: "DrugHistory" */ '@/pages/hxcm/tender/signup-manage/product/drug/history');
const DrugAduit = () => import(/* webpackChunkName: "aduit" */ '@/pages/hxcm/tender/signup-manage/product/drug/aduit');
const DrugAduitList = () => import(/* webpackChunkName: "aduit-list" */ '@/pages/hxcm/tender/signup-manage/product/drug/aduit-list');
const DrugContrastDetail = () => import(/* webpackChunkName: "DrugContrastDetail" */ '@/pages/hxcm/tender/signup-manage/product/drug/contrast-detail');

export default [
    {
        path: 'product/consumable/list',
        component: ConsumableList,
        meta: {
            title: '产品列表'
            // display: 'none'
        }
    },
    {
        path: 'product/consumable/list/edit',
        component: ConsumableEdit,
        meta: {
            title: '编辑',
            display: 'none'
        }
    },
    {
        path: 'product/consumable/list/product-detail',
        component: ConsumableProductDetail,
        meta: {
            title: '查看产品详情',
            display: 'none'
        }
    },
    {
        path: 'product/consumable/list/product-detail/history',
        component: ConsumableHistory,
        meta: {
            title: '查看历史',
            display: 'none'
        }
    },
    {
        path: 'product/consumable/aduit-list',
        component: ConsumableAduitList,
        meta: {
            title: '产品报名审核'
        }
    },
    {
        path: 'product/consumable/aduit-list/aduit',
        component: ConsumableAduit,
        meta: {
            title: '产品审核',
            display: 'none'
        }
    },
    {
        path: 'product/consumable/aduit-list/aduit/admin-detail',
        component: ConsumableAdminDetail,
        meta: {
            title: '查看详情',
            display: 'none'
        }
    },
    {
        path: 'product/consumable/aduit-list/aduit/detail',
        component: ConsumableDetail,
        meta: {
            title: '比对详情',
            display: 'none'
        }
    },
    {
        path: 'product/drug/list',
        component: DrugList,
        meta: {
            title: '产品列表'
            // display: 'none'
        }
    },
    {
        path: 'product/drug/list/edit',
        component: DrugEdit,
        meta: {
            title: '编辑',
            display: 'none'
        }
    },
    {
        path: 'product/drug/list/product-detail',
        component: DrugProductDetail,
        meta: {
            title: '产品详情',
            display: 'none'
        }
    },
    {
        path: 'product/drug/list/product-detail/history',
        component: DrugHistory,
        meta: {
            title: '查看历史',
            display: 'none'
        }
    },
    {
        path: 'product/drug/aduit-list',
        component: DrugAduitList,
        meta: {
            title: '产品报名审核'
        }
    },
    {
        path: 'product/drug/aduit-list/aduit',
        component: DrugAduit,
        meta: {
            title: '产品审核',
            display: 'none'
        }
    },
    {
        path: 'product/drug/aduit-list/product-detail',
        component: DrugDetail,
        meta: {
            title: '查看产品详情',
            display: 'none'
        }
    },
    {
        path: 'product/drug/aduit-list/aduit/detail',
        component: DrugDetail,
        meta: {
            title: '查看详情',
            display: 'none'
        }
    },
    {
        path: 'product/drug/aduit-list/aduit/detail/contrast-detail',
        component: DrugContrastDetail,
        meta: {
            title: '对比详情',
            display: 'none'
        }
    }
];
