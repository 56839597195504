/*
* @Author: xiaoyu
* @Email: wangxy2@guahao.com
* @Date: 2020-02-19
 * @Last Modified by: hejk
 * @Last Modified time: 2021-03-25 11:11:54
* @Description: 药品/报量路由管理
*/

const Catalog = () => import(/* webpackChunkName: "Catalog" */ '@/pages/hxcm/tender/province-report-management/drug/catalog-manage');
const Match = () => import(/* webpackChunkName: "Match" */ '@/pages/hxcm/tender/province-report-management/drug/match');
const Product = () => import(/* webpackChunkName: "Product" */ '@/pages/hxcm/tender/province-report-management/drug/product-manage');
const AddProduct = () => import(/* webpackChunkName: "AddProduct" */ '@/pages/hxcm/tender/province-report-management/drug/add-product');

const DRUGROUTES = [
    {
        path: 'drug/catalog-manage',
        component: Catalog,
        meta: {
            title: '目录管理',
            flag: 'quantity'
        }
    },
    {
        path: 'drug/catalog-manage/match',
        component: Match,
        meta: {
            title: '匹配细则',
            flag: 'quantity',
            display: 'none'
        }
    },
    {
        path: 'drug/product-manage',
        component: Product,
        meta: {
            title: '目录产品管理',
            flag: 'quantity'
        }
    },
    {
        path: 'drug/product-manage/add',
        component: AddProduct,
        meta: {
            title: '新增目录产品',
            flag: 'quantity',
            display: 'none'
        }
    }
];

export default DRUGROUTES;
