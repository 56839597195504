/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2021-05-31 16:36:39
 * @Last Modified by: yanchunl
 * @Last Modified time: 2021-07-24 14:18:17
 * @Description: 管理端 - 产品转产审核
 * @Route: Route
 */

const list = () => import(/* webpackChunkName: "product-conversion-list" */ '@/pages/hxcm/tender/product-conversion/regulator-org/list');
const audit = () => import(/* webpackChunkName: "product-conversion-audit" */ '@/pages/hxcm/tender/product-conversion/regulator-org/components/audit');

export default [
    {
        path: 'regulator-org/list',
        component: list,
        meta: {
            title: '产品转产审核'
        }
    },
    {
        path: 'regulator-org/list/audit',
        component: audit,
        meta: {
            title: '审核',
            display: 'none'
        }
    },
    {
        path: 'regulator-org/list/detail',
        component: audit,
        meta: {
            title: '查看详情',
            display: 'none'
        }
    }
];
