/*
 * @Author: guoxy
 * @Email: guoxy1@guahao.com
 * @Date: 2020-10-06 17:59:59
 * @Last Modified by: hejk
 * @Last Modified time: 2021-03-25 11:11:07
 * @Description: 企业端-阳光挂网
 */

const drug = () => import(/* webpackChunkName: "modeList" */ '@/pages/hxcm/tender/sunshine-hanging/product-org/drug/list');
const consumable = () => import(/* webpackChunkName: "modeList" */ '@/pages/hxcm/tender/sunshine-hanging/product-org/consumable/list');
export default [
    {
        path: 'product-org/drug/list',
        component: drug,
        meta: {
            title: '阳光挂网'
        }
    },
    {
        path: 'product-org/consumable/list',
        component: consumable,
        meta: {
            title: '阳光挂网'
        }
    }
];
