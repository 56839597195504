/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 11:38
 * @route
 * @description 申投诉处理/申投诉事项维护
 */

const Maintain = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/statement-handle/statement-maintain/maintain');


export default [
    {
        path: 'statement-maintain/maintain',
        component: Maintain,
        meta: {
            title: '申投诉事项维护'
        }
    }
];
