/*
 * @Author: shandan
 * @Email: shandan@guahao.com
 * @Date: 2020-09-02 15:54:46
 * @Last Modified by: shandan
 * @Last Modified time: 2020-09-02 15:17:58
 * @Description: 生产企业-二次议价
 */
import Drug from './drug/index';
import Consumable from './consumable/index';

export default [
    ...Drug,
    ...Consumable
];