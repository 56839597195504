
/**
 * @author lina
 * @email lina5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy lina
 * @lastModifiedTime 11:38
 * @route
 * @description 配送方案管理
 */

const ExpressSchemes = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/express-manage/product-org/list');
const ExpressOperation = () => import(/* webpackChunkName: "drug-detail" */ '@/pages/hxcm/tender/express-manage/product-org/operation');
const ExpressApply = () => import(/* webpackChunkName: "apply" */ '@/pages/hxcm/tender/express-manage/product-org/apply');
const ExpressPush = () => import(/* webpackChunkName: "push" */ '@/pages/hxcm/tender/express-manage/product-org/apply-push');
const ExpressLog = () => import(/* webpackChunkName: "pro-log" */ '@/pages/hxcm/tender/express-manage/product-org/adjust-log');
const ExpressDetail = () => import(/* webpackChunkName: "detail" */ '@/pages/hxcm/tender/express-manage/components/common-detail');

export default [
    {
        path: 'express-manage/product-org/list',
        component: ExpressSchemes,
        meta: {
            title: '配送方案点选'
            // display: 'none'
        }
    },
    {
        path: 'express-manage/product-org/list/operation',
        component: ExpressOperation,
        meta: {
            title: '配送方案维护',
            display: 'none'
        }
    },
    {
        path: 'express-manage/product-org/apply',
        component: ExpressApply,
        meta: {
            title: '配送签约调整申请'
            // display: 'none'
        }
    },
    {
        path: 'express-manage/product-org/apply/apply-push',
        component: ExpressPush,
        meta: {
            title: '申请调整',
            display: 'none'
        }
    },
    {
        path: 'express-manage/product-org/apply/log',
        component: ExpressLog,
        meta: {
            title: '调整日志',
            display: 'none'
        }
    },
    {
        path: 'express-manage/product-org/apply/adjust-detail',
        component: ExpressDetail,
        meta: {
            title: '查看',
            display: 'none'
        }
    }
];
