/*
 * @Author: xiaoyu
 * @Email: wangxy2@guahao.com
 * @Date: 2019-12-11
 * @Last Modified by: wangxiaoyu
 * @Last Modified time: 2019-12-11
 * @Description: 目录产品管理路由
 */


import DrugRouter from './drug';
import ConsumableRouter from './consumable';

export default [
    ...DrugRouter,
    ...ConsumableRouter
];
