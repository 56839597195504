/*
 * @Author: shandan
 * @Email: shandan@guahao.com
 * @Date: 2021-06-21 17:12:40
 * @Last Modified by: kirin
 * @Last Modified time: 2021-07-27 14:51:07
 * @Description: 耗材-目录调整审核与申请
 * @Route: 
 */

const auditList = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/catalog-adjustment/audit/list');
const auditDetail = () => import(/* webpackChunkName: "add" */ '@/pages/hxcm/tender/catalog-adjustment/audit/detail');
const audit = () => import(/* webpackChunkName: "add" */ '@/pages/hxcm/tender/catalog-adjustment/audit/detail');

const applicationList = () => import(/* webpackChunkName: "detail" */ '@/pages/hxcm/tender/catalog-adjustment/application/list');
const applicationDetail = () => import(/* webpackChunkName: "detail" */ '@/pages/hxcm/tender/catalog-adjustment/application/detail');
const application = () => import(/* webpackChunkName: "detail" */ '@/pages/hxcm/tender/catalog-adjustment/application/detail');
export default [
    {
        path: 'catalog-adjustment/audit/list',
        component: auditList,
        meta: {
            title: '目录调整审核'
        }
    },
    {
        path: 'catalog-adjustment/audit/list/examine',
        component: audit,
        meta: {
            title: '审核',
            display: 'none'
        }
    },
    {
        path: 'catalog-adjustment/audit/list/detail',
        component: auditDetail,
        meta: {
            title: '查看',
            display: 'none'
        }
    },
    {
        path: 'catalog-adjustment/application/list',
        component: applicationList,
        meta: {
            title: '目录调整申请'
        }
    },
    {
        path: 'catalog-adjustment/application/list/adjustment',
        component: application,
        meta: {
            title: '查看',
            display: 'none'
        }
    },
    {
        path: 'catalog-adjustment/application/list/detail',
        component: applicationDetail,
        meta: {
            title: '查看',
            display: 'none'
        }
    }
];
