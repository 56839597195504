// 备案采购-耗材-管理机构
import View from '@/components/view';

const recordPurchase = () => import(/* webpackChunkName: "recordPurchase" */ '@/pages/hxcm/settle/record-purchase/consumable/filing-procurement-manage-list');

const routes = [
    {
        component: View,
        path: 'record-purchase',
        meta: {
            title: '交易管理'
        },
        children: [
            {
                path: 'consumable/filing-procurement-manage-list',
                component: recordPurchase,
                meta: {
                    title: '备案采购管理'
                }
            }
        ]
    }
];

export default routes;
