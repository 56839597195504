/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 15:04
 * @route
 * @description 直接挂网查询
*/

import View from '@/components/view';
import SpreadNet from './spread-net';
import ResultQuery from './result-query';

export default [
    {
        path: 'spread-net',
        component: View,
        meta: {
            title: '直接挂网查询'
        },
        children: [
            ...SpreadNet,
            ...ResultQuery
        ]
    }
];
