import View from '@/components/view';

// 药品-结算-结算方案配置-管理机构
const settlementConfiguration = () => import(/* webpackChunkName: "settlementConfiguration" */ '@/pages/hxcm/settle/solution-configuration/drug/settlement-configuration');
const productSettlementConfiguration = () => import(/* webpackChunkName: "productSettlementConfiguration" */ '@/pages/hxcm/settle/solution-configuration/drug/product-settlement-configuration');
const settlementProcess = () => import(/* webpackChunkName: "settlementProcess" */ '@/pages/hxcm/settle/solution-configuration/drug/settlement-process');
const medicalTreatmentAlliance = () => import(/* webpackChunkName: "medicalTreatmentAlliance" */ '@/pages/hxcm/settle/solution-configuration/drug/medical-treatment-alliance');
const medicalTreatmentAllianceLook = () => import(/* webpackChunkName: "medicalTreatmentAlliancelook" */ '@/pages/hxcm/settle/solution-configuration/components/look');

const routes = [
    {
        path: 'solution-configuration/drug',
        component: View,
        meta: {
            title: '结算方案配置'
        },
        children: [
            {
                path: 'solution-configuration/settlement-configuration/list',
                component: settlementConfiguration,
                meta: {
                    title: '结算方式配置'
                }
            },
            {
                path: 'solution-configuration/settlement-configuration/list/product',
                component: productSettlementConfiguration,
                meta: {
                    title: '产品结算方式配置',
                    display: 'none'
                }
            },
            {
                path: 'solution-configuration/settlement-process',
                component: settlementProcess,
                meta: {
                    title: '结算流程配置'
                }
            },
            {
                path: 'solution-configuration/medical-treatment-alliance',
                component: medicalTreatmentAlliance,
                meta: {
                    title: '医联体设置'
                }
            },
            {
                path: 'solution-configuration/medical-treatment-alliance/look',
                component: medicalTreatmentAllianceLook,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
