import View from '@/components/view';

// 药品-发货退货管理
const OrderList = () => import(/* webpackChunkName: "OrderList" */ '@/pages/hxcm/settle/send-return-message/drug/order-list'); // 发货管理-按订单发货
const DetailList = () => import(/* webpackChunkName: "DetailList" */ '@/pages/hxcm/settle/send-return-message/drug/detail-list'); // 按明细发货
const InvoiceList = () => import(/* webpackChunkName: "InvoiceList" */ '@/pages/hxcm/settle/send-return-message/drug/invoice-list'); // 发货订单管理
const OrderTrackList = () => import(/* webpackChunkName: "OrderTrackList" */ '@/pages/hxcm/settle/send-return-message/drug/order-track-list'); // 订单明细跟踪
const ReturnManageList = () => import(/* webpackChunkName: "ReturnManageList" */ '@/pages/hxcm/settle/send-return-message/drug/return-manage-list'); // 退货管理

const OrderDetail = () => import(/* webpackChunkName: "OrderDetail" */ '@/pages/hxcm/settle/send-return-message/drug/order-detail');// 按订单发货-订单明细
const DeliveryDetail = () => import(/* webpackChunkName: "DeliveryDetail" */ '@/pages/hxcm/settle/send-return-message/drug/delivery-detail');// 按订单发货-发货/补发
const ShipDetail = () => import(/* webpackChunkName: "ShipDetailManager" */ '@/pages/hxcm/settle/send-return-message/drug/ship-detail');// 发货订单管理-订单明细详情

const routes = [
    {
        path: 'send-return-message/drug',
        component: View,
        meta: {
            title: '发货退货管理'
        },
        children: [
            {
                path: 'order-list/list',
                component: OrderList,
                meta: {
                    title: '按订单发货'
                }
            },
            {
                path: 'order-list/list/read',
                component: OrderDetail,
                meta: {
                    title: '订单明细',
                    display: 'none'
                }
            },
            {
                path: 'order-list/list/operation',
                component: DeliveryDetail,
                meta: {
                    title: '发货/补发',
                    display: 'none'
                }
            },

            {
                path: 'detail-list',
                component: DetailList,
                meta: {
                    title: '按明细发货'
                }
            },
            {
                path: 'invoice/list',
                component: InvoiceList,
                meta: {
                    title: '发货订单管理'
                }
            },
            {
                path: 'invoice/list/ship-detail',
                component: ShipDetail,
                meta: {
                    title: '订单明细详情',
                    display: 'none'
                }
            },
            {
                path: 'order-track-list',
                component: OrderTrackList,
                meta: {
                    title: '订单明细跟踪'
                }
            },
            {
                path: 'return-manage-list',
                component: ReturnManageList,
                meta: {
                    title: '退货管理'
                }
            }
        ]
    }
];

export default routes;
