import {
    CALogin,
    getUpmsUserInfo,
    queryMenu,
    userLoginout,
    getProjectMenu,
    getMessaeCount,
    getTaskType

} from '@/api';
import {
    SET_USERINFO,
    GET_APPLY_MENU,
    CLEAR_PAGE_TAGS,
    SET_MESSAGE_COUNT,
    SET_TASK_TYPE,
    SAVE_HOSP_DATA,
    SAVE_USER_MODULE_NUM
} from './mutation-type';


// 登录
const loginWithCA = ({ commit }, params) => (
    new Promise((resolve, reject) => {
        CALogin(params).then(res => {
            /* eslint no-param-reassign: 'off' */
            if (res.code !== '0') {
                reject(res.message);
            }
            if (res && res.code === '0') {
                commit(SET_USERINFO, res.data); // 设置用户信息
                resolve(res);
            }
        }).catch(e => reject(e));
    })
);

// 获取用户信息
const fetchUserInfo = ({ commit }) => (
    new Promise((resolve, reject) => {
        getUpmsUserInfo().then(res => {
            if (res) {
                localStorage.setItem('userInfo', JSON.stringify(res.data));
                commit(SET_USERINFO, res.data);
            } else {
                commit(SET_USERINFO, {});
            }
            resolve(res);
        }).catch(e => reject(e));
    })
);
// 清空项目
const clearPageTags = ({ commit }) => (
    commit(CLEAR_PAGE_TAGS)
);

// 退出
const fetchLoginOut = ({ commit }) => (
    new Promise((resolve, reject) => {
        userLoginout().then(res => {
            commit(SET_USERINFO, {});
            commit(CLEAR_PAGE_TAGS);
            localStorage.setItem('token', ''); // 清除token
            localStorage.setItem('user-key', ''); // 清除token
            resolve(res);
        }).catch(e => {
            commit(SET_USERINFO, {});
            commit(CLEAR_PAGE_TAGS);
            localStorage.setItem('token', ''); // 清除token
            localStorage.setItem('user-key', ''); // 清除token
            reject(e);
        });
    })
);

// 获取项目外菜单
const fetchApplyMenu = ({ commit }) => (
    new Promise((resolve, reject) => {
        queryMenu().then(res => {
            if (res.code !== 0) {
                reject(res.message);
            }
            if (res && res.code === 0) {
                // console.log('new::::', JSON.stringify(res.data[0]));
                // res.data[0].path = 'hxcm';
                commit(GET_APPLY_MENU, res.data);
                resolve(res);
            }
        }).catch(e => reject(e));
    })
);

// 获取项目内菜单
const fetchProjectMenu = ({ commit }, params) => (
    new Promise((resolve, reject) => {
        getProjectMenu(params).then(res => {
            if (res.code !== 0) {
                reject(res.message);
            }
            if (res && res.code === 0) {
                commit(GET_APPLY_MENU, res.data);
                resolve(res);
            }
        }).catch(e => reject(e));
    })
);


// 获取用户消息数量
const fetchUserMessageCount = ({ commit }, params) => (
    new Promise((resolve, reject) => {
        getMessaeCount(params).then(res => {
            if (res.code !== 0) {
                commit(SET_MESSAGE_COUNT, 0);
                reject(res.message);
            }
            if (res && res.code === 0) {
                commit(SET_MESSAGE_COUNT, res.data);
                resolve(res);
            }
        }).catch(e => reject(e));
    })
);

const fetchTaskType = ({ commit }, params) => (
    new Promise((resolve, reject) => {
        getTaskType(params).then(res => {
            if (res.code !== 0) {
                reject(res.message);
            }
            if (res && res.code === 0) {
                commit(SET_TASK_TYPE, res.data);
                resolve(res);
            }
        }).catch(e => reject(e));
    })
);

const saveHospData = ({ commit }, params) => {
    commit(SAVE_HOSP_DATA, params);
};

const saveUserModuleNum = ({ commit }, params) => {
    commit(SAVE_USER_MODULE_NUM, params);
};

export default {
    loginWithCA,
    fetchUserInfo,
    fetchApplyMenu,
    fetchProjectMenu,
    fetchLoginOut,
    fetchUserMessageCount,
    fetchTaskType,
    saveHospData,
    saveUserModuleNum,
    clearPageTags
};
