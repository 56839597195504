/*
 * @Author: shandan
 * @Email: shandan@guahao.com
 * @Date: 2019-10-28 10:12:33
 * @Last Modified by: shandan
 * @Last Modified time: 2021-05-20 10:56:30
 * @Description: 资质预警
 * @Route: Route
 */

import View from '@/components/view';
// import consumable from './consumable';
import drug from './drug';

export default [
    {
        path: 'qualify-warning',
        component: View,
        meta: {
            title: '资质预警'
        },
        children: [
            // ...consumable,
            ...drug
        ]
    }
];