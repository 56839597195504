/*
 * @Author: qiuwt
 * @Email: qiuwt@hxmec.com
 * @Date: 2020-01-16 16:19:35
 * @Last Modified by: huanggk
 * @Last Modified time: 2021-07-13 09:42:23
 * @Description: 微前端入口
 */


import Info from './base';
import routes from '../router/hxcm';

const VueLifecycles = Info({
    publicPath: 'cm-settle',
    routes
});

const store = window.Store;
// 添加项目内外菜单
if (store) {
    store.commit('SAVE_PROJECT_INFO', {
        name: 'cm-settle',
        params: {
            inner: '',
            outer: ''
        }
    });
}

export const bootstrap = [
    VueLifecycles.bootstrap
];
// 项目启动后的钩子
export const mount = [
    VueLifecycles.mount
];
// 项目卸载的钩子
export const unmount = [
    VueLifecycles.unmount
];
