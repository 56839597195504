/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-11
 * @lastModifiedBy yanghy
 * @lastModifiedTime 18:14
 * @route
 * @description 我的项目库/药品和耗材
*/

const DrugList = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/my-product/drug/list');
const DrugEdit = () => import(/* webpackChunkName: "edit" */ '@/pages/hxcm/tender/my-product/drug/edit');
const DrugDetail = () => import(/* webpackChunkName: "drug-detail" */ '@/pages/hxcm/tender/my-product/drug/detail');

const DrugAdminDetail = () => import(/* webpackChunkName: "DrugAdminDetail" */ '@/pages/hxcm/tender/my-product/drug/admin-detail');
const DrugHistory = () => import(/* webpackChunkName: "drug-history" */ '@/pages/hxcm/tender/my-product/drug/history');
const DrugAdminList = () => import(/* webpackChunkName: "DrugAdminList" */ '@/pages/hxcm/tender/my-product/drug/aduit-list');
const DrugAduit = () => import(/* webpackChunkName: "drug-aduit" */ '@/pages/hxcm/tender/my-product/drug/aduit');
const DrugContrast = () => import(/* webpackChunkName: "drug-contrast" */ '@/pages/hxcm/tender/my-product/drug/contrast-detail');

const ConsumableList = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/my-product/consumable/consumable-libary/list');
const ConsumableEdit = () => import(/* webpackChunkName: "edit" */ '@/pages/hxcm/tender/my-product/consumable/consumable-libary/edit');
const ConsumableDetail = () => import(/* webpackChunkName: "ConsumableDetail" */ '@/pages/hxcm/tender/my-product/consumable/consumable-libary/detail');
const ConsumableHistory = () => import(/* webpackChunkName: "ConsumableHistory" */ '@/pages/hxcm/tender/my-product/consumable/consumable-libary/history');
const ConsumableAuditList = () => import(/* webpackChunkName: "ConsumableAuditList" */ '@/pages/hxcm/tender/my-product/consumable/consumable-libary/aduit-list');
const ConsumableAudit = () => import(/* webpackChunkName: "ConsumableAudit" */ '@/pages/hxcm/tender/my-product/consumable/consumable-libary/audit');
const ConsumableContrastDetail = () => import(/* webpackChunkName: "ConsumableContrastDetail" */ '@/pages/hxcm/tender/my-product/consumable/consumable-libary/contrast-detail');
const consumableAdminDetail = () => import(/* webpackChunkName: "DrugContrastDetail" */ '@/pages/hxcm/tender/my-product/consumable/consumable-libary/admin-detail');

const QualifyLibaryList = () => import(/* webpackChunkName: "QualifyLibaryList" */ '@/pages/hxcm/tender/my-product/consumable/qualify-library/list');
const QualifyLibaryEdit = () => import(/* webpackChunkName: "QualifyLibaryEdit" */ '@/pages/hxcm/tender/my-product/consumable/qualify-library/edit');
const QualifyLibaryDetail = () => import(/* webpackChunkName: "QualifyLibaryDetail" */ '@/pages/hxcm/tender/my-product/consumable/qualify-library/detail');
const QualifyLibaryAdminDetail = () => import(/* webpackChunkName: "QualifyLibaryAdminDetail" */ '@/pages/hxcm/tender/my-product/consumable/qualify-library/admin-detail');
const QualifyLibaryAdd = () => import(/* webpackChunkName: "QualifyLibaryAdd" */ '@/pages/hxcm/tender/my-product/consumable/qualify-library/add');
const QualifyLibaryHistory = () => import(/* webpackChunkName: "QualifyLibaryAdd" */ '@/pages/hxcm/tender/my-product/consumable/qualify-library/history');
const QualifyLibaryAduitList = () => import(/* webpackChunkName: "QualifyLibaryAduitList" */ '@/pages/hxcm/tender/my-product/consumable/qualify-library/aduit-list');
const QualifyLibaryAduit = () => import(/* webpackChunkName: "QualifyLibaryAduitList" */ '@/pages/hxcm/tender/my-product/consumable/qualify-library/aduit');
const QualifyLibaryContrastDetail = () => import(/* webpackChunkName: "QualifyLibaryContrastDetail" */ '@/pages/hxcm/tender/my-product/consumable/qualify-library/contrast-detail');

// 不复审
const DrugListPlus = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/my-product/drug/list-plus');
const DrugDetailPlus = () => import(/* webpackChunkName: "drug-detail" */ '@/pages/hxcm/tender/my-product/drug/detail-plus');
const DrugAdminListPlus = () => import(/* webpackChunkName: "DrugAdminList" */ '@/pages/hxcm/tender/my-product/drug/aduit-list-plus');
const DrugAdminDetailPlus = () => import(/* webpackChunkName: "DrugAdminDetail" */ '@/pages/hxcm/tender/my-product/drug/admin-detail-plus');

const NoRecheckList = () => import(/* webpackChunkName: "NoRecheckList" */ '@/pages/hxcm/tender/my-product/consumable/no-recheck/list');
const NoRecheckDetail = () => import(/* webpackChunkName: "NoRecheckDetail" */ '@/pages/hxcm/tender/my-product/consumable/no-recheck/detail');

export default [
    {
        path: 'my-product/drug/list',
        component: DrugList,
        meta: {
            title: '我的药品库'
            // display: 'none'
        }
    },
    {
        path: 'my-product/drug/list/edit',
        component: DrugEdit,
        meta: {
            title: '编辑',
            display: 'none'
        }
    },
    {
        path: 'my-product/drug/list/detail',
        component: DrugDetail,
        meta: {
            title: '查看产品详情',
            display: 'none'
        }
    },
    {
        path: 'my-product/drug/aduit-list/detail',
        component: DrugAdminDetail,
        meta: {
            title: '查看产品详情',
            display: 'none'
        }
    },
    {
        path: 'my-product/drug/product-info',
        component: DrugListPlus,
        meta: {
            title: '我的产品资料'
            // display: 'none'
        }
    },
    {
        path: 'my-product/drug/product-info/detail',
        component: DrugDetailPlus,
        meta: {
            title: '查看产品详情',
            display: 'none'
        }
    },
    {
        path: 'my-product/drug/list/detail/history',
        component: DrugHistory,
        meta: {
            title: '查看历史',
            display: 'none'
        }
    },
    {
        path: 'my-product/drug/aduit-list',
        component: DrugAdminList,
        meta: {
            title: '产品资质审核'
            // display: 'none'
        }
    },
    {
        path: 'my-product/drug/aduit-list/aduit',
        component: DrugAduit,
        meta: {
            title: '审核详情',
            display: 'none'
        }
    },
    {
        path: 'my-product/drug/product-library',
        component: DrugAdminListPlus,
        meta: {
            title: '产品库'
            // display: 'none'
        }
    },
    {
        path: 'my-product/drug/product-library/detail',
        component: DrugAdminDetailPlus,
        meta: {
            title: '查看产品详情',
            display: 'none'
        }
    },
    {
        path: 'my-product/drug/aduit-list/aduit/history',
        component: DrugHistory,
        meta: {
            title: '查看历史',
            display: 'none'
        }
    },
    {
        path: 'my-product/drug/aduit-list/aduit/contrast-detail',
        component: DrugContrast,
        meta: {
            title: '比对详情',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/consumable-libary/list',
        component: ConsumableList,
        meta: {
            title: '我的耗材库'
            // display: 'none'
        }
    },
    {
        path: 'my-product/consumable/consumable-libary/list/edit',
        component: ConsumableEdit,
        meta: {
            title: '编辑',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/consumable-libary/list/add',
        component: ConsumableEdit,
        meta: {
            title: '新增',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/consumable-libary/list/detail',
        component: ConsumableDetail,
        meta: {
            title: '查看耗材详情',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/consumable-libary/list/detail/history',
        component: ConsumableHistory,
        meta: {
            title: '查看历史',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/consumable-libary/audit-list',
        component: ConsumableAuditList,
        meta: {
            title: '耗材资质审核'
            // display: 'none'
        }
    },
    {
        path: 'my-product/consumable/consumable-libary/audit-list/audit',
        component: ConsumableAudit,
        meta: {
            title: '产品详情',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/consumable-libary/audit-list/detail',
        component: consumableAdminDetail,
        meta: {
            title: '产品详情',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/consumable-libary/audit-list/audit/contrast-detail',
        component: ConsumableContrastDetail,
        meta: {
            title: '对比详情',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/qualify-libary/list',
        component: QualifyLibaryList,
        meta: {
            title: '我的耗材资质库'
            // display: 'none'
        }
    },
    {
        path: 'my-product/consumable/qualify-libary/list/add',
        component: QualifyLibaryAdd,
        meta: {
            title: '新增耗材资质详情',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/qualify-libary/list/edit',
        component: QualifyLibaryEdit,
        meta: {
            title: '编辑耗材资质详情',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/qualify-libary/list/detail',
        component: QualifyLibaryDetail,
        meta: {
            title: '查看耗材资质详情',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/qualify-libary/aduit-list/admin-detail',
        component: QualifyLibaryAdminDetail,
        meta: {
            title: '查看耗材资质详情',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/qualify-libary/list/detail/history',
        component: QualifyLibaryHistory,
        meta: {
            title: '查看历史记录',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/qualify-libary/aduit-list',
        component: QualifyLibaryAduitList,
        meta: {
            title: '耗材资质库审核'
            // display: 'none'
        }
    },
    {
        path: 'my-product/consumable/qualify-libary/aduit-list/aduit',
        component: QualifyLibaryAduit,
        meta: {
            title: '资质详情',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/qualify-libary/aduit-list/aduit/contrast-detail',
        component: QualifyLibaryContrastDetail,
        meta: {
            title: '比对详情',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/no-recheck/enterprise-org/list',
        component: NoRecheckList,
        meta: {
            title: '我的产品资料',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/no-recheck/enterprise-org/list/detail',
        component: NoRecheckDetail,
        meta: {
            title: ' 查看耗材详情',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/no-recheck/regulator-org/list',
        component: NoRecheckList,
        meta: {
            title: '产品库',
            display: 'none'
        }
    },
    {
        path: 'my-product/consumable/no-recheck/regulator-org/list/detail',
        component: NoRecheckDetail,
        meta: {
            title: ' 查看耗材详情',
            display: 'none'
        }
    }
];
