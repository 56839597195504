import View from '@/components/view';


const routes = [
    {
        path: 'contract-signing',
        component: View,
        meta: {
            title: '国家集采合同签订'
        },
        children: [
            {
                path: 'index',
                component: () => import(/* webpackChunkName: "prod-contract-signing" */ '@/pages/hxcm/settle/product/contract-signing/index'),
                meta: {
                    title: '国家集采合同签订'
                }
            },
            {
                path: 'index/into',
                component: () => import('@/pages/hxcm/settle/product/contract-signing/into'),
                meta: {
                    title: '查看'
                }
            }
        ]
    }
];
export default routes;
