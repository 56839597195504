/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 14:18
 * @route
 * @description 谈判管理
*/

import View from '@/components/view';
import ResultQuery from './result-query';
import RoundManage from './round-manage';

export default [
    {
        path: 'negotiate-manage',
        meta: {
            title: '谈判管理'
        },
        component: View,
        children: [
            ...ResultQuery,
            ...RoundManage
        ]
    }
];
