import View from '@/components/view';

const delivery = () => import(/* webpackChunkName: "delivery-settlement-apply" */'@/pages/hxcm/trade/common/settlement-apply/delivery');
const deliveryAdd = () => import(/* webpackChunkName: "delivery-settlement-apply" */ '@/pages/hxcm/trade/common/settlement-apply/delivery-add');
const medical = () => import(/* webpackChunkName: "medical-settlement-apply" */'@/pages/hxcm/trade/common/settlement-apply/medical');
const routes = [
    {
        path: 'settlement-apply/consumable/dispatching',
        component: View,
        meta: {
            title: '结算申请(耗材)'
        },
        children: [
            {
                path: 'index',
                component: delivery,
                meta: {
                    title: '结算申请(耗材配送)'
                }
            },
            {
                path: 'index/add',
                component: deliveryAdd,
                meta: {
                    title: '维护结算明细',
                    display: 'none'
                }
            }
        ]
    },
    {
        path: 'settlement-apply/consumable/medical',
        component: View,
        meta: {
            title: '结算申请(耗材医疗)'
        },
        children: [
            {
                path: 'index',
                component: medical,
                meta: {
                    title: '结算申请(医疗)'
                }
            },
            {
                path: 'index/add',
                component: deliveryAdd,
                meta: {
                    title: '维护结算明细',
                    display: 'none'
                }
            }
        ]
    }
];
export default routes;
