import View from '@/components/view';

// 目录管理-药品-配送企业
const catalogDefendList = () => import(/* webpackChunkName: "catalogDefendList" */ '@/pages/hxcm/settle/distribution-directory/drug/catalog-defend-list');
const catalogDefendListRead = () => import(/* webpackChunkName: "catalogDefendListRead" */ '@/pages/hxcm/settle/distribution-directory/drug/catalog-defend-list-read');
const inventoryMaintenance = () => import(/* webpackChunkName: "inventoryMaintenance" */ '@/pages/hxcm/settle/distribution-directory/drug/inventory-maintenance');
const secondaryBargainingList = () => import(/* webpackChunkName: "secondaryBargainingList" */ '@/pages/hxcm/settle/distribution-directory/drug/secondary-bargaining-list');
const deliveryLinkManManage = () => import(/* webpackChunkName: "deliveryLinkManManage" */ '@/pages/hxcm/settle/distribution-directory/components/delivery-linkman-manage');
const drugDirectory = () => import(/* webpackChunkName: "catalogDefendList" */ '@/pages/hxcm/settle/distribution-directory/drug/drug-directory');
const routes = [
    {
        path: 'distribution-directory/drug',
        component: View,
        meta: {
            title: '目录管理'
        },
        children: [
            {
                path: 'distribution-directory/list',
                component: catalogDefendList,
                meta: {
                    title: '配送目录查询'
                }
            },
            {
                path: 'distribution-directory/list/read',
                component: catalogDefendListRead,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'inventory-maintenance',
                component: inventoryMaintenance,
                meta: {
                    title: '配送库存维护'
                }
            },
            {
                path: 'secondary-bargaining-list',
                component: secondaryBargainingList,
                meta: {
                    title: '议价管理'
                }
            },
            {
                path: 'delivery-linkman-manage',
                component: deliveryLinkManManage,
                meta: {
                    title: '企业联系人管理'
                }
            },
            {
                path: 'drug-directory',
                component: drugDirectory,
                meta: {
                    title: '药品目录查询'
                }
            },
            {
                path: 'drug-directory/read',
                component: catalogDefendListRead,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
