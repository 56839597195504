/*
 * @Author: xiaoyu
 * @Email: wangxy2@guahao.com
 * @Date: 2019-12-11
 * @Last Modified by: hejk
 * @Last Modified time: 2021-03-25 11:17:01
 * @Description: 药品目录管理路由
 */

const DrugList = () => import(/* webpackChunkName: "DrugList" */ '@/pages/hxcm/tender/catalog-manage/catalog/drug/list');
const DrugMatch = () => import(/* webpackChunkName: "DrugMatch" */ '@/pages/hxcm/tender/catalog-manage/catalog/drug/match');
export default [
    {
        path: 'catalog/drug/list',
        component: DrugList,
        meta: {
            title: '目录管理-管理端'
        }
    },
    {
        path: 'catalog/drug/list/match',
        component: DrugMatch,
        meta: {
            title: '匹配',
            display: 'none',
            noTag: true
        }
    },
    {
        path: 'catalog/drug/purchase-list',
        component: () => import(/* webpackChunkName: "purchase-list" */ '@/pages/hxcm/tender/catalog-manage/catalog/drug/purchase-list'),
        meta: {
            title: '采购目录管理-管理端'
        }
    }
];
