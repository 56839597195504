import View from '@/components/view';


const delivery = () => import(/* webpackChunkName: "delivery-settlement-apply" */'@/pages/hxcm/settle/common/account-checking/delivery');
const deliveryChecking = () => import(/* webpackChunkName: "delivery-settlement-apply" */'@/pages/hxcm/settle/common/account-checking/delivery-checking');
const deliveryAdd = () => import(/* webpackChunkName: "delivery-settlement-apply" */ '@/pages/hxcm/settle/common/settlement-apply/delivery-add');

const dispatchingAccountChecking = [
    {
        path: 'acccount-checking',
        component: View,
        meta: {
            title: '对账管理(耗材)'
        },
        children: [
            {
                path: 'index',
                component: delivery,
                meta: {
                    title: '对账管理(配送)'
                }
            },
            {
                path: 'index/checking',
                component: deliveryChecking,
                meta: {
                    title: '对账',
                    display: 'none'
                }
            },
            {
                path: 'index/checking/detail',
                component: deliveryAdd,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'index/checking-list/detail',
                component: () => import('@/pages/hxcm/settle/common/settlement-apply/delivery-add'),
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            }
        ]
    }
];
const medicalAccountChecking = [
    {
        path: 'acccount-checking',
        component: View,
        meta: {
            title: '对账管理(耗材)'
        },
        children: [
            {
                path: 'index',
                component: delivery,
                meta: {
                    title: '对账管理(医疗)'
                }
            },
            {
                path: 'index/checking',
                component: deliveryChecking,
                meta: {
                    title: '对账',
                    display: 'none'
                }
            },
            {
                path: 'index/checking-list/detail',
                component: () => import('@/pages/hxcm/settle/common/settlement-apply/delivery-add'),
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'index/checking/detail',
                component: deliveryAdd,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            }
        ]
    }
];
export {
    dispatchingAccountChecking,
    medicalAccountChecking
};
