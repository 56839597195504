/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2021-02-24 16:44:17
 * @Last Modified by: hejk
 * @Last Modified time: 2021-03-25 11:13:11
 * @Description: 采购目录管理(捏合)
 */
const PurchaseCatalog = () => import(/* webpackChunkName: "purchase-catalog" */'@/pages/hxcm/tender/catalog-manage/purchase-catalog');

export default [
    {
        path: 'purchase-catalog',
        component: PurchaseCatalog,
        meta: {
            title: '采购目录管理',
            desc: '(耗材捏合)'
        }
    }
];
