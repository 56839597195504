/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2021-05-21 09:24:42
 * @Last Modified by: caoyp
 * @Last Modified time: 2021-06-08 20:15:05
 * @Description: 动态调整管理
 */

import View from '@/components/view';

const SpreadList = () => import(/* webpackChunkName: "statistics-list" */ '@/pages/hxcm/tender/adjustment/spread/index');
const SpreadDetails = () => import(/* webpackChunkName: "statistics-list" */ '@/pages/hxcm/tender/adjustment/spread/details');
const SpreadCheckout = () => import(/* webpackChunkName: "statistics-list" */ '@/pages/hxcm/tender/adjustment/spread/checkout');
const SpreadPublic = () => import(/* webpackChunkName: "statistics-list" */ '@/pages/hxcm/tender/adjustment/spread/public-details');

const distributionList = () => import(/* webpackChunkName: "statistics-list" */ '@/pages/hxcm/tender/adjustment/distribution/index');
const distributionDetails = () => import(/* webpackChunkName: "statistics-list" */ '@/pages/hxcm/tender/adjustment/distribution/details');
const distributionCheckout = () => import(/* webpackChunkName: "statistics-list" */ '@/pages/hxcm/tender/adjustment/distribution/checkout');
const distributionPublic = () => import(/* webpackChunkName: "statistics-list" */ '@/pages/hxcm/tender/adjustment/distribution/public-details');
export default [
    {
        path: 'adjustment-administration',
        component: View,
        meta: {
            title: '动态调整管理'
        },
        children: [
            {
                path: 'spreadList/index/list',
                component: SpreadList,
                meta: {
                    title: '挂网目录调整'
                }
            },
            {
                path: 'spreadList/index/list/detail',
                component: SpreadDetails,
                meta: {
                    title: '发起调整',
                    display: 'none'
                }
            },
            {
                path: 'spreadList/index/list/fund',
                component: SpreadCheckout,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'spreadList/index/list/fund/detail',
                component: SpreadPublic,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'distributionList/index/list',
                component: distributionList,
                meta: {
                    title: '配送关系调整'
                }
            },
            {
                path: 'distributionList/index/list/detail',
                component: distributionDetails,
                meta: {
                    title: '发起调整',
                    display: 'none'
                }
            },
            {
                path: 'distributionList/index/list/fund',
                component: distributionCheckout,
                meta: {
                    title: '调整日志',
                    display: 'none'
                }
            },
            {
                path: 'distributionList/index/list/fund/detail',
                component: distributionPublic,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            }
        ]
    }
];
