import View from '@/components/view';

const routes = [
    {
        path: 'project-maintain',
        component: View,
        meta: {
            title: '项目维护【生产企业】'
        },
        children: [
            {
                path: 'index',
                component: () => import('@/pages/hxcm/settle/common/project-maintain/product'),
                meta: {
                    title: '项目维护'
                }
            },
            {
                path: 'index/change',
                component: () => import('@/pages/hxcm/settle/common/project-maintain/product-change'),
                meta: {
                    title: '变更',
                    display: 'none'
                }
            }
        ]
    }
];
export default routes;
