/*
 * @Author: maozg
 * @Email: maozg@guahao.com
 * @Date: 2019-10-31 15:54:46
 * @Last Modified by: hejk
 * @Last Modified time: 2021-04-15 17:27:05
 * @Description: 购销协议管理模块
 */
import View from '@/components/view';

const medicalList = () => import('@/pages/hxcm/trade/agreement-manage/drug/medical-agreement-list.vue');
const expressList = () => import('@/pages/hxcm/trade/agreement-manage/drug/express-agreement-list.vue');
const regulatorList = () => import('@/pages/hxcm/trade/agreement-manage/drug/regulator-agreement-list.vue');
const Sign = () => import('@/pages/hxcm/trade/agreement-manage/signature.vue');


export default [
    {
        path: 'drug/agreement-manage',
        component: View,
        meta: {
            title: '购销协议管理'
        },
        children: [
            {
                path: 'medical-list',
                component: medicalList,
                meta: {
                    title: '购销协议管理'
                }
            },
            {
                path: 'medical-list/sign',
                component: Sign,
                meta: {
                    title: '签订',
                    display: 'none'
                }
            },
            {
                path: 'express-list',
                component: expressList,
                meta: {
                    title: '购销协议管理'
                }
            },
            {
                path: 'express-list/sign',
                component: Sign,
                meta: {
                    title: '签订',
                    display: 'none'
                }
            },
            {
                path: 'regulator-list',
                component: regulatorList,
                meta: {
                    title: '购销协议管理'

                }
            },
            {
                path: 'regulator-list/sign',
                component: Sign,
                meta: {
                    title: '签订',
                    display: 'none'
                }
            }
        ]
    }
];
