/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 11:38
 * @route
 * @description 竞价管理/竞价结果查询
*/

const BiddingList = () => import(/* webpackChunkName: "bindding-list" */ '@/pages/hxcm/tender/bidding-manage/result-query/bindding-list');

export default [
    {
        path: 'result-query/regulator-org/list',
        component: BiddingList,
        meta: {
            title: '竞价结果管理'
            // display: 'none'
        }
    },
    {
        path: 'result-query/product-org/list',
        component: BiddingList,
        meta: {
            title: '竞价结果查询'
            // display: 'none'
        }
    }
];
