/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2021-05-31 16:34:38
 * @Last Modified by: yanchunl
 * @Last Modified time: 2021-07-24 14:18:22
 * @Description: 产品转产审核
 * @Route: Route
 */

import View from '@/components/view';
import ProductOrg from './product-org';
import RegulatorOrg from './regulator-org';

export default [
    {
        path: 'product-conversion',
        meta: {
            title: '产品转产审核'
        },
        component: View,
        children: [
            ...ProductOrg,
            ...RegulatorOrg
        ]
    }
];
