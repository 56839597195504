/*
 * @Author: lina
 * @Email: lina5@guahao.com
 * @Date: 2019-09-04 15:40:53
 * @Last Modified by: yanghy5
 * @Last Modified time: 2020-01-08 17:28:12
 * @Description: 模糊查询接口
 * @Route: Route
 */

// 目录名称/通用名
export const apiCommonName = '/web/bdc/mdcDictCodeSvc/genericNameFuzzyQuery';
// 品名
export const apiProductName = '/web/bdc/drugInfo/queryGroupDrugNameList';
// 剂型 
export const apiDosageName = '/web/bdc/mdcDictCodeSvc/queryBysmlNameList';
// 规格
export const apiSpec = '/web/bdc/mdcDictCodeSvc/calcSpecFuzzyQuery';
// 包材
export const apiPacMatl = '/web/bdc/mdcDictCodeSvc/queryDictList';
// 生产企业/报名企业
export const apiOrgName = '/web/bdc/org/page';
// 计算用规格
export const queryCalculationSpecPage = '/web/bdc/mdcDictCodeSvc/queryCalculationSpecPage';
// 规格属性
export const queryDictPage = '/web/bdc/mdcDictCodeSvc/queryDictPage';
// 差比价剂型
export const queryDosageFormPage = '/web/bdc/mdcDictCodeSvc/queryDosageFormPage';
// 资质名称
export const apiQualList = '/web/tender/dcla/base/qua';