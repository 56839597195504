/*
 * @Author: zhangzc
 * @Email: zhangzc2@guahao.com
 * @Date: 2019-07-05 17:05:28
 * @Last Modified by: huanggk
 * @Last Modified time: 2021-06-21 20:46:01
 * @Description: 空路由容器
 * @Route: Route
 */
<template>
    <a-config-provider :locale="locale">
        <div class="main-container"
             :class="{'dev-main-container':env === 'development'}">
            <keep-alive ref="keep">
                <router-view v-if="$route.meta.display !== 'none'"
                             ref="page" />
            </keep-alive>
            <router-view v-if="$route.meta.display==='none'" />
        </div>
    </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            env: process.env.NODE_ENV,
            locale: zhCN,
            /**
             * 缓存加载id
             *  */

            cacheId: {},
            cacheObj: {},
            /** *
             * 记录tag变化
             */
            oldTags: '[]'
        };
    },
    computed: {
        ...mapGetters(['pageTagsData'])
    },
    mounted() {
    },
    methods: {
        addCacheId() {
            const { fullPath, meta } = this.$route;
            if (meta.display !== 'none') {
                let name = this.$refs.page.$vnode.tag;
                name = name.replace(/^vue-component-/, '');
                this.cacheId[fullPath] = name;
                // this.cacheObj[fullPath] = this;
            }
        }
    }
};
</script>
