import View from '@/components/view';


const delivery = () => import(/* webpackChunkName: "delivery-settlement-apply" */'@/pages/hxcm/trade/common/account-checking/delivery');
const deliveryChecking = () => import(/* webpackChunkName: "delivery-settlement-apply" */'@/pages/hxcm/trade/common/account-checking/delivery-checking');
const deliveryAdd = () => import(/* webpackChunkName: "delivery-settlement-apply" */ '@/pages/hxcm/trade/common/settlement-apply/delivery-add');

const routes = [
    {
        path: 'acccount-checking/drug/dispatching',
        component: View,
        meta: {
            title: '对账管理(药品)'
        },
        children: [
            {
                path: 'index',
                component: delivery,
                meta: {
                    title: '对账管理(配送)'
                }
            },
            {
                path: 'index/checking',
                component: deliveryChecking,
                meta: {
                    title: '对账',
                    display: 'none'
                }
            },
            {
                path: 'index/checking-list/detail',
                component: () => import('@/pages/hxcm/trade/common/settlement-apply/delivery-add'),
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'index/checking/detail',
                component: deliveryAdd,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            }
        ]
    },
    {
        path: 'acccount-checking/drug/medical',
        component: View,
        meta: {
            title: '对账管理(药品)'
        },
        children: [
            {
                path: 'index',
                component: delivery,
                meta: {
                    title: '对账管理(医疗)'
                }
            },
            {
                path: 'index/checking',
                component: deliveryChecking,
                meta: {
                    title: '对账',
                    display: 'none'
                }
            },
            {
                path: 'index/checking-list/detail',
                component: () => import('@/pages/hxcm/trade/common/settlement-apply/delivery-add'),
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'index/checking/detail',
                component: () => import('@/pages/hxcm/trade/common/settlement-apply/delivery-add'),
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            }
        ]
    }
];
export default routes;
