/*
 * @Author: shandan2
 * @Email: shandan2@guahao.com
 * @Date: 2019-11-01 09:48:30
 * @Last Modified by: shandan
 * @Last Modified time: 2020-09-07 10:35:07
 * @Description: 议价状态
 */
export default [
    // {
    //     label: '全部',
    //     value: ''
    // },
    {
        label: '待医疗机构报价',
        value: '2'
    },
    {
        label: '待企业报价',
        value: '1'
    },
    {
        label: '双方达成一致',
        value: '3'
    }
];