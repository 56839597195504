/*
 * @Author: guoxy
 * @Email: guoxy1@guahao.com
 * @Date: 2020-10-06 17:55:46
 * @Last Modified by: guoxy
 * @Last Modified time: 2020-10-06 18:09:44
 * @Description: 阳光挂网
 * @Route: Route
 */
import View from '@/components/view';
import ProductOrg from './product-org';
import RegulatorOrg from './regulator-org';

export default [
    {
        path: 'sunshine-hanging',
        component: View,
        meta: {
            title: '阳光挂网'
        },
        children: [
            ...ProductOrg,
            ...RegulatorOrg
        ]
    }
];