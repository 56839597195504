import View from '@/components/view';
import Manage from '@/pages/hxcm/trade/mgr/trading-manage/manage';

const OrderTracking = () => import(/* webpackChunkName: "mcs-order-tracking" */'@/pages/hxcm/trade/mgr/trading-manage/mcs-order-tracking');
const OrderDetail = () => import('@/pages/hxcm/trade/order-manage/drug/order-details');
const OrderDetailMcs = () => import('@/pages/hxcm/trade/order-manage/consumable/order-details');

const routes = [
    {
        path: 'trading-manage/consumable',
        component: View,
        meta: {
            title: '交易管理'
        },
        children: [
            {
                path: 'manage',
                component: Manage,
                meta: {
                    title: '采购单管理'
                }
            },
            {
                path: 'manage/order-detail',
                component: OrderDetail,
                meta: {
                    title: '订单明细',
                    display: 'none'
                }
            },
            {
                path: 'order-tracking',
                component: OrderTracking,
                meta: {
                    title: '订单明细追踪'
                }
            },
            {
                path: 'purchase-order-manage',
                component: () => import('@/pages/hxcm/trade/mgr/trading-manage/mcs-manage'),
                meta: {
                    title: '采购订单管理'
                }
            },
            {
                path: 'purchase-order-manage/order-detail',
                component: OrderDetailMcs,
                meta: {
                    title: '订单明细',
                    display: 'none'
                }
            },
            {
                path: 'order-plan-audit',
                component: () => import('@/pages/hxcm/trade/mgr/trading-manage/order-plan-audit'),
                meta: {
                    title: '采购计划审核'
                }
            },
            {
                path: 'order-plan-audit/review',
                component: () => import('@/pages/hxcm/trade/plan-manage/consumable/review'),
                meta: {
                    title: '审核',
                    display: 'none'
                }
            },
            {
                path: 'order-plan-audit/detail',
                component: () => import('@/pages/hxcm/trade/plan-manage/consumable/review'),
                meta: {
                    title: '详情',
                    display: 'none'
                }
            }
        ]
    }
];
export default routes;
