import View from '@/components/view';
import catalogManagement from './catalog-management';
import ProjectMaintain from './project-maintain';
import ContractSigning from './contract-signing';
import PurchaseCatalogue from './purchase-choosed-catalogue';

const routes = [
    {
        path: 'medical',
        component: View,
        meta: {
            title: '医院端'
        },
        children: [
            ...catalogManagement,
            ...ProjectMaintain,
            ...ContractSigning,
            ...PurchaseCatalogue,
            {
                path: 'reference-price',
                component: () => import('@/pages/hxcm/settle/medical/reference-price'),
                meta: {
                    title: '低价药交易价格'
                }
            },
            {
                path: 'second-argainin-management/list',
                component: () => import('@/pages/hxcm/settle/medical/second-argainin-management'),
                meta: {
                    title: '议价配送管理'
                }
            },
            {
                path: 'second-argainin-management/list/add',
                component: () => import('@/pages/hxcm/settle/medical/second-argainin-management-add'),
                meta: {
                    title: '新增议价',
                    display: 'none'
                }
            }
        ]
    }
];
export default routes;
