/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-13
 * @lastModifiedBy yanghy
 * @lastModifiedTime 11:30
 * @route
 * @description 项目配置
*/

const List = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/project-config/list');

export default [
    {
        path: 'tendering-manage/list/project-config',
        component: List,
        meta: {
            title: '项目配置'
            // display: 'none'
        }
    }
];
