import View from '@/components/view';

const deliveryAdd = () => import(/* webpackChunkName: "delivery-settlement-apply" */ '@/pages/hxcm/trade/common/settlement-apply/delivery-add');
const done = () => import(/* webpackChunkName: "checking-manage-done" */ '@/pages/hxcm/trade/mgr/checking-manage/done');
const ReviewResult = () => import(/* webpackChunkName: "checking-manage-review-result" */ '@/pages/hxcm/trade/mgr/checking-manage/review-result');
const orderDetail = () => import(/* webpackChunkName: "checking-manage-order-detail" */ '@/pages/hxcm/trade/mgr/checking-manage/order-detail');
const Log = () => import(/* webpackChunkName: "checking-log" */'@/pages/hxcm/trade/components/log');

const CheckingManage = () => import('@/pages/hxcm/trade/mgr/checking-manage');
const routes = [
    {
        path: 'checking-manage/consumable',
        component: View,
        meta: {
            title: '对账管理'
        },
        children: [
            {
                path: 'index',
                component: CheckingManage,
                meta: {
                    title: '生成对账单'
                }
            },
            {
                path: 'index/log',
                component: Log,
                meta: {
                    title: '任务日志',
                    display: 'none'
                }
            },
            {
                path: 'index/look',
                component: deliveryAdd,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'index/done',
                component: done,
                meta: {
                    title: '已生成查看',
                    display: 'none'
                }
            },
            {
                path: 'index/done/look',
                component: deliveryAdd,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'review',
                component: ReviewResult,
                meta: {
                    title: '对账结果查询'
                }
            },
            {
                path: 'review/order-detail',
                component: orderDetail,
                meta: {
                    title: '对账单明细',
                    display: 'none'
                }
            },
            {
                path: 'review/order-detail/detail',
                component: deliveryAdd,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            }
        ] 
    }
];
export default routes;