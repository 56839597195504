/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2021-08-10 11:14:32
 * @Description: 企业信息弹窗
 */
<template>
    <div>
        <bk-dialog :dialog-config="dialogConfig"
                   :dialog-visible="dialogVisible" />
    </div>
</template>
<script>
import Field from '@/mixins/field';

export default {
    props: {
        orgCode: {
            require: true,
            type: String,
            default: ''
        },
        prodId: {
            type: String,
            default: ''
        }
    },
    data() {
        const form = ['企业名称', '企业编号', '企业联系人', '企业联系电话', '统一社会信用代码', '营业执照有效日期', '生产或经营许可证号', '生产或经营许可证有效期'];
        const formData = Field.getFormInfo(form, { grid: 2 });
        return {
            dialogVisible: {
                info: false
            },
            dialogConfig: {
                data: [
                    {
                        name: 'info',
                        extend: {
                            title: '企业信息',
                            width: '700px'
                        },
                        data: [
                            {
                                name: 'form',
                                type: 'form',
                                formConfig: {
                                    readonly: true,
                                    http: {
                                        url: '/web/trans/org/get_info',
                                        method: 'post',
                                        onBefore: p => {
                                            p.orgCode = this.orgCode;
                                            p.prodCode = this.prodId;
                                        }
                                    },
                                    data: [
                                        {
                                            data: formData
                                        }
                                    ]
                                }
                            }
                        ],
                        noDefaultButtons: true,
                        extraButtons: [
                            {
                                label: '关闭',
                                name: 'close',
                                extend: {
                                    type: ''
                                },
                                callback: el => {
                                    el.dialogVisible.info = false;
                                }
                            }
                        ]
                    }
                ]
            }
        };
    }
};
</script>
