/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2021-05-21 09:24:42
 * @Last Modified by: caoyp
 * @Last Modified time: 2021-06-08 20:15:05
 * @Description: 审核人员分配
 */

const ReviewList = () => import(/* webpackChunkName: "review-list" */ '@/pages/hxcm/tender/review-assign/list');

export default [
    {
        path: 'review-assign/list',
        component: ReviewList,
        meta: {
            title: '审核人员分配'
        }
    }
];
