/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-13
 * @lastModifiedBy yanghy
 * @lastModifiedTime 12:31
 * @route
 * @description 公告管理
*/

const AnnouncementList = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/announcement-manage/list');
const AnnouncementAdd = () => import(/* webpackChunkName: "add" */ '@/pages/hxcm/tender/announcement-manage/add');
const AnnouncementDetail = () => import(/* webpackChunkName: "detail" */ '@/pages/hxcm/tender/announcement-manage/detail');
export default [
    {
        path: 'announcement-manage/list',
        component: AnnouncementList,
        meta: {
            title: '公告管理'
        }
    },
    {
        path: 'announcement-manage/list/add',
        component: AnnouncementAdd,
        meta: {
            title: '新增',
            display: 'none'
        }
    },
    {
        path: 'announcement-manage/list/edit',
        component: AnnouncementAdd,
        meta: {
            title: '编辑',
            display: 'none'
        }
    },
    {
        path: 'announcement-manage/list/detail',
        component: AnnouncementDetail,
        meta: {
            title: '查看',
            display: 'none'
        }
    }
];
