import View from '@/components/view';

const SupplementManage = () => import(/* webpackChunkName: "supplement-manage" */ '@/pages/hxcm/tender/declare-manage/supplement-manage'); // 企业资料增补管理
const SupplementConfig = () => import(/* webpackChunkName: "supplement-config" */ '@/pages/hxcm/tender/declare-manage/supplement-config'); // 企业资料增补配置
const SupplementDeclare = () => import(/* webpackChunkName: "supplement-manage" */ '@/pages/hxcm/tender/declare-manage/supplement-declare'); // 企业资料增补申报
const FieldConfig = () => import(/* webpackChunkName: "field-config" */ '@/pages/hxcm/tender/declare-manage/field-config'); // 企业增补资料字段配置
const Detail = () => import(/* webpackChunkName: "supplement-detail" */ '@/pages/hxcm/tender/declare-manage/detail'); // 企业增补资料详情

const ProdKnead = () => import(/* webpackChunkName: "prod-knead" */ '@/pages/hxcm/tender/declare-manage/prod-knead'); // 产品捏合
const ProdKneadLog = () => import(/* webpackChunkName: "prod-knead-log" */ '@/pages/hxcm/tender/declare-manage/prod-knead/log'); // 操作日志
const prodKneaded = () => import(/* webpackChunkName: "prod-kneaded" */ '@/pages/hxcm/tender/declare-manage/prod-knead/kneaded'); // 已捏合
const ProdKneading = () => import(/* webpackChunkName: "prod-kneading" */ '@/pages/hxcm/tender/declare-manage/prod-knead/kneading'); // 未捏合
const KneadEnroll = () => import(/* webpackChunkName: "knead-enroll" */ '@/pages/hxcm/tender/declare-manage/knead-enroll'); // 捏合产品报名
const KneadEnrollDetail = () => import(/* webpackChunkName: "knead-enroll-detail" */ '@/pages/hxcm/tender/declare-manage/knead-enroll/detail'); // 详情
const KneadEnrollManage = () => import(/* webpackChunkName: "knead-enroll-manage" */ '@/pages/hxcm/tender/declare-manage/knead-enroll-manage'); // 捏合产品报名管理

const historyPublicityList = () => import(/* webpackChunkName: "history-publicity-list" */ '@/pages/hxcm/tender/declare-manage/history-publicity/list'); // 历史价格公示
const historyPublicityDetail = () => import(/* webpackChunkName: "history-publicity-list" */ '@/pages/hxcm/tender/declare-manage/history-publicity/detail'); // 历史价格公示详情
const publicitySet = () => import(/* webpackChunkName: "publicity-set" */ '@/pages/hxcm/tender/declare-manage/publicity-set'); //  资料公示配置

const enterpriseResultsRegulator = () => import(/* webpackChunkName: "enterprise-results-list" */ '@/pages/hxcm/tender/declare-manage/enterprise-results/regulator-org/list'); //  企业报名结果公示管理
const enterpriseResultsProduct = () => import(/* webpackChunkName: "enterprise-results-list" */ '@/pages/hxcm/tender/declare-manage/enterprise-results/product-org/list'); //  企业报名结果公示公示
const enterpriseResultsDetail = () => import(/* webpackChunkName: "enterprise-results-list" */ '@/pages/hxcm/tender/declare-manage/enterprise-results/detail');

const productResultsRegulator = () => import(/* webpackChunkName: "enterprise-results-detail" */ '@/pages/hxcm/tender/declare-manage/product-results/regulator-org/list'); //  产品报名结果公示管理-详情
const productResultsProduct = () => import(/* webpackChunkName: "enterprise-results-detail" */ '@/pages/hxcm/tender/declare-manage/product-results/product-org/list'); //  产品报名结果公示

const routes = [
    {
        path: 'declare-manage',
        component: View,
        meta: {
            title: '申报管理'
        },
        children: [
            {
                path: 'supplement-manage',
                component: SupplementManage,
                meta: {
                    title: '企业资料增补管理'
                }
            },
            {
                path: 'supplement-manage/detail',
                component: Detail,
                meta: {
                    title: '查看企业详情',
                    display: 'none'
                }
            },
            {
                path: 'supplement-manage/audit',
                component: Detail,
                meta: {
                    title: '审核企业资料增补',
                    display: 'none'
                }
            },
            {
                path: 'supplement-config',
                component: SupplementConfig,
                meta: {
                    title: '企业资料增补配置'
                }
            },
            {
                path: 'supplement-config/field-config',
                component: FieldConfig,
                meta: {
                    title: '企业增补资料字段配置',
                    display: 'none'
                }
            },
            {
                path: 'supplement-declare',
                component: SupplementDeclare,
                meta: {
                    title: '企业资料增补申报'
                }
            },

            // 承诺书上传
            {
                path: 'commitment-manage',
                component: SupplementManage,
                meta: {
                    title: '承诺书管理'
                }
            },
            {
                path: 'commitment-manage/detail',
                component: Detail,
                meta: {
                    title: '查看承诺书',
                    display: 'none'
                }
            },
            {
                path: 'commitment-manage/audit',
                component: Detail,
                meta: {
                    title: '审核承诺书',
                    display: 'none'
                }
            },
            {
                path: 'commitment-config',
                component: SupplementConfig,
                meta: {
                    title: '承诺书上传配置'
                }
            },
            {
                path: 'commitment-config/field-config',
                component: FieldConfig,
                meta: {
                    title: '承诺书上传字段配置',
                    display: 'none'
                }
            },
            {
                path: 'commitment-declare',
                component: SupplementDeclare,
                meta: {
                    title: '承诺书上传'
                }
            }, 
            // 历史价格
            {
                path: 'history-fill/set-config',
                component: () => import('@/pages/hxcm/tender/declare-manage/history-fill/set-config'),
                meta: {
                    title: '历史价格申报配置',
                    docs: '管理端'
                }
            },
            {
                path: 'history-fill/manage',
                component: () => import('@/pages/hxcm/tender/declare-manage/history-fill/manage'),
                meta: {
                    title: '历史价格申报管理',
                    docs: '管理端',
                    keep: true
                }
            },
            {
                path: 'history-fill/manage-aup',
                component: () => import('@/pages/hxcm/tender/declare-manage/history-fill/manage'),
                meta: {
                    title: '历史价格申报管理',
                    docs: '耗材管理端',
                    keep: true
                }
            },
            {
                path: 'history-fill/manage/info',
                component: () => import('@/pages/hxcm/tender/declare-manage/history-fill/info'),
                meta: {
                    title: '历史价格申报-详情',
                    docs: '管理端'
                }
            },
            {
                path: 'history-fill/manage-aup/info-aup',
                component: () => import('@/pages/hxcm/tender/declare-manage/history-fill/info-aup'),
                meta: {
                    title: '历史价格申报-详情',
                    docs: '耗材管理端'
                }
            },
            {
                path: 'history-fill/org-send',
                component: () => import('@/pages/hxcm/tender/declare-manage/history-fill/org-send'),
                meta: {
                    title: '历史价格申报',
                    docs: '企业端',
                    keep: true

                }
            },
            {
                path: 'history-fill/org-send-aup',
                component: () => import('@/pages/hxcm/tender/declare-manage/history-fill/org-send'),
                meta: {
                    title: '历史价格申报',
                    docs: '耗材企业端'
                }
            },
            {
                path: 'history-fill/org-send/info',
                component: () => import('@/pages/hxcm/tender/declare-manage/history-fill/info'),
                meta: {
                    title: '历史价格申报-详情',
                    docs: '企业端'
                }
            },
            {
                path: 'history-fill/org-send-aup/info-aup',
                component: () => import('@/pages/hxcm/tender/declare-manage/history-fill/info-aup'),
                meta: {
                    title: '历史价格申报-详情',
                    docs: '耗材企业端'
                }
            },
            {
                path: 'history-publicity/list',
                component: historyPublicityList,
                meta: {
                    title: '历史价格公示', // 药品
                    type: 'drug'
                }
            },
            {
                path: 'history-publicity/list/detail',
                component: historyPublicityDetail,
                meta: {
                    title: '查看', // 历史价格公示-药品-查看
                    type: 'drug'
                    // display: 'none'
                }
            },
            {
                path: 'prod-knead',
                component: ProdKnead,
                meta: {
                    title: '产品捏合'
                }
            },
            {
                path: 'prod-knead/log',
                component: ProdKneadLog,
                meta: {
                    title: '操作日志',
                    display: 'none'
                }
            },
            {
                path: 'prod-knead/kneaded',
                component: prodKneaded,
                meta: {
                    title: '已捏合',
                    display: 'none'
                }
            },
            {
                path: 'prod-knead/kneading',
                component: ProdKneading,
                meta: {
                    title: '未捏合',
                    display: 'none'
                }
            },
            {
                path: 'knead-enroll',
                component: KneadEnroll,
                meta: {
                    title: '捏合产品报名'
                }
            },
            {
                path: 'knead-enroll/detail',
                component: KneadEnrollDetail,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'knead-enroll-manage',
                component: KneadEnrollManage,
                meta: {
                    title: '捏合产品资料报名管理'
                }
            },
            {
                path: 'knead-enroll-manage/detail',
                component: KneadEnrollDetail,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'knead-enroll-manage/audit',
                component: KneadEnrollDetail,
                meta: {
                    title: '审核',
                    display: 'none'
                }
            },
            {
                path: 'publicity-set',
                component: publicitySet,
                meta: {
                    title: '企业产品公示配置'
                }
            },
            {
                path: 'enterprise-results/regulator-org/list',
                component: enterpriseResultsRegulator,
                meta: {
                    title: '企业报名结果公示管理'
                }
            },
            {
                path: 'enterprise-results/regulator-org/list/detail',
                component: enterpriseResultsDetail,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'enterprise-results/product-org/list',
                component: enterpriseResultsProduct,
                meta: {
                    title: '企业报名结果公示'
                }
            },
            {
                path: 'enterprise-results/product-org/list/detail',
                component: enterpriseResultsDetail,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'product-results/regulator-org/list',
                component: productResultsRegulator,
                meta: {
                    title: '产品报名结果公示管理'
                }
            },
            {
                path: 'product-results/regulator-org/list/detail',
                component: KneadEnrollDetail,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'product-results/product-org/list',
                component: productResultsProduct,
                meta: {
                    title: '产品报名结果公示'
                }
            },
            {
                path: 'product-results/product-org/list/detail',
                component: KneadEnrollDetail,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
