/*
 * @Author: guoxy
 * @Email: guoxy1@guahao.com
 * @Date: 2020-10-06 17:59:59
 * @Last Modified by: hejk
 * @Last Modified time: 2021-03-25 11:15:49
 * @Description: 管理端-阳光挂网
 */

const Drug = () => import(/* webpackChunkName: "modeList" */ '@/pages/hxcm/tender/sunshine-hanging/regulator-org/drug/list');
const DrugProjectConfig = () => import(/* webpackChunkName: "modeList" */ '@/pages/hxcm/tender/sunshine-hanging/regulator-org/drug/project-config');
const Consumable = () => import(/* webpackChunkName: "modeList" */ '@/pages/hxcm/tender/sunshine-hanging/regulator-org/consumable/list');
const ConProjectConfig = () => import(/* webpackChunkName: "modeList" */ '@/pages/hxcm/tender/sunshine-hanging/regulator-org/consumable/project-config');
export default [
    {
        path: 'regulator-org/drug/list',
        component: Drug,
        meta: {
            title: '阳光挂网执行'
        }
    },
    {
        path: 'regulator-org/drug/project-config',
        component: DrugProjectConfig,
        meta: {
            title: '阳光挂网配置'
        }
    },
    {
        path: 'regulator-org/consumable/list',
        component: Consumable,
        meta: {
            title: '阳光挂网执行'
        }
    },
    {
        path: 'regulator-org/consumable/project-config',
        component: ConProjectConfig,
        meta: {
            title: '阳光挂网配置'
        }
    }
];
