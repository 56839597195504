export default {
    // 接口请求域名，只有生产环境需要配置
    // baseUrl: '//hxgw.heisea.cn/',
    // baseUrl: '//yapi.med.guahao-inc.com/mock/562',
    baseUrl: '/code',
    bridgeUrl: '//bridge.med.guahao-test.com',
    // 统一文件上传地址，不区分环境
    uploadPath: '/hxTcmeSettle/web/trade/comp/file/upload',
    // 统一文件查询地址，不区分环境
    filePath: '/hxTcmeSettle/web/trade/comp/file/query',
    wedataOrigin: 'http://192.168.94.22:9000'
    // vcodeHost:'http://hsa-spp-component-service-qa1.guahao-test.com',
    // vcodePath: '/api/comp/vcode/image/gen'
};
