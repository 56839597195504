/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 10:36
 * @route
 * @description 报名管理
*/

import View from '@/components/view';
import Enterprise from './enterprise';
import Product from './product';

export default [
    {
        path: 'signup-manage',
        component: View,
        meta: {
            title: '报名管理'
        },
        children: [
            ...Enterprise,
            ...Product
        ]
    }
];
