import devConfig from './config.dev';
import preConfig from './config.pre';
import prodConfig from './config.prod';
import testConfig from './config.test';
import baseConfig from './config.base';

const env = process.env.CM_ENV || process.env.NODE_ENV;

const config = env === 'development' ? devConfig : (env === 'production' ? prodConfig : (env === 'pre' ? preConfig : testConfig));
// 服务
config.baseOss = {
    '/web/tender': `${config.baseUrl}/hxTcmeSettle`,
    '/web/trans': `${config.baseUrl}/hxTcmeSettle`,
    '/web/trade': `${config.baseUrl}/hxTcmeSettle`,
    '/web/rupu': `${config.baseUrl}/hxTcmeSettle`,
    '/web/auth': `${config.baseUrl}/hxTcmeSettle`,
    '/web/bdc': `${config.baseUrl}/hxTcmeSettle`,
    '/web/setl': `${config.baseUrl}/hxTcmeSettle`,
    '/web/mgr': `${config.baseUrl}/hxTcmeSettle`,
    '/web/config': `${config.baseUrl}/hxTcmeSettle`,
    '/web/comp': `${config.baseUrl}/hxTcmeSettle`,
    '/web/pay': `${config.baseUrl}/hxTcmeSettle`
};
export default Object.assign(baseConfig, config);
