export default {
    responseProps: {
        code: 'code', // 响应状态码
        data: 'data', // 响应数据
        message: 'message', // 响应文案信息
        successCode: 0 // 文件上传联调
    },
    responseListProps: {
        results: 'records', // 响应数组数据
        pageSize: 'size', // 页长
        pageNumber: 'current', // 页码
        totalCount: 'total', // 页面数据总量
        cursorCode: 'cursorCode' // 游标标记字段
    },
    // 文件响应数据键值
    responseFileProps: {
        fileId: 'fileId', // 文件标识字段
        fileUrl: 'fileUrl', // 文件地址
        fileName: 'originalFileName', // 文件名
        fileSuffix: 'fileExt' // 文件后缀
    },
    // 加密流程方式：false，非CA 加密流程；true，CA 加密流程
    caStas: false,
    // 临时三方协议签章
    certPath: '/cert.html',
    certOrigin: 'https://apitest.jhsec.com.cn', // 嘉恒兼容
    isSignature: false,
    // 预览
    // filePath: '/web/trade/comp/file/query',
    // 验证码
    vcodePath: '/web/comp/vcode/image/gen',
    // 文件下载方式是否为新，默认false
    downloadFlag: true,
    // type为input类型的之后，新增字符长度限制
    inputLength: 100,
    // 列表无数据时是否展示分页信息，默认为false
    emptyPagingShow: false,
    // 当前页码大于1且records无数据时自动跳上一页
    autoPrevPage: true,
    // 控制列表字段显示隐藏
    tablePaneDisplay: true,
    
    tableDownload: {
        show: true,
        pageSize: 10000,
        eachSize: 200, // 如果不写默认分页500条数据
        params: {
            isSearchCount: false
        }
    }, // 列表是否显示前端导出按钮
    ossUrl: `${window.location.protocol}//${window.location.host}`,
    baseOss: {
        '/web/tender': '/hxTcmeSettle',
        '/web/trans': '/hxTcmeSettle',
        '/web/trade': '/hxTcmeSettle',
        '/web/rupu': '/hxTcmeSettle',
        '/web/auth': '/hxTcmeSettle',
        '/web/bdc': '/hxTcmeSettle',
        '/web/setl': '/hxTcmeSettle',
        '/web/mgr': '/hxTcmeSettle',
        '/web/config': '/hxTcmeSettle',
        '/web/comp': '/hxTcmeSettle',
        '/web/pay': '/hxTcmeSettle'
    }
};
