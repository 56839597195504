// 耗材-结算账户管理-医疗/配送/管理
import View from '@/components/view';

const itemIndex = () => import(/* webpackChunkName: "itemIndex" */ '@/pages/hxcm/settle/item/drug/index');
const itemProd = () => import(/* webpackChunkName: "itemProd" */ '@/pages/hxcm/settle/item/drug/prod');
const agreedQuantity = () => import(/* webpackChunkName: "administrationList" */ '@/pages/hxcm/settle/three-tripartite/drug/agreed-quantity');

const routes = [
    {
        path: 'supervise/drug/item',
        component: View,
        meta: {
            title: '带量采购管理'
        },
        children: [
            {
                path: 'index',
                component: itemIndex,
                meta: {
                    title: '带量采购管理'
                }
            },
            {
                path: 'index/prod',
                component: itemProd,
                meta: {
                    title: '查看中选产品',
                    display: 'none'
                }
            },
            {
                path: 'index/agreed-quantity',
                component: agreedQuantity, // 管理修改约定量
                meta: {
                    title: '修改约定量',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
