import View from '@/components/view';

// 目录管理-药品-配送企业
const productionSecondaryList = () => import(/* webpackChunkName: "productionSecondaryList" */ '@/pages/hxcm/settle/distribution-directory/drug/production-secondary-list');

const routes = [
    {
        path: 'production/drug',
        component: View,
        meta: {
            title: '目录管理'
        },
        children: [
            {
                path: 'secondary-list',
                component: productionSecondaryList,
                meta: {
                    title: '议价管理'
                }
            }
        ]
    }
];

export default routes;
