import View from '@/components/view';

// 配送企业
const ListExpress = () => import('@/pages/hxcm/settle/checking-manage/consumable/list-express');
const CheckingExpress = () => import('@/pages/hxcm/settle/checking-manage/consumable/checking-express');
const DetailExpress = () => import('@/pages/hxcm/settle/checking-manage/consumable/detail-express');

// 医疗机构
const ListMedical = () => import('@/pages/hxcm/settle/checking-manage/consumable/list-medical');
const CheckingMedical = () => import('@/pages/hxcm/settle/checking-manage/consumable/checking-medical');
const DetailMedical = () => import('@/pages/hxcm/settle/checking-manage/consumable/detail-medical');

// 结算-对账管理-药品-管理单位
const ListRegulator = () => import('@/pages/hxcm/settle/checking-manage/consumable/list-regulator');
const DetailRegulator = () => import('@/pages/hxcm/settle/checking-manage/consumable/detail-regulator');
const ReadRegulator = () => import('@/pages/hxcm/settle/checking-manage/consumable/read-regulator');
const LogRegulator = () => import('@/pages/hxcm/settle/checking-manage/consumable/log-regulator');
const ResultRegulator = () => import('@/pages/hxcm/settle/checking-manage/consumable/result-regulator');
const DetailRegluator = () => import('@/pages/hxcm/settle/checking-manage/consumable/detail-regulator');

const routes = [
    {
        path: 'checking-manage/consumable',
        component: View,
        meta: {
            title: '对账管理'
        },
        children: [
            // 配送企业
            {
                path: 'express-list',
                component: ListExpress,
                meta: {
                    title: '对账确认处理'
                }
            },
            {
                path: 'express-list/checking',
                component: CheckingExpress,
                meta: {
                    title: '明细对账',
                    display: 'none'
                }
            },
            {
                path: 'express-list/detail',
                component: DetailExpress,
                meta: {
                    title: '对账单详情',
                    display: 'none'
                }
            },
            // 医疗机构
            {
                path: 'medical-list',
                component: ListMedical,
                meta: {
                    title: '对账确认处理'
                }
            },
            {
                path: 'medical-list/checking',
                component: CheckingMedical,
                meta: {
                    title: '明细对账',
                    display: 'none'
                }
            },
            {
                path: 'medical-list/detail',
                component: DetailMedical,
                meta: {
                    title: '对账单详情',
                    display: 'none'
                }
            },

            // 管理机构
            {
                path: 'regulator-list',
                component: ListRegulator,
                meta: {
                    title: '生成对账单'
                }
            },
            {
                path: 'regulator-list/read',
                component: ReadRegulator,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'regulator-list/detail',
                component: DetailRegulator,
                meta: {
                    title: '对账单详情',
                    display: 'none'
                }
            },
            {
                path: 'regulator-list/log',
                component: LogRegulator,
                meta: {
                    title: '任务日志',
                    display: 'none'
                }
            },
            {
                path: 'regulator-result',
                component: ResultRegulator,
                meta: {
                    title: '对账结果查询'
                }
            },
            {
                path: 'regulator-result/details',
                component: DetailRegluator,
                meta: {
                    title: '对账单明细',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
