import View from '@/components/view';
import Drug from './drug';
import Consumable from './consumable';
import ProjectMaintain from './pagesRoutes/project-maintain';

const routes = [
    {
        path: 'prod',
        component: View,
        meta: {
            title: '生产企业端'
        },
        children: [
            ...Drug,
            ...Consumable,
            ...ProjectMaintain
        ]
    }
];
export default routes;