import View from '@/components/view';

const routes = [
    {
        path: 'purchase-choosed-catalogue',
        component: View,
        meta: {
            title: '国家集采中选目录'
        },
        children: [
            {
                path: 'index',
                component: () => import('@/pages/hxcm/trade/common/purchase-choosed-catalogue/index'),
                meta: {
                    title: '需求查看'
                }
            }
        ]
    }
];
export default routes;