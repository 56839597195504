/*
 * @Author: qiuwt
 * @Email: qiuwt@guahao.com
 * @Date: 2019-07-23 15:56:30
 * @Last Modified by: shandan
 * @Last Modified time: 2020-04-29 10:28:44
 * @Description: 挂网状态
 */
export default [
    {
        value: '0',
        label: '取消挂网'
    }, {
        value: '1',
        label: '已挂网'
    }, {
        value: '2',
        label: '停止执行'
    }

];