import View from '@/components/view';
import TradingManage from '../pagesRoutes/trading-manage';
import { consumableQualification } from '../pagesRoutes/product-qualification';
import purchaseChoosedCatalogue from '../pagesRoutes/purchase-choosed-catalogue';
import DeliveryRelationManage from '../pagesRoutes/delivery-relation-manage';

const OrderTracking = () => import(/* webpackChunkName: "mcs-order-tracking" */ '@/pages/hxcm/trade/mgr/trading-manage/mcs-order-tracking');

const routes = [
    {
        path: 'consumable',
        component: View,
        meta: {
            title: '耗材'
        },
        children: [
            ...TradingManage,
            ...consumableQualification,
            ...purchaseChoosedCatalogue,
            ...DeliveryRelationManage,
            {
                path: 'order-tracking',
                component: OrderTracking,
                meta: {
                    title: '采购明细查看'
                }
            }
        ]   
    }
];
export default routes;
