/**
* @author xiaoyu
* @email wangxy2@guahao.com
* @date 2020-01-03
* @lastModifiedBy xiaoyu
* @lastModifiedTime 10:14
* @description 药品动态调整管理挂网状态和价格路由
*/

// 公共
const List = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/dynamic-adjust/drug/list');
const Checkout = () => import(/* webpackChunkName: "checkout" */ '@/pages/hxcm/tender/dynamic-adjust/drug/checkout');
const LaunchList = () => import(/* webpackChunkName: "launch-list" */ '@/pages/hxcm/tender/dynamic-adjust/drug/launch-list');
const Launch = () => import(/* webpackChunkName: "launch" */ '@/pages/hxcm/tender/dynamic-adjust/drug/launch');
const PublicDetails = () => import(/* webpackChunkName: "PublicDetails" */ '@/pages/hxcm/tender/dynamic-adjust/drug/public-details');

// 管理端
const AuditList = () => import(/* webpackChunkName: "audit-list" */ '@/pages/hxcm/tender/dynamic-adjust/drug/management/audit-list');
const Review = () => import(/* webpackChunkName: "review" */ '@/pages/hxcm/tender/dynamic-adjust/drug/management/review');

// 企业端
const ConfirmList = () => import(/* webpackChunkName: "confirm-list" */ '@/pages/hxcm/tender/dynamic-adjust/drug/company/confirm-list');
const Confirm = () => import(/* webpackChunkName: "confirm" */ '@/pages/hxcm/tender/dynamic-adjust/drug/company/confirm');


export default [
    // 公共
    {
        path: 'dynamic-adjust/drug/list',
        component: List,
        meta: {
            title: '挂网状态和价格查询'
        }
    },
    {
        path: 'dynamic-adjust/drug/list/checkout',
        component: Checkout,
        meta: {
            title: '查看',
            display: 'none'
        }
    },
    {
        path: 'dynamic-adjust/drug/list/checkout/details',
        component: PublicDetails,
        meta: {
            title: '详情',
            display: 'none'
        }
    },
    {
        path: 'dynamic-adjust/drug/launch-list',
        component: LaunchList,
        meta: {
            title: '发起挂网状态和价格调整'
        }
    },
    {
        path: 'dynamic-adjust/drug/launch-list/launch',
        component: Launch,
        meta: {
            title: '发起调整',
            display: 'none'
        }
    },
    // 管理端
    {
        path: 'dynamic-adjust/drug/management/audit-list',
        component: AuditList,
        meta: {
            title: '挂网状态和价格动态调整审核'
        }
    },
    {
        path: 'dynamic-adjust/drug/management/audit-list/review',
        component: Review,
        meta: {
            title: '审核',
            display: 'none'
        }
    },
    // 企业端
    {
        path: 'dynamic-adjust/drug/company/confirm-list',
        component: ConfirmList,
        meta: {
            title: '挂网状态和价格调整确认'
        }
    },
    {
        path: 'dynamic-adjust/drug/company/confirm-list/confirm',
        component: Confirm,
        meta: {
            title: '确认',
            display: 'none'
        }
    }
];
