/*
 * @Author: yanshuai
 * @Email: yanshuai@guahao.com
 * @Date: 2021-03-03 10:36:28
 * @Last Modified by: panzw2
 * @Last Modified time: 2021-03-10 15:32:22
 * @Description: 药品 - 支付模块 - 配送企业
 */
import View from '@/components/view';

// 应收汇总
const collectionSummary = () => import(/* webpackChunkName: "collection-summary" */ '@/pages/hxcm/trade/payment-manage/drug/delivery/pay-module/collection-summary');
// 管理单位结算查询
const managementSettlement = () => import(/* webpackChunkName: "management-settlement" */ '@/pages/hxcm/trade/payment-manage/drug/delivery/pay-module/management-settlement');
// 医疗机构应付汇总
const medicalSummary = () => import(/* webpackChunkName: "medical-summary" */ '@/pages/hxcm/trade/payment-manage/drug/delivery/pay-module/medical-summary');
// 交易流水查询
const transaction = () => import(/* webpackChunkName: "medical-summary" */ '@/pages/hxcm/trade/payment-manage/drug/delivery/pay-module/transaction');
// 首付款管理-提现管理
const List = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/trade/payment-manage/drug/delivery/payment-slip/list');

const Withdrawalrecord = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/trade/payment-manage/drug/delivery/pay-module/withdrawal-record');
export default [
    {
        path: 'delivery',
        component: View,
        meta: {
            title: '支付模块 - 药品'
        },
        children: [
            {
                path: 'collection-summary',
                component: collectionSummary,
                meta: {
                    title: '应收汇总'
                }
            },
            {
                path: 'management-settlement',
                component: managementSettlement,
                meta: {
                    title: '管理单位结算查询'
                }
            },
            {
                path: 'medical-summary',
                component: medicalSummary,
                meta: {
                    title: '医疗机构应付汇总'
                }
            },
            {
                path: 'transaction',
                component: transaction,
                meta: {
                    title: '交易流水查询'
                }
            },
            // 首付款管理-提现管理
            {
                path: 'payment-slip/list',
                component: List,
                meta: {
                    title: '提现管理'
                }
            },
            {
                path: 'withdrawal-record',
                component: Withdrawalrecord,
                meta: {
                    title: '医疗机构应付汇总'
                }
            }
        ]
    }
];
