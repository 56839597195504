/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-13
 * @lastModifiedBy yanghy
 * @lastModifiedTime 18:46
 * @route
 * @description 首页
*/

import Product from './product-org';
import Regulator from './regulator-org';

// 专家登录首页
const ExpertIndex = () => import(/* webpackChunkName: "expert" */ '@/pages/hxcm/tender/home-page/outside-item/expert/index');

export default [
    {
        path: 'outside-item/expert/index',
        component: ExpertIndex,
        meta: {
            title: '专家首页'
        }
    },
    ...Product,
    ...Regulator
];
