/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2021-05-22 10:26:28
 * @Last Modified by: xujh
 * @Last Modified time: 2021-05-31 09:42:52
 * @Description: 采购单管理
 * @Route: /trade/prod/trading-manage/drug/manage
 */
<template>
    <bk-list 
        ref="table"
        :search-config="searchConfig"
        :button-config="buttonConfig"
        :table-config="tableConfig" />
</template>

<script>
import config from '@/mixins/field';

export default {
    data() {
        const { path } = this.$route;
        const isDrug = /\/drug\//.test(path);
        const search = config.getFormInfo(['品名', '剂型', '包装', '规格', {
            name: 'sendTime',
            type: 'daterange',
            label: '采购时间',
            extraFields: ['startSendTime', 'endSendTime'],
            grid: 3
        }
        ]);
        const table = config.getTableInfo([
            {
                name: '订单编号',
                scene: 'skip',
                http: {
                    url: `/cm-settle/trade/prod/${isDrug ? 'drug' : 'consumable'}/trading-manage/manage/order-detail`,
                    toString: true,
                    targetKeys: ['ordId']
                }
            }, '品名', '剂型', '规格', '包装', '项目名称', '采购金额', '发货金额', '发货率', '收货金额', '收货率'
        ]);
        return {
            searchConfig: {
                layout: 'flow',
                draw: true,
                clear: true,
                results: {
                    startSendTime: '',
                    endSendTime: '',
                    sendTime: []
                },
                data: [
                    {
                        data: search
                    }
                ]
            },
            buttonConfig: {
                data: [
                    {
                        name: 'export',
                        label: '导出',
                        scene: 'export',
                        visible: true,
                        selection: false,
                        http: {
                            url: '/web/trans/ship/export',
                            method: 'post',
                            onSuccess: res => {
                                if (res.data) {
                                    window.open(res.data);
                                }
                                return false;
                            }
                        }
                    }
                ]
            },
            tableConfig: {
                autoHeight: true,
                http: {
                    method: 'post',
                    url: '/web/trans/ship/query_page',
                    params: {
                        shpStas: 9
                    }
                },
                selection: false,
                data: table
            }
        };
    },
    created() {
        this.getCurrent();
    },
    methods: {
        getCurrent() {
            const date = new Date();
            const year = date.getFullYear();
            const months = date.getMonth() + 1;
            const day = date.getDate();
            // console.log(this.$refs.table)
            const first = `${year}-${months - 1}-${day} 00:00:00`;
            const second = `${year}-${months}-${day} 23:59:59`;
            this.searchConfig.results.startSendTime = first;   
            this.searchConfig.results.endSendTime = second;
            this.searchConfig.results.sendTime = [first, second];
        }
    }
};
</script>
