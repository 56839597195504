
/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-13
 * @lastModifiedBy yanghy
 * @lastModifiedTime 18:06
 * @route
 * @description 申投诉处理
*/

import View from '@/components/view';
import StatementManage from './statement-manage';
import StatementMaintain from './statement-maintain';

export default [
    {
        path: 'statement-handle',
        component: View,
        meta: {
            title: '申投诉管理'
        },
        children: [
            ...StatementManage,
            ...StatementMaintain
        ]
    }
];
