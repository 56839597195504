import View from '@/components/view';

// 医疗机构-带量采购统计
const List = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/settle/purchase/consumable/list');
const Details = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/settle/purchase/consumable/details');
const PurchasingData = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/settle/purchase/consumable/purchasing-data');

const routes = [
    {
        path: 'purchase/consumable',
        component: View,
        meta: {
            title: '带量采购统计'
        },
        children: [
            {
                path: 'list',
                component: List,
                meta: {
                    title: '带量采购统计'
                }
            },
            {
                path: 'list/details',
                component: Details,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'purchasing-data',
                component: PurchasingData,
                meta: {
                    title: '采购数据统计'
                }
            }
        ]
    }
];

export default routes;
