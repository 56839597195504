/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 11:38
 * @route
 * @description 竞价管理/竞价结果查询
 */

const RConsumableBidding = () => import(/* webpackChunkName: "p-bidding" */ '@/pages/hxcm/tender/bidding-manage/round-manage/regulator-org/consumable/bidding');
const RConsumablePublicity = () => import(/* webpackChunkName: "p-publicity" */ '@/pages/hxcm/tender/bidding-manage/round-manage/regulator-org/consumable/publicity');
const RConsumableChosen = () => import(/* webpackChunkName: "p-chosen" */ '@/pages/hxcm/tender/bidding-manage/round-manage/regulator-org/consumable/chosen');
const PConsumableBidding = () => import(/* webpackChunkName: "r-bidding" */ '@/pages/hxcm/tender/bidding-manage/round-manage/product-org/consumable/bidding');
const PConsumablePublicity = () => import(/* webpackChunkName: "r-publicity" */ '@/pages/hxcm/tender/bidding-manage/round-manage/product-org/consumable/publicity');
const PConsumableChosenLog = () => import(/* webpackChunkName: "PConsumableChosenLog" */ '@/pages/hxcm/tender/bidding-manage/round-manage/regulator-org/consumable/chosen-log');


const BidRoundRegList = () => import(/* webpackChunkName: "bid-round-reg-list" */ '@/pages/hxcm/tender/bidding-manage/round-manage/regulator-org/list');
const BidRoundProList = () => import(/* webpackChunkName: "bid-round-pro-list" */ '@/pages/hxcm/tender/bidding-manage/round-manage/product-org/index');
const RDrugEntry = () => import(/* webpackChunkName: "pd-entry" */ '@/pages/hxcm/tender/bidding-manage/round-manage/regulator-org/entry-manage');

const RDrugBidding = () => import(/* webpackChunkName: "pd-list" */ '@/pages/hxcm/tender/bidding-manage/round-manage/regulator-org/drug/bidding');
const RDrugPublicity = () => import(/* webpackChunkName: "pd-list" */ '@/pages/hxcm/tender/bidding-manage/round-manage/regulator-org/drug/publicity');
const RDrugChosen = () => import(/* webpackChunkName: "pd-chosen" */ '@/pages/hxcm/tender/bidding-manage/round-manage/regulator-org/drug/chosen');
const RDrugPrice = () => import(/* webpackChunkName: "pd-price" */ '@/pages/hxcm/tender/bidding-manage/round-manage/regulator-org/drug/differential-price-calculat');
const UnifiedDecryption = () => import(/* webpackChunkName: "unified-decryption" */ '@/pages/hxcm/tender/bidding-manage/components/unified-decryption');
const UnifiedDecryption2 = () => import(/* webpackChunkName: "unified-decryption" */ '@/pages/hxcm/tender/bidding-manage/components/unified-decryption2');
const PDrugBidding = () => import(/* webpackChunkName: "rd-list" */ '@/pages/hxcm/tender/bidding-manage/round-manage/product-org/drug/bidding');
const PDrugPublicity = () => import(/* webpackChunkName: "rd-list" */ '@/pages/hxcm/tender/bidding-manage/round-manage/product-org/drug/publicity');
const drugDiffPricLog = () => import(/* webpackChunkName: "drugDiffPricLog" */ '@/pages/hxcm/tender/bidding-manage/round-manage/regulator-org/drug/diffprice-log');
const drugChosenLog = () => import(/* webpackChunkName: "drugChosenLog" */ '@/pages/hxcm/tender/bidding-manage/round-manage/regulator-org/drug/choosen-log');
export default [
    {
        path: 'round-manage/regulator-org/consumable/list/entry-manage/bidding',
        component: RConsumableBidding,
        meta: {
            title: '进入竞价管理',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/consumable/list/entry-manage/publicity',
        component: RConsumablePublicity,
        meta: {
            title: '进入公示管理',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/consumable/list/entry-manage/chosen',
        component: RConsumableChosen,
        meta: {
            title: '进入入选管理',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/consumable/list/entry-manage/chosen/log',
        component: PConsumableChosenLog,
        meta: {
            title: '查看日志',
            display: 'none'
        }

    },
    {
        path: 'round-manage/regulator-org/list',
        component: BidRoundRegList,
        meta: {
            title: '竞价轮次管理'
            // display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/consumable/list',
        component: BidRoundRegList,
        meta: {
            title: '竞价轮次管理'
            // display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/list/entry-manage/bidding',
        component: RDrugBidding,
        meta: {
            title: '进入竞价管理',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/list/entry-manage/publicity',
        component: RDrugPublicity,
        meta: {
            title: '进入公示管理',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/list/entry-manage',
        component: RDrugEntry,
        meta: {
            title: '进入管理',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/consumable/list/entry-manage',
        component: RDrugEntry,
        meta: {
            title: '进入管理',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/list/entry-manage/chosen',
        component: RDrugChosen,
        meta: {
            title: '进入入选管理',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/list/entry-manage/chosen/log',
        component: drugChosenLog,
        meta: {
            title: '调整日志',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/list/entry-manage/differential-price-calculat',
        component: RDrugPrice,
        meta: {
            title: '进入差比价计算',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/list/entry-manage/differential-price-calculat/log',
        component: drugDiffPricLog,
        meta: {
            title: '调整日志',
            display: 'none'
        }
    },
    {
        path: 'round-manage/product-org/consumable/list/entry-manage/bidding',
        component: PConsumableBidding,
        meta: {
            title: '进入竞价管理',
            display: 'none'
        }
    },
    {
        path: 'round-manage/product-org/consumable/list/publicity',
        component: PConsumablePublicity,
        meta: {
            title: '进入公示管理',
            display: 'none'
        }
    },
    {
        path: 'round-manage/product-org/list',
        component: BidRoundProList,
        meta: {
            title: '竞价轮次管理'
            // display: 'none'
        }
    },
    {
        path: 'round-manage/product-org/list/bidding',
        component: PDrugBidding,
        meta: {
            title: '进入竞价管理',
            display: 'none'
        }
    },
    {
        path: 'round-manage/product-org/list/publicity',
        component: PDrugPublicity,
        meta: {
            title: '进入公示管理',
            display: 'none'
        }
    },
    {
        path: 'round-manage/components/unified-decryption',
        component: UnifiedDecryption,
        meta: {
            title: '统一解密',
            display: 'none'
        }
    },
    {
        path: 'round-manage/components/unified-decryption2',
        component: UnifiedDecryption2,
        meta: {
            title: '统一解密',
            display: 'none'
        }
    }
];
