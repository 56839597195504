import View from '@/components/view';

const Configuration = () => import('@/pages/hxcm/trade/mgr/checking-configuration/index');
const Holidays = () => import('@/pages/hxcm/trade/mgr/checking-configuration/holidays-configuration');
const routes = [
    {
        path: 'checking-configuration',
        component: View,
        meta: {
            title: '结算方案配置'
        },
        children: [
            {
                path: 'configuration',
                component: Configuration,
                meta: {
                    title: '代付对账配置'
                }
            },
            {
                path: 'holidays',
                component: Holidays,
                meta: {
                    title: '节假日配置'
                }
            }
        ] 
    }
];
export default routes;