/*
 * @Author: yanshuai
 * @Email: yanshuai@guahao.com
 * @Date: 2021-03-03 10:36:28
 * @Description: 支付模块 - 配送企业
 */
import View from '@/components/view';

// 应收汇总
const collectionSummary = () => import(/* webpackChunkName: "collection-summary" */ '@/pages/hxcm/pay/delivery/pay-module/collection-summary');
// 管理单位结算查询
const managementSettlement = () => import(/* webpackChunkName: "management-settlement" */ '@/pages/hxcm/pay/delivery/pay-module/management-settlement');
// 医疗机构应付汇总
const medicalSummary = () => import(/* webpackChunkName: "medical-summary" */ '@/pages/hxcm/pay/delivery/pay-module/medical-summary');
// 交易流水查询
const transaction = () => import(/* webpackChunkName: "medical-summary" */ '@/pages/hxcm/pay/delivery/pay-module/transaction');
// 首付款管理-提现管理
const List = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/pay/delivery/payment-slip/list');

const WithdrawalRecord = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/pay/delivery/pay-module/withdrawal-record');
export default [
    {
        path: 'delivery',
        component: View,
        meta: {
            title: '支付模块'
        },
        children: [
            {
                path: 'collection-summary',
                component: collectionSummary,
                meta: {
                    title: '应收汇总'
                }
            },
            {
                path: 'management-settlement',
                component: managementSettlement,
                meta: {
                    title: '管理单位结算查询'
                }
            },
            {
                path: 'medical-summary',
                component: medicalSummary,
                meta: {
                    title: '医疗机构应付汇总',
                    keepAlive: false
                }
            },
            // 医疗机构应付汇总 发票列表
            {
                path: 'medical-summary/checking-list',
                component: () => import('@/pages/hxcm/settle/common/account-checking/delivery-checking'),
                meta: {
                    title: '发票列表',
                    display: 'none'
                }
            },
            // 医疗机构应付汇总 发票详情
            {
                path: 'medical-summary/checking-list/detail',
                component: () => import('@/pages/hxcm/settle/common/settlement-apply/delivery-add'),
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'transaction',
                component: transaction,
                meta: {
                    title: '交易流水查询'
                }
            },
            // 首付款管理-提现管理
            {
                path: 'payment-slip/list',
                component: List,
                meta: {
                    title: '提现管理'
                }
            },
            {
                path: 'withdrawal-record',
                component: WithdrawalRecord,
                meta: {
                    title: '提现记录查询'
                }
            },
            // 结算账户管理
            {
                path: 'account-management',
                component: () => import('@/pages/hxcm/pay/delivery/account-management'),
                meta: {
                    title: '结算账户管理'
                }
            },
            {
                path: 'account-management/add',
                component: () => import('@/pages/hxcm/pay/delivery/account-management/add'),
                meta: {
                    title: '添加',
                    display: 'none'
                }
            },
            {
                path: 'account-management/read',
                component: () => import('@/pages/hxcm/pay/delivery/account-management/add'),
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'account-management/edit',
                component: () => import('@/pages/hxcm/pay/delivery/account-management/add'),
                meta: {
                    title: '编辑',
                    display: 'none'
                }
            }
        ]
    }
];
