
/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 11:38
 * @route
 * @description 发布结果查询/谈判发布结果
 */

const RegNegotiate = () => import(/* webpackChunkName: "ed-list" */ '@/pages/hxcm/tender/publish-result/negotiate/regulator-org/list');
const ProdNegotiate = () => import(/* webpackChunkName: "ed-list" */ '@/pages/hxcm/tender/publish-result/negotiate/product-org/list');
export default [
    {
        path: 'negotiate/regulator-org/list',
        component: RegNegotiate,
        meta: {
            title: '谈判发布管理'
            // display: 'none'
        }
    },
    {
        path: 'negotiate/product-org/list',
        component: ProdNegotiate,
        meta: {
            title: '谈判发布结果'
            // display: 'none'
        }
    }
];
