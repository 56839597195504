/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2021-05-26 11:39:57
 * @Last Modified by: xujh
 * @Last Modified time: 2021-05-28 10:00:00
 * @Description: 中选产品查询
 * @Route: Route
 */
<template>
    <bk-list 
        ref="table"
        :table-config="tableConfig" />
</template>

<script>
import config from '@/mixins/field';

export default {
    data() {
        // const { path } = this.$route;
        // const isDrug = /\/drug\//.test(path);
        const table = config.getTableInfo([
            {
                name: '品名',
                setName: 'genname'
            },
            {
                name: '剂型',
                setName: 'dosformName'
            },
            {
                name: '规格',
                setName: 'specName'
            }, 
            {
                name: '包装',
                setName: 'pac'
            },
            {
                name: '项目名称',
                setName: 'tenditmName'
            },
            '挂网价(元)', '挂网时间'
        ]);
        return {
            tableConfig: {
                autoHeight: true,
                http: {
                    method: 'post',
                    url: '/web/trans/drug/pubonln/prodentp/page'
                },
                selection: false,
                data: table
            }
        };
    }
};
</script>
