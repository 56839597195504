import View from '@/components/view';

const routes = [
    {
        path: 'contract-signing',
        component: View,
        meta: {
            title: '国家集采合同签订'
        },
        children: [
            {
                path: 'index',
                component: () => import('@/pages/hxcm/settle/mgr/contract-signing'),
                meta: {
                    title: '国家集采合同签订'
                }
            },
            {
                path: 'index/look',
                component: () => import('@/pages/hxcm/settle/mgr/contract-signing/look'),
                meta: {
                    title: '查看',
                    display: 'none'
                }
            }
        ]
    }
];
export default routes;
