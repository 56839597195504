import { render, staticRenderFns } from "./manage.vue?vue&type=template&id=666c2582&scoped=true"
import script from "./manage.vue?vue&type=script&lang=js"
export * from "./manage.vue?vue&type=script&lang=js"
import style0 from "./manage.vue?vue&type=style&index=0&id=666c2582&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "666c2582",
  null
  
)

export default component.exports