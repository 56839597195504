/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 14:05
 * @route
 * @description 谈判管理/轮次管理
*/
// 药品/耗材企业端
const PDrugRoundProList = () => import(/* webpackChunkName: "drug-round-list" */ '@/pages/hxcm/tender/negotiate-manage/round-manage/product-org/index');

const PConsumableOfferList = () => import(/* webpackChunkName: "consumable-offer-list" */ '@/pages/hxcm/tender/negotiate-manage/round-manage/product-org/consumable/offer-list');

const PDrugOfferList = () => import(/* webpackChunkName: "drug-offer-list" */ '@/pages/hxcm/tender/negotiate-manage/round-manage/product-org/drug/offer-list');

const PConsumableOfferPublicity = () => import(/* webpackChunkName: "consumabel-offer-publicity" */ '@/pages/hxcm/tender/negotiate-manage/round-manage/product-org/consumable/offer-publicity');

const PDrugOfferPublicity = () => import(/* webpackChunkName: "drug-offer-pubicity" */ '@/pages/hxcm/tender/negotiate-manage/round-manage/product-org/drug/offer-publicity');

const RDrugRoundRegList = () => import(/* webpackChunkName: "drrug-round-reg-list" */ '@/pages/hxcm/tender/negotiate-manage/round-manage/regulator-org/index');
// 公共详情页
const RDrugNegotiateDetail = () => import(/* webpackChunkName: "rdrug-ndetail" */ '@/pages/hxcm/tender/negotiate-manage/round-manage/regulator-org/negotiate-detail');

const RDrugNegotiateList = () => import(/* webpackChunkName: "rdrug-ndetail" */ '@/pages/hxcm/tender/negotiate-manage/round-manage/regulator-org/drug/negotiate-list');
const RDrugPublicList = () => import(/* webpackChunkName: "rdrug-ndetail" */ '@/pages/hxcm/tender/negotiate-manage/round-manage/regulator-org/drug/public-list');
const RConsumableNegotiateList = () => import(/* webpackChunkName: "rdrug-ndetail" */ '@/pages/hxcm/tender/negotiate-manage/round-manage/regulator-org/consumable/negotiate-list');
const RConsumablePublicList = () => import(/* webpackChunkName: "rdrug-ndetail" */ '@/pages/hxcm/tender/negotiate-manage/round-manage/regulator-org/consumable/public-list');
// 药品
const drugLog = () => import(/* webpackChunkName: "drugLog" */ '@/pages/hxcm/tender/negotiate-manage/round-manage/product-org/drug/negotiation-log');
// 耗材
const consumableLog = () => import(/* webpackChunkName: "consumableLog" */ '@/pages/hxcm/tender/negotiate-manage/round-manage/product-org/consumable/negotiation-log');
// 12月18号new
const managerdrugLog = () => import(/* webpackChunkName: "drugLog" */ '@/pages/hxcm/tender/negotiate-manage/round-manage/regulator-org/drug/log');
const drugDetail = () => import(/* webpackChunkName: "drugDetail" */ '@/pages/hxcm/tender/negotiate-manage/round-manage/regulator-org/drug/product-detail');
const priceInfo = () => import(/* webpackChunkName: "priceInfo" */ '@/pages/hxcm/tender/negotiate-manage/round-manage/regulator-org/drug/price-info');
const mcsProductDetails = () => import(/* webpackChunkName: "mcsProductDetails" */ '@/pages/hxcm/tender/negotiate-manage/round-manage/regulator-org/consumable/mcs-product-details');

export default [
    {
        path: 'round-manage/regulator-org/list/negotiate-detail/negotiate/product',
        component: drugDetail,
        meta: {
            title: '产品详情',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/list/negotiate-detail/negotiate/price',
        component: priceInfo,
        meta: {
            title: '价格信息',
            display: 'none'
        }
    },
    {
        path: 'round-manage/product-org/list',
        component: PDrugRoundProList,
        meta: {
            title: '谈判轮次管理'
            // display: 'none'
        }
    },
    {
        path: 'round-manage/product-org/drug/log',
        component: drugLog,
        meta: {
            title: '谈判日志',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/list/negotiate-detail/negotiate/log',
        component: managerdrugLog,
        meta: {
            title: '谈判日志',
            display: 'none'
        }
    },
    {
        path: 'round-manage/product-org/consumable/log',
        component: consumableLog,
        meta: {
            title: '谈判日志',
            display: 'none'
        }
    },
    {
        path: 'round-manage/product-org/consumable/list/offer',
        component: PConsumableOfferList,
        meta: {
            title: '谈判报价',
            display: 'none'
        }
    },
    {
        path: 'round-manage/product-org/list/offer',
        component: PDrugOfferList,
        meta: {
            title: '谈判报价',
            display: 'none'
        }
    },
    {
        path: 'round-manage/product-org/consumable/list/offer/publicity',
        component: PConsumableOfferPublicity,
        meta: {
            title: '公示管理',
            display: 'none'
        }
    },
    {
        path: 'round-manage/product-org/drug/list/publicity',
        component: PDrugOfferPublicity,
        meta: {
            title: '公示管理',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/list',
        component: RDrugRoundRegList,
        meta: {
            title: '谈判轮次管理'
            // display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/list/negotiate-detail',
        component: RDrugNegotiateDetail,
        meta: {
            title: '谈判轮次详情',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/list/negotiate-detail/negotiate',
        component: RDrugNegotiateList,
        meta: {
            title: '谈判管理',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/list/negotiate-detail/public',
        component: RDrugPublicList,
        meta: {
            title: '公示管理',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/consumable/list/negotiate-detail/negotiate',
        component: RConsumableNegotiateList,
        meta: {
            title: '谈判管理',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/consumable/list/negotiate-detail/negotiate/product',
        component: mcsProductDetails,
        meta: {
            title: '产品详情',
            display: 'none'
        }
    },
    {
        path: 'round-manage/regulator-org/consumable/list/negotiate-detail/public',
        component: RConsumablePublicList,
        meta: {
            title: '公示管理',
            display: 'none'
        }
    }
];
