const host = '//api.bqex.com';
export default {
    baseUrl: `${host}/code`, // 运维中心数据服务
    // wedataOrigin: 'http://192.168.94.22:9000',
    // 上传
    uploadPath: `${host}/code/hxTcmeSettle/web/trade/comp/file/upload`, // 上传
    filePath: `${host}/code/hxTcmeSettle/web/trade/comp/file/query`, // 文件信息
    batchFilePath: `${host}/code/base/fe/batchInfo` // 批量获取文件信息
    // downloadPath: '/code/base/fe/download'
};
