// 短缺耗材信息填报-药品-配送企业
const shortageOfSupplies = () => import(/* webpackChunkName: "shortageOfSupplies" */ '@/pages/hxcm/settle/shortage-distribution/consumable/shortage-of-supplies');
const shortageOfSuppliesAdd = () => import(/* webpackChunkName: "shortageOfSuppliesAdd" */ '@/pages/hxcm/settle/shortage-distribution/consumable/shortage-of-supplies-add');

const routes = [
    {
        path: 'shortage-distribution/consumable/shortage-of-supplies/list',
        component: shortageOfSupplies,
        meta: {
            title: '短缺耗材信息填报'
        }
    },
    {
        path: 'shortage-distribution/consumable/shortage-of-supplies/list/add',
        component: shortageOfSuppliesAdd,
        meta: {
            title: '新增',
            display: 'none'
        }
    }
];

export default routes;
