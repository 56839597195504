// 挂网目录查询-药品-管理机构
import View from '@/components/view';

const hangingManagement = () => import(/* webpackChunkName: "recordPurchase" */ '@/pages/hxcm/settle/hanging-management/drug/hanging-management');
const Log = () => import(/* webpackChunkName: "d-log" */ '@/pages/hxcm/settle/hanging-management/drug/log');
const hangingManagementListRead = () => import(/* webpackChunkName: "hangingManagementListRead" */ '@/pages/hxcm/settle/catalog-management/drug/detail');

const routes = [
    {
        component: View,
        path: 'hanging-management',
        meta: {
            title: '交易管理'
        },
        children: [
            {
                path: 'drug/hanging-management',
                component: hangingManagement,
                meta: {
                    title: '挂网目录查询'
                }
            },
            {
                path: 'drug/hanging-management/read',
                component: hangingManagementListRead,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            },
            {
                path: 'drug/hanging-management/log',
                component: Log,
                meta: {
                    title: '日志',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
