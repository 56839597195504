/*
 * @Author: xiaoyu
 * @Email: wangxy2@guahao.com
 * @Date: 2020/1/10
 * @Last Modified by: hejk
 * @Last Modified time: 2021-03-08 15:47:43
 * @Description: 国家项目执行管理路由
 */

import View from '@/components/view';

const List = () => import(/* webpackChunkName: "List" */ '@/pages/hxcm/tender/national-project-execution-management/list');
const Config = () => import(/* webpackChunkName: "config" */ '@/pages/hxcm/tender/national-project-execution-management/configuration/index');

// 药品
const DrugCheckout = () => import(/* webpackChunkName: "DrugCheckout" */ '@/pages/hxcm/tender/national-project-execution-management/drug/checkout');
// 耗材
const ConsumableCheckout = () => import(/* webpackChunkName: "ConsumableCheckout" */ '@/pages/hxcm/tender/national-project-execution-management/consumable/checkout');


export default [
    {
        path: 'national-project/execution',
        component: View,
        meta: {
            title: '国家项目执行管理'
        },
        children: [
            {
                path: 'list',
                component: List,
                meta: {
                    title: '国家项目执行管理'
                }
            },
            {
                path: 'project-config',
                component: Config,
                meta: {
                    title: '配置'
                }
            },
            // 药品
            {
                path: 'list/drug/checkout',
                component: DrugCheckout,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            // 耗材
            {
                path: 'list/consumable/checkout',
                component: ConsumableCheckout,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            }
        ]
    }
];
