/*
 * @Author: caoyp
 * @Email: caoyp@guahao.com
 * @Date: 2021-05-31 16:52:41
 * @Last Modified by: yanchunl
 * @Last Modified time: 2021-07-28 09:59:43
 * @Description: 企业端 - 产品转产审核
 * @Route: Route
 */

const ProductConversionOrgList = () => import(/* webpackChunkName: "product-conversion-org-list" */ '@/pages/hxcm/tender/product-conversion/product-org/list');
const ProductConversionAuditAdd = () => import(/* webpackChunkName: "product-conversion-audit-add" */ '@/pages/hxcm/tender/product-conversion/product-org/add');
const ProductAuditDetail = () => import(/* webpackChunkName: "product-audit-detail" */ '@/pages/hxcm/tender/product-conversion/product-org/detail');
const ProductAuditEdit = () => import(/* webpackChunkName: "product-audit-detail" */ '@/pages/hxcm/tender/product-conversion/product-org/edit');
export default [
    {
        path: 'product-org/list',
        component: ProductConversionOrgList,
        meta: {
            title: '产品转产申请'
            // display: 'none'
        }
    },
    {
        path: 'product-org/list/add',
        component: ProductConversionAuditAdd,
        meta: {
            title: '新增转产',
            display: 'none'
        }
    },
    {
        path: 'product-org/list/detail',
        component: ProductAuditDetail,
        meta: {
            title: '查看详情',
            display: 'none'
        }
    },
    {
        path: 'product-org/list/edit',
        component: ProductAuditEdit,
        meta: {
            title: '编辑转产',
            display: 'none'
        }
    }
];
