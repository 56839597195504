/*
 * @Author: yanshuai
 * @Email: yanshuai@guahao.com
 * @Date: 2021-03-03 10:36:28
 * @Last Modified by: Stonererer
 * @Last Modified time: 2022-04-07 16:41:56
 * @Description: 支付模块 - 医疗机构
 */
import View from '@/components/view';

// 应付汇总
const paymentSummary = () => import(/* webpackChunkName: "payment-summary" */ '@/pages/hxcm/pay/medical/pay-module/payment-summary');
// 配送企业应收汇总
const distributionSummary = () => import(/* webpackChunkName: "distribution-summary" */ '@/pages/hxcm/pay/medical/pay-module/distribution-summary');
// 医疗机构应付汇总
const medicalSummary = () => import(/* webpackChunkName: "medical-summary" */ '@/pages/hxcm/pay/medical/pay-module/medical-summary');

const transaction = () => import(/* webpackChunkName: "medical-summary" */ '@/pages/hxcm/pay/medical/pay-module/transaction');
// 收款付款管理
const List = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/pay/medical/collection/list');
const PayDetail = () => import(/* webpackChunkName: "createPay" */ '@/pages/hxcm/pay/medical/collection/detail');

// 支付管理
const paymentList = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/pay/medical/payment/list');
const particulars = () => import(/* webpackChunkName: "particulars" */ '@/pages/hxcm/pay/medical/payment/particulars');
const log = () => import(/* webpackChunkName: "log" */ '@/pages/hxcm/pay/medical/payment/payment-log');
export default [
    {
        path: 'medical',
        component: View,
        meta: {
            title: '支付模块'
        },
        children: [
            {
                path: 'payment-summary',
                component: paymentSummary,
                meta: {
                    title: '应付汇总'
                }
            },
            {
                path: 'distribution-summary',
                component: distributionSummary,
                meta: {
                    title: '配送企业应收汇总'
                }
            },
            {
                path: 'medical-summary',
                component: medicalSummary,
                meta: {
                    title: '医疗机构应付汇总'
                }
            },
            {
                path: 'transaction',
                component: transaction,
                meta: {
                    title: '交易流水查询'
                }
            },
            {
                path: 'collection/list',
                component: List,
                meta: {
                    title: '支付单审核'
                }
            },
            {
                path: 'collection/list/detail',
                component: PayDetail,
                meta: {
                    title: '支付单明细',
                    display: 'none'
                }

            },
            {
                path: 'payment/list',
                component: paymentList,
                meta: {
                    title: '支付管理'
                }
            },
            {
                path: 'payment/list/particulars',
                component: particulars,
                meta: {
                    title: '支付单明细',
                    display: 'none'
                }
            },
            {
                path: 'payment/list/log',
                component: log,
                meta: {
                    title: '日志',
                    display: 'none'
                }
            },
            // 结算账户管理
            {
                path: 'account-management',
                component: () => import('@/pages/hxcm/pay/medical/account-management'),
                meta: {
                    title: '结算账户管理'
                }
            },
            {
                path: 'account-management/add',
                component: () => import('@/pages/hxcm/pay/medical/account-management/add'),
                meta: {
                    title: '添加',
                    display: 'none'
                }
            },
            {
                path: 'account-management/read',
                component: () => import('@/pages/hxcm/pay/medical/account-management/add'),
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'account-management/edit',
                component: () => import('@/pages/hxcm/pay/medical/account-management/add'),
                meta: {
                    title: '编辑',
                    display: 'none'
                }
            }
        ]
    }
];
