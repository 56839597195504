/*
 * @Author: yanshuai
 * @Email: yanshuai@guahao.com
 * @Date: 2021-03-03 10:36:28
 * @Last Modified by: hejk
 * @Last Modified time: 2021-04-15 17:50:49
 * @Description: 耗材 - 支付模块 - 配送企业
 */
import View from '@/components/view';

// 应收汇总
const collectionSummary = () => import(/* webpackChunkName: "collection-summary" */ '@/pages/hxcm/trade/payment-manage/consumables/delivery/pay-module/collection-summary');
// 管理单位结算查询
const managementSettlement = () => import(/* webpackChunkName: "management-settlement" */ '@/pages/hxcm/trade/payment-manage/consumables/delivery/pay-module/management-settlement');
// 医疗机构应付汇总
const medicalSummary = () => import(/* webpackChunkName: "medical-summary" */ '@/pages/hxcm/trade/payment-manage/consumables/delivery/pay-module/medical-summary');
// 提现记录查询
const transAction = () => import(/* webpackChunkName: "medical-summary" */ '@/pages/hxcm/trade/payment-manage/consumables/delivery/pay-module/transaction');
// 提现记录查询
const withdrawalRecord = () => import(/* webpackChunkName: "medical-summary" */ '@/pages/hxcm/trade/payment-manage/consumables/delivery/pay-module/withdrawal-record');
// 首付款管理-提现管理
const List = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/trade/payment-manage/consumables/delivery/payment-slip/list');

export default [
    {
        path: 'delivery',
        component: View,
        meta: {
            title: '支付模块 - 耗材'
        },
        children: [
            {
                path: 'collection-summary',
                component: collectionSummary,
                meta: {
                    title: '应收汇总'
                }
            },
            {
                path: 'management-settlement',
                component: managementSettlement,
                meta: {
                    title: '管理单位结算查询'
                }
            },
            {
                path: 'medical-summary',
                component: medicalSummary,
                meta: {
                    title: '医疗机构应付汇总'
                }
            },
            {
                path: 'transaction',
                component: transAction,
                meta: {
                    title: '交易流水查询'
                }
            },
            {
                path: 'withdrawal-record',
                component: withdrawalRecord,
                meta: {
                    title: '提现记录查询'
                }
            },
            // 首付款管理-提现管理
            {
                path: 'payment-slip/list',
                component: List,
                meta: {
                    title: '提现管理'
                }
            }
        ]
    }
];
