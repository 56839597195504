import View from '@/components/view';

// 医疗机构-带量采购统计
const List = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/settle/purchase/drug/list');
const Details = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/settle/purchase/drug/details');

const routes = [
    {
        path: 'purchase/drug',
        component: View,
        meta: {
            title: '带量采购统计'
        },
        children: [
            {
                path: 'list',
                component: List,
                meta: {
                    title: '带量采购统计'
                }
            },
            {
                path: 'list/details',
                component: Details,
                meta: {
                    title: '查看详情',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
