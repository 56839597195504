/*
 * @Author: yanshuai
 * @Email: yanshuai@guahao.com
 * @Date: 2021-03-03 11:15:31
 * @Last Modified by: Stoner
 * @Last Modified time: 2022-04-08 10:33:31
 * @Description: 支付模块
 */
// import View from '@/components/view';
import View from '@/pages/index';
import Delivery from './delivery';
import ManagementUnit from './management-unit';
import Medical from './medical';

export default [
    {
        path: 'pay',
        component: View,
        meta: {
            title: '支付模块'
        },
        children: [
            ...Delivery,
            ...ManagementUnit,
            ...Medical
        ]
    }
];
