/*
 * @Author: xiaoyu
 * @Email: wangxy2@guahao.com
 * @Date: 2019-12-04
 * @Last Modified by: xiaoyu
 * @Last Modified time: 2020-01-03 10:01:37 10:01:37
 * @Description: 我的企业信息
 */

// 药品
const businessDrug = () => import(/* webpackChunkName: "businessDrug" */ '@/pages/hxcm/tender/my-business-info/drug/my-business-info');
// 不复审
const businessDrugPlus = () => import(/* webpackChunkName: "businessDrug" */ '@/pages/hxcm/tender/my-business-info/drug/my-business-info-plus');
const drugHistory = () => import(/* webpackChunkName: "drugHistory" */ '@/pages/hxcm/tender/my-business-info/drug/history-details');
// 耗材
const businessConsumable = () => import(/* webpackChunkName: "businessConsumable" */ '@/pages/hxcm/tender/my-business-info/consumable/business');
const consumableHistory = () => import(/* webpackChunkName: "consumableHistory" */ '@/pages/hxcm/tender/my-business-info/consumable/history');
// 不复审
const noRecheckPlus = () => import(/* webpackChunkName: "noRecheckPush" */ '@/pages/hxcm/tender/my-business-info/consumable/no-recheck-plus');
export default [
    // 药品
    {
        path: 'my-business-info/drug/business',
        component: businessDrug,
        meta: {
            title: '我的企业信息'
            // display: 'none'
        }
    },
    {
        path: 'my-business-info/drug/enterprise-data',
        component: businessDrugPlus,
        meta: {
            title: '我的企业资料'
            // display: 'none'
        }
    },
    {
        path: 'my-business-info/drug/my-business-info/history-details',
        component: drugHistory,
        meta: {
            title: '查看历史',
            display: 'none'
        }
    },
    // 耗材
    {
        path: 'my-business-info/consumable/business',
        component: businessConsumable,
        meta: {
            title: '我的企业信息'
            // display: 'none'
        }
    },
    {
        path: 'my-business-info/consumable/business/history',
        component: consumableHistory,
        meta: {
            title: '查看历史',
            display: 'none'
        }
    },
    {
        path: 'my-business-info/consumable/no-recheck-plus',
        component: noRecheckPlus,
        meta: {
            title: '我的企业信息'
            // display: 'none'
        }
    }
];
