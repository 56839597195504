import View from '@/components/view';

const Hosp = () => import(/* webpackChunkName: "hosp" */ '@/pages/hxcm/settle/receivable-payable/hosp');
const Delivery = () => import(/* webpackChunkName: "Delivery" */ '@/pages/hxcm/settle/receivable-payable/delivery');
const Manage = () => import(/* webpackChunkName: "Manage" */ '@/pages/hxcm/settle/receivable-payable/manage');
const InvoiceList = () => import(/* webpackChunkName: "invoice-list" */ '@/pages/hxcm/settle/receivable-payable/invoice-list');
const Detail = () => import(/* webpackChunkName: "invoice-list-detail" */ '@/pages/hxcm/settle/common/settlement-apply/delivery-add');
const routes = [
    {
        component: View,
        path: 'receivable-payable',
        meta: {
            title: '应收应付汇总'
        },
        children: [
            {
                path: 'hosp/list',
                component: Hosp,
                meta: {
                    title: '医疗机构付款查询'
                }
            },
            {
                path: 'hosp/list/look',
                component: InvoiceList,
                meta: {
                    title: '发票列表',
                    display: 'none'
                }
            },
            {
                path: 'hosp/list/look/detail',
                component: Detail,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'delivery/list',
                component: Delivery,
                meta: {
                    title: '配送企业收款查询'
                }
            },
            {
                path: 'delivery/list/look',
                component: InvoiceList,
                meta: {
                    title: '发票列表',
                    display: 'none'
                }
            },
            {
                path: 'delivery/list/look/detail',
                component: Detail,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            },
            {
                path: 'manage/list',
                component: Manage,
                meta: {
                    title: '管理单位支付查询'
                }
            },
            {
                path: 'manage/list/look',
                component: InvoiceList,
                meta: {
                    title: '发票列表',
                    display: 'none'
                }
            },
            {
                path: 'manage/list/look/detail',
                component: Detail,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
