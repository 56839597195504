/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-11
 * @lastModifiedBy yanghy
 * @lastModifiedTime 18:14
 * @route
 * @description 企业端首页
 */


const ProjectIndex = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/home-page/inside-item/project-index');
const NoticeList = () => import(/* webpackChunkName: "notice-list" */ '@/pages/hxcm/tender/home-page/inside-item/notice-list');
const NoticeDetail = () => import(/* webpackChunkName: "notice-detail" */ '@/pages/hxcm/tender/home-page/inside-item/notice-detail');
const ProjectWorkbench = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/home-page/outside-item/product-org/index');

export default [
    {
        path: 'outside-item/product-org/index',
        component: ProjectWorkbench,
        meta: {
            title: '我的工作台'
        }
    },
    {
        path: 'inside-item/project-index',
        component: ProjectIndex,
        meta: {
            title: '首页'
        }
    },
    {
        path: 'inside-item/project-index/notice-list',
        component: NoticeList,
        meta: {
            title: '',
            display: 'none'
        }
    },
    {
        path: 'inside-item/project-index/notice-list/notice-detail',
        component: NoticeDetail,
        meta: {
            title: '',
            display: 'none'
        }
    }
];
