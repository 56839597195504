import View from '@/components/view';
import Manage from '@/pages/hxcm/settle/product/trading-manage/manage';

const OrderTracking = () => import('@/pages/hxcm/settle/product/trading-manage/order-tracking-drug');
const OrderDetail = () => import('@/pages/hxcm/settle/order-manage/drug/order-details');

const routes = [
    {
        path: 'trading-manage',
        component: View,
        meta: {
            title: '交易管理'
        },
        children: [
            {
                path: 'manage',
                component: Manage,
                meta: {
                    title: '采购订单管理'
                }
            },
            {
                path: 'manage/order-detail',
                component: OrderDetail,
                meta: {
                    title: '订单明细',
                    display: 'none'
                }
            },
            {
                path: 'order-tracking',
                component: OrderTracking,
                meta: {
                    title: '订单明细追踪'
                }
            }
        ]
    }
];
export default routes;
