/*
 * @Author: xiaoyu
 * @Email: wangxy2@guahao.com
 * @Date: 2019-11-30
 * @Last Modified by: hejk
 * @Last Modified time: 2021-03-25 11:14:29
 * @Description: 招采耗材-管理端-企业资料审核路由
 */

const ConsumableList = () => import(/* webpackChunkName: "ConsumableList" */ '@/pages/hxcm/tender/qualify-manage/enterprise/consumable/index');
const AuditDetail = () => import(/* webpackChunkName: "AuditDetail" */ '@/pages/hxcm/tender/qualify-manage/enterprise/consumable/audit-details');
const AuditedDetail = () => import(/* webpackChunkName: "AuditedDetail" */ '@/pages/hxcm/tender/qualify-manage/enterprise/consumable/audited-details');
const ComparaDetail = () => import(/* webpackChunkName: "ComparaDetail" */ '@/pages/hxcm/tender/qualify-manage/enterprise/consumable/compare-details');
const History = () => import(/* webpackChunkName: "ComparaDetail" */ '@/pages/hxcm/tender/qualify-manage/enterprise/consumable/history');

// 不复审
const noRecheckPlus = () => import(/* webpackChunkName: "noRecheckPush" */ '@/pages/hxcm/tender/qualify-manage/enterprise/consumable/no-recheck-plus');
const noRecheckDetailPlus = () => import(/* webpackChunkName: "noRecheckDetailPush" */ '@/pages/hxcm/tender/qualify-manage/enterprise/consumable/no-recheck-detail-plus');

export default [
    {
        path: 'enterprise/consumable/index',
        component: ConsumableList,
        meta: {
            title: '企业资料审核'
            // display: 'none'
        }
    },
    {
        path: 'enterprise/consumable/index/details',
        component: AuditDetail,
        meta: {
            title: '查看企业详情',
            display: 'none'
        }
    },
    {
        path: 'enterprise/consumable/index/audited-details',
        component: AuditedDetail,
        meta: {
            title: '查看企业详情',
            display: 'none'
        }
    },
    {
        path: 'enterprise/consumable/index/compare-details',
        component: ComparaDetail,
        meta: {
            title: '比对详情',
            display: 'none'
        }
    },
    {
        path: 'enterprise/consumable/index/history',
        component: History,
        meta: {
            title: '查看历史',
            display: 'none'
        }
    },
    {
        path: 'enterprise/consumable/no-recheck-plus',
        component: noRecheckPlus,
        meta: {
            title: '企业库'
            // display: 'none'
        }
    },
    {
        path: 'enterprise/consumable/no-recheck-plus/detail',
        component: noRecheckDetailPlus,
        meta: {
            title: '查看企业详情',
            display: 'none'
        }
    }
];
