/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 10:36
 * @route
 * @description 报量项目管理
*/
// 备案采购-药品-管理机构
import View from '@/components/view';

const List = () => import(/* webpackChunkName: "quantity-list" */ '@/pages/hxcm/trade/quantity-manage/list');
const ProvinceList = () => import(/* webpackChunkName: "province-list" */ '@/pages/hxcm/trade/quantity-manage/province-list');
const Fill = () => import(/* webpackChunkName: "quantity-fill" */ '@/pages/hxcm/trade/quantity-manage/drug/fill');
const ConsumableFill = () => import(/* webpackChunkName: "consumable-quantity-fill" */ '@/pages/hxcm/trade/quantity-manage/consumable/fill');

export default [
    {
        component: View,
        path: 'quantity-manage',
        meta: {
            title: '项目报量'
        },
        children: [
            {
                path: 'list',
                component: List,
                meta: {
                    title: '国家项目报量'
                }
            },
            {
                path: 'province-list',
                component: ProvinceList,
                meta: {
                    title: '省级项目报量管理'
                }
            },
            {
                path: 'list/drug/fill',
                component: Fill,
                meta: {
                    title: '采购数据填写',
                    display: 'none'
                }
            },
            {
                path: 'list/consumable/fill',
                component: ConsumableFill,
                meta: {
                    title: '采购数据填写',
                    display: 'none'
                }
            }
        ]
    }
];
