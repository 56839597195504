/*
 * @Author: yanchunl
 * @Email: yanchunl@hxmec.com
 * @Date: 2021-07-07 17:46:46
 * @Last Modified by: yanchunl
 * @Last Modified time: 2021-07-07 17:59:51
 * @Description: 检验试剂分类管理
 * @Route: Route
 */
import View from '@/components/view';

const ClassifiedManage = () => import(/* webpackChunkName: "classified-manage" */'@/pages/hxcm/tender/reagent-classification/classified-manage');
export default [
    {
        path: 'reagent-classification',
        component: View,
        meta: {
            title: '检验试剂分类管理'
        },
        children: [
            {
                path: 'classified-manage',
                component: ClassifiedManage,
                meta: {
                    title: '分类管理'
                }
            }
        ]
    }
];
