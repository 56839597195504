/**
 * @author yanghy
 * @email yanghy5@guahao.com
 * @date 2019-07-12
 * @lastModifiedBy yanghy
 * @lastModifiedTime 11:38
 * @route
 * @description 申投诉处理/申投诉管理
 */

const List = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/tender/statement-handle/statement-manage/list');
const Aduit = () => import(/* webpackChunkName: "aduit" */ '@/pages/hxcm/tender/statement-handle/statement-manage/aduit');
const Read = () => import(/* webpackChunkName: "aduit" */ '@/pages/hxcm/tender/statement-handle/statement-manage/read');

export default [
    {
        path: 'statement-manage/list',
        component: List,
        meta: {
            title: '申投诉管理'
        }
    },
    {
        path: 'statement-manage/list/aduit',
        component: Aduit,
        meta: {
            title: '处理申投诉',
            display: 'none'
        }
    },
    {
        path: 'statement-manage/list/read',
        component: Read,
        meta: {
            title: '查看申投诉',
            display: 'none'
        }
    }
];
