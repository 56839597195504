/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2021-02-24 17:27:18
 * @Last Modified by: hejk
 * @Last Modified time: 2021-03-25 11:12:48
 * @Description: 目录产品管理 (捏合)
 */
const CatalogProd = () => import(/* webpackChunkName: "catalog-prod" */'@/pages/hxcm/tender/catalog-manage/catalog-prod');

export default [
    {
        path: 'catalog-prod',
        component: CatalogProd,
        meta: {
            title: '目录产品管理',
            desc: '(耗材捏合)'
        }
    }
];
