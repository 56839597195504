import View from '@/components/view';

const routes = [
    {
        path: 'contract-signing',
        component: View,
        meta: {
            title: '国家集采合同签订'
        },
        children: [
            {
                path: 'main',
                component: () => import('@/pages/hxcm/settle/medical/contract-signing/main'),
                meta: {
                    title: '国家集采合同签订【主导医院端】'
                }
            },
            {
                path: 'main/into',
                component: () => import('@/pages/hxcm/settle/medical/contract-signing/into'),
                meta: {
                    title: '进入',
                    display: 'none'
                }
            }
        ]
    }
];
export default routes;
