/*
 * @Author: xiaoyu
 * @Email: wangxy2@guahao.com
 * @Date: 2019-11-30
 * @Last Modified by: wangxiaoyu
 * @Last Modified time: 2019-11-30
 * @Description: 管理端：药品+耗材企业资料审核
 */

import Consumable from './consumable';
import Drug from './drug';

export default [
    ...Consumable,
    ...Drug
];
