/*
 * @Author: maozg
 * @Email: maozg@guahao.com
 * @Date: 2019-10-31 15:54:46
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2021-08-22 16:14:20
 * @Description: 采购订单管理模块
 */
import View from '@/components/view';

const List = () => import('@/pages/hxcm/trade/order-manage/consumable/order-list.vue');
const AdditionList = () => import('@/pages/hxcm/trade/order-manage/consumable/addition-list.vue');
const OrderDetail = () => import('@/pages/hxcm/trade/order-manage/consumable/order-details.vue');
const PlanDetail = () => import('@/pages/hxcm/trade/order-manage/consumable/plan-details.vue');
const OrderTracking = () => import('@/pages/hxcm/trade/order-manage/consumable/order-tracking.vue');
const DeliveryList = () => import('@/pages/hxcm/trade/order-manage/consumable/delivery-list.vue');
const DeliveryDetail = () => import('@/pages/hxcm/trade/order-manage/consumable/diff-delivery.vue');
const OrderEvaluate = () => import('@/pages/hxcm/trade/order-manage/consumable/order-evaluate.vue');


export default [
    {
        path: 'consumable/order-manage',
        component: View,
        meta: {
            title: '采购订单管理'
        },
        children: [
            {
                path: 'order-list',
                component: List,
                meta: {
                    title: '采购订单管理'
                }
            },
            {
                path: 'order-list/detail',
                component: OrderDetail,
                meta: {
                    title: '订单明细',
                    display: 'none'
                }
            },
            {
                path: 'order-list/plan-detail',
                component: PlanDetail,
                meta: {
                    title: '采购计划明细',
                    display: 'none'
                }
            },
            {
                path: 'addition-list',
                component: AdditionList,
                meta: {
                    title: '补录订单管理'
                }
            },
            {
                path: 'addition-list/detail',
                component: OrderDetail,
                meta: {
                    title: '订单明细',
                    display: 'none'
                }
            },
            {
                path: 'addition-list/plan-detail',
                component: PlanDetail,
                meta: {
                    title: '采购计划明细',
                    display: 'none'
                }
            },
            {
                path: 'order-tracking', 
                component: OrderTracking,
                meta: {
                    title: '订单明细追踪'

                }
            },
            {
                path: 'delivery-list', 
                component: DeliveryList,
                meta: {
                    title: '采购汇总'
                }
            },
            {
                path: 'delivery-list/detail', 
                component: DeliveryDetail,
                meta: {
                    title: '采购汇总详情',
                    display: 'none'
                }
            },
            {
                path: 'order-evaluate',
                component: OrderEvaluate,
                meta: {
                    title: '采购订单评价'
                }
            },
            {
                path: 'order-evaluate/detail',
                component: OrderDetail,
                meta: {
                    title: '订单明细',
                    display: 'none'
                }
            },
            {
                path: 'order-evaluate/plan-detail',
                component: PlanDetail,
                meta: {
                    title: '采购计划明细',
                    display: 'none'
                }
            }
        ]
    }
];
