/*
* @Author: xiaoyu
* @Email: wangxy2@guahao.com
* @Date: 2020-02-19
 * @Last Modified by: hejk
 * @Last Modified time: 2021-03-25 11:13:58
* @Description: 耗材/报量路由管理
*/

const Catalog = () => import(/* webpackChunkName: "Catalog" */ '@/pages/hxcm/tender/province-report-management/consumable/catalog-manage');
const Choose = () => import(/* webpackChunkName: "Choose" */ '@/pages/hxcm/tender/province-report-management/consumable/choose-catalog');

const CONSUMABLEROUTES = [
    {
        path: 'consumable/catalog-manage',
        component: Catalog,
        meta: {
            title: '目录管理',
            flag: 'quantity'
        }
    },
    {
        path: 'consumable/catalog-manage/choose',
        component: Choose,
        meta: {
            title: '选择报量目录',
            flag: 'quantity',
            display: 'none'
        }
    }
];

export default CONSUMABLEROUTES;
