import Field from '@heisea/field';
import url from '@/api/trade/index';
import { limitsFile, headerConfig } from '@/utils/utils';
import { apiOrgName } from '@/api/tender/fuzzy-search';

const verifyPhone = (rule, value, cb) => {
    const mobileReg = /^1[^0-2]{1}[0-9]{9}$/g;
    if (value.trim() === '') {
        cb(new Error('请输入'));
    } else if (!mobileReg.test(value)) {
        cb(new Error('手机号格式不正确！'));
    } else {
        cb();
    }
};
export default new Field({
    refreshHeaders: () => headerConfig(),
    data: {
        医疗机构id: {
            name: 'medinsCode',
            type: 'search',
            label: '医疗机构',
            grid: 3,
            http: {
                url: url.queryOrg,
                params: {
                    default: 'orgName',
                    orgTypeCode: '2' // 0运营平台,1监管机构（医保单位）,2医疗机构（定点医院）,3生产企业,4配送企业,5代理企业,
                },
                method: 'post',
                initSwitch: false,
                onSuccess: res => {
                    if (res.code === 0) {
                        return res.data;
                    }
                    return res;
                }
            },
            props: {
                label: 'orgName',
                value: 'entpCode'
            }
        },
        医疗机构id_form: {
            name: 'medinsCode',
            label: '医疗机构',
            formExtend: {
                type: 'search',
                valueObj: true,
                http: {
                    url: url.queryOrg,
                    params: {
                        default: 'orgName',
                        orgTypeCode: '2' // 0运营平台,1监管机构（医保单位）,2医疗机构（定点医院）,3生产企业,4配送企业,5代理企业,
                    },
                    method: 'post',
                    initSwitch: false,
                    onSuccess: res => {
                        if (res.code === 0) {
                            res.data = res.data.map(i => ({
                                label: i.orgName,
                                value: i.entpCode
                            }));
                            return res.data;
                        }
                        return res;
                    }
                }
            }
        },
        医疗机构Label: {
            name: 'medinsCode',
            type: 'search',
            label: '医疗机构',
            grid: 3,
            http: {
                url: url.queryOrg,
                params: {
                    default: 'orgName',
                    orgTypeCode: '2' // 0运营平台,1监管机构（医保单位）,2医疗机构（定点医院）,3生产企业,4配送企业,5代理企业,
                },
                method: 'post',
                initSwitch: false,
                onSuccess: res => {
                    if (res.code === 0) {
                        return res.data;
                    }
                    return res;
                }
            },
            props: {
                label: 'orgName',
                value: 'orgName'
            }
        },
        医疗机构: {
            name: 'medinsName',
            label: '医疗机构'
        },
        生产企业id: {
            name: 'prodentpCode',
            type: 'search',
            label: '生产企业',
            grid: 3,
            http: {
                url: url.queryOrg,
                params: {
                    default: 'orgName',
                    orgTypeCode: '3' // 0运营平台,1监管机构（医保单位）,2医疗机构（定点医院）,3生产企业,4配送企业,5代理企业,
                },
                method: 'post',
                initSwitch: false
            },
            props: {
                label: 'orgName',
                value: 'entpCode'
            }
        },
        投标企业id: {
            label: '投标企业',
            name: 'dclaEntpUscc',
            type: 'search',
            grid: 3,
            placeholder: '请输入',
            http: {
                url: apiOrgName,
                method: 'post',
                params: {
                    default: 'orgName',
                    orgTypeList: [3, 4, 5, 6, 7, 8, 9]
                },
                initSwitch: false
            },
            props: {
                value: 'uscc',
                label: 'orgName'
            }
        },
        配送企业id: {
            name: 'delventpCode',
            type: 'search',
            label: '配送企业',
            http: {
                url: url.queryOrg,
                params: {
                    default: 'orgName',
                    orgTypeCode: '4'
                },
                method: 'post',
                initSwitch: false
            },
            props: {
                label: 'orgName',
                value: 'entpCode'
            }
        },
        变更后配送企业: {
            name: 'delventpName',
            label: '变更后配送企业'
        },
        配送企业: {
            name: 'delvEntp',
            label: '配送企业'
        },
        医保区划id: {
            name: 'admdvs',
            label: '医保区划',
            type: 'select',
            http: {
                method: 'post',
                url: '/web/trans/base/admdvs/list_admdvs'
            },
            props: {
                label: 'label',
                value: 'value'
            }
        },
        状态: {
            name: 'enabVrtaAcctFlag',
            label: '状态',
            extend: {
                formatter(row) {
                    return `${row.enabVrtaAcctFlag === '0' ? '禁用' : '启用'}`;
                }
            }

        },
        退货明细编号: {
            name: 'retnCode',
            label: '退货明细编号',
            type: 'input'
        },
        订单编号: {
            name: 'ordCode',
            label: '订单编号',
            type: 'input'
        },
        退货时间: {
            name: 'delventpPassTime',
            label: '退货时间'
        },
        退货时间mcs: {
            name: 'medinsRetnTime',
            type: 'daterange',
            noSubmit: true,
            label: '退货时间',
            extraFields: ['startMedinsRetnTime', 'endMedinsRetnTime'],
            grid: 3
        },
        品名: {
            name: 'prodName',
            label: '品名',
            type: 'input'
        },
        通用名: {
            name: 'prodName',
            label: '通用名'
        },
        剂型: {
            name: 'dosform',
            label: '剂型',
            type: 'input'
        },
        饮片剂: {
            name: 'dosform',
            label: '饮片剂',
            type: 'input'
        },
        产品等级: {
            name: 'qualityName',
            label: '产品等级'
        },
        规格: {
            name: 'prodSpec',
            label: '规格',
            type: 'input'
        },
        包装: {
            name: 'prodPac',
            label: '包装',
            type: 'input'
        },
        本次退货数量: {
            name: 'retnCnt',
            label: '本次退货数量'
        },
        发票抬头: {
            name: 'invottl',
            label: '发票抬头'
        },
        发送时间: {
            name: 'sendTime',
            label: '发送时间'
        },
        '订单金额(元)': {
            name: 'ordSumamt',
            label: '订单金额(元)'
        },
        '收货金额(元)': {
            name: 'shppAmt',
            label: '收货金额(元)'
        },
        统计月份: {
            name: 'sendTime',
            type: 'daterange',
            label: '发送时间',
            extraFields: ['startSendTime', 'endSendTime'],
            grid: 3
        },
        本次退货金额: {
            name: 'retnAmt',
            label: '本次退货金额(元)'
        },
        医院采购价: {
            name: 'purcpric',
            label: '医院采购价'
        },
        挂网价: {
            name: 'pubonlnPric',
            label: '挂网价'
        },
        包装材质: {
            name: 'pacMatl',
            label: '包装材质',
            type: 'input'
        },
        生产企业: {
            name: 'prodentpName',
            label: '生产企业',
            type: 'input'
        },
        代理企业: {
            name: 'dclaEntpName',
            label: '代理企业'
        },
        批号: {
            name: 'manuLotnum',
            label: '批号',
            type: 'input'
        },
        发货时间: {
            name: 'shpTime',
            label: '发货时间'
        },
        退货原因: {
            name: 'medinsRetnRea',
            label: '退货原因'
        },
        配送企业delventpName: {
            name: 'delventpName',
            label: '配送企业'
        },
        项目名称: {
            name: 'itemname',
            label: '项目名称'
        },
        采购金额: {
            name: 'purcAmt',
            label: '采购金额'
        },
        发货金额: {
            name: 'shpAmt',
            label: '发货金额'
        },
        发货率: {
            name: 'shpRate',
            label: '发货率'
        },
        收货金额: {
            name: 'shppAmt',
            label: '收货金额'
        },
        收货率: {
            name: 'shppRate',
            label: '收货率'
        },
        '挂网价(元)': {
            name: 'pubonlnPric',
            label: '挂网价(元)'
        },
        挂网时间: {
            name: 'crteTime',
            label: '挂网时间'
        },
        责任产品: {
            name: 'linkProdNum',
            label: '责任产品',
            type: 'input'
        },
        操作信息: {
            name: 'logContent',
            label: '操作信息',
            type: 'input'
        },
        操作信息Desc: {
            name: 'logContent',
            label: '操作信息',
            extend: {
                width: '800'
            },
            render: (h, p) => {
                const { row } = p;
                const text = `【${row.bizId}】,【${row.module}】,【${row.clientIp}】,【${row.orgName}】,【${row.logContent}】`;
                return <span>{text}</span>;
            }
        },
        操作人: {
            name: 'userName',
            label: '操作人',
            type: 'input'
        },
        操作时间: {
            name: 'optTime',
            label: '操作时间',
            type: 'daterange',
            noSubmit: true,
            extraFields: ['optTimeBegin', 'optTimeEnd'],
            extend: {
                type: 'daterange',
                'picker-options': {
                    disabledDate: t => {
                        const timer1 = new Date();
                        if ((t.getTime() > timer1)) return true;
                        return false;
                    }
                }
            },
            grid: 3

        },
        所属角色: {
            name: 'orgType',
            label: '所属角色',
            type: 'select',
            options: [
                {
                    value: '2',
                    label: '医疗机构'
                },
                {
                    value: '3,4',
                    label: '配送企业'
                }
                // {
                //     value: '1',
                //     label: '管理单位'
                // }
            ]
        },
        责任机构: {
            name: 'linkHospNum',
            label: '责任机构',
            type: 'search',
            grid: 3,
            http: {
                url: url.queryOrg,
                params: {
                    default: 'orgName',
                    orgTypeCode: '2'
                },
                method: 'post',
                initSwitch: false
            },
            props: {
                label: 'orgName',
                value: 'entpCode'
            }
        },
        // 配送企业: {
        //     name: 'delventpName'
        // },
        联系人: {
            name: 'coner',
            type: 'input'
        },
        联系方式: {
            name: 'conerMob',
            type: 'input'
        },
        注册证编号: {
            name: 'aprvno',
            type: 'input'
        },
        注册证名称: {
            name: 'regcert',
            type: 'input'
        },
        型号: {
            name: 'mol',
            type: 'input'
        },
        产品材质: {
            name: 'prodMatl',
            type: 'input'
        },
        医保区划: {
            name: 'admdvsName',
            type: 'input'
        },
        上级管理单位: {
            name: 'admorgName',
            type: 'input'
        },
        上级管理单位id: {
            label: '上级管理单位',
            name: 'admorgCode',
            type: 'search',
            http: {
                url: url.queryOrg,
                params: {
                    default: 'orgName',
                    orgTypeCode: '1'// 0运营平台,1监管机构（医保单位）,2医疗机构（定点医院）,3生产企业,4配送企业,5代理企业,
                },
                method: 'post',
                initSwitch: false
            },
            props: {
                label: 'orgName',
                value: 'entpCode'
            }
        },
        医疗机构orgName: {
            name: 'orgName',
            label: '医疗机构',
            type: 'input'
        },
        责任产品Form: {
            label: '责任产品',
            name: 'prodNum'
        },
        责任机构Form: {
            label: '责任机构',
            name: 'orgNum'
        },
        注册证是否过期: {
            name: 'expyStas',
            label: '注册证是否过期',
            type: 'select',
            http: {
                url: '/web/trans/base/enums/query_list',
                params: {
                    enumsName: 'ExpyStasEnum'
                }
            }
        },

        统一社会信用代码: {
            name: 'uscc',
            label: '统一社会信用代码'
        },
        营业执照有效期: {
            name: 'bizLicExpyBegintime',
            label: '营业执照有效期',
            grid: 2
        },
        营业执照有效期end: {
            name: 'bizLicExpyEndtime',
            label: '',
            grid: 2
        },
        企业营业执照: {
            name: 'bizLicAtt',
            label: '企业营业执照',
            type: 'upload',
            readonly: true
        },
        生产许可证号: {
            name: 'mcsManuLicNo',
            label: '生产许可证号'
        },
        生产许可证有效期: {
            name: 'mcsManuLicBegintime',
            label: '生产许可证有效期',
            grid: 2
        },
        生产许可证有效期end: {
            name: 'mcsManuLicEndtime',
            label: '',
            grid: 2
        },
        生产许可证附件: {
            name: 'mcsManuLicAtt',
            label: '生产许可证附件',
            type: 'upload',
            readonly: true
        },
        经营许可证号: {
            name: 'mcsBizLicNo',
            label: '经营许可证号'
        },
        经营许可证有效期: {
            name: 'mcsBizLicBegintime',
            label: '经营许可证有效期',
            grid: 2
        },
        经营许可证有效期end: {
            name: 'mcsBizLicEndtime',
            label: '',
            grid: 2
        },
        经营许可证附件: {
            name: 'mcsBizLicAtt',
            label: '经营许可证附件',
            type: 'upload',
            readonly: true
        },
        资质名称: {
            name: 'quaName',
            label: '资质名称',
            type: 'select',
            options: [
                {
                    value: '生产许可证',
                    label: '生产许可证'
                },
                {
                    value: '经营许可证',
                    label: '经营许可证'
                },
                {
                    value: '企业营业执照',
                    label: '企业营业执照'
                },
                {
                    value: '',
                    label: '全部'
                }
            ]
        },
        过期情况: {
            name: 'expyInfoType',
            label: '过期情况',
            type: 'select',
            options: [
                {
                    value: '0',
                    label: '资质已过期'
                },
                {
                    value: '1',
                    label: '资质一个月内即将过期'
                },
                {
                    value: '2',
                    label: '资质3个月内即将过期'
                },
                {
                    value: '3',
                    label: '资质6个月内即将过期'
                },
                {
                    value: '',
                    label: '全部'
                }
            ]
        },
        过期情况Desc: {
            name: 'expyInfo',
            label: '过期情况'
        },
        资质有效期: {
            name: 'expyEndTime',
            label: '资质有效期'
        },
        资质编号: {
            name: 'quaNo',
            label: '资质编码'
        },
        资质名称Desc: {
            name: 'quaName',
            label: '资质名称'
        },
        企业名称: {
            name: 'orgName',
            label: '企业名称',
            type: 'input'
        },
        目录属性: {
            name: 'listAttrCode',
            type: 'select',
            http: {
                url: '/web/trans/base/dicdata/list_select',
                params: {
                    grpKey: 'LIST_ATTR_CODE'
                }
            }
        },
        类别: {
            name: 'mgtType',
            type: 'select',
            http: {
                url: '/web/trans/base/dicdata/list_select',
                params: {
                    grpKey: 'MCS_MGT_TYPE'
                }
            }
        },
        管理类别: {
            name: 'mgtType',
            label: '管理类别'
        },
        分类代码: {
            name: 'medMcsTypeCode',
            label: '分类代码'
        },
        目录名称: {
            name: 'mcsTypeLv1Name',
            label: '目录名称',
            type: 'input'
        },
        产品规格: {
            name: 'mcsSpec',
            label: '产品规格'
        },
        产品型号: {
            name: 'mcsMol',
            label: '产品型号'
        },
        产品转换比: {
            name: 'convrat',
            label: '产品转换比'
        },
        最小使用单位: {
            name: 'minuntName',
            label: '最小使用单位'
        },
        投标企业: {
            name: 'dclaEntpName',
            label: '投标企业'
        },
        包装单位: {
            name: 'minpacuntName',
            label: '包装单位'
        },
        企业类型: {
            name: 'orgTypeCode',
            label: '企业类型',
            type: 'select',
            options: [
                {
                    value: '3',
                    label: '生产企业'
                },
                {
                    value: '5',
                    label: '代理企业'
                },
                {
                    value: '4',
                    label: '配送企业'
                }
            ]
        },
        企业类型Desc: {
            name: 'orgTypeName',
            label: '企业类型'
        },
        订单类型: {
            name: 'splmInpt',
            label: '订单类型',
            type: 'select',
            options: [
                {
                    label: '采购订单',
                    value: '0'
                },
                {
                    label: '补录订单',
                    value: '1'
                }
            ]
        },
        阅读状态Desc: {
            name: 'readFlag',
            label: '阅读状态',
            render: (h, p) => {
                const { row } = p;
                const text = `${row.readFlag === '1' ? '已读' : '未读'}`;
                return <span>{text}</span>;
            }
        },
        配送阅读时间: {
            name: 'readTime',
            label: '配送企业阅读时间'
        },
        制单人: {
            name: 'docmker',
            type: 'input',
            label: '制单人',
            grid: 3
        },
        备注: {
            name: 'note',
            label: '备注'
        },
        医院采购价格: {
            name: 'purcpric',
            label: '医院采购价格'
        },
        订单类型Desc: {
            name: 'splmInpt',
            label: '订单类型',
            render: (h, p) => {
                const { row } = p;
                const text = `${row.splmInpt === '0' ? '采购订单' : '补录订单'}`;
                return <span>{text}</span>;
            }
        },
        销退发票号: {
            name: 'retnInvo',
            label: '销退发票号'
        },
        销退清单号: {
            name: 'retnList',
            label: '销退清单号'
        },
        采购计划编号: {
            name: 'purcPlanCode',
            type: 'input',
            label: '采购计划编号',
            grid: 3
        },
        采购计划名称: {
            name: 'purcPlanName',
            type: 'input',
            label: '采购计划名称',
            grid: 3
        },
        制单时间: {
            name: 'crteTime',
            type: 'daterange',
            label: '制单时间',
            extraFields: ['creatBegin', 'creatEnd'],
            grid: 3
        },
        审核状态admorgChkStas: {
            name: 'admorgChkStas',
            label: '审核状态',
            type: 'select',
            http: {
                url: '/web/trans/base/enums/query_list?enumsName=PlanAdmorgChkStasEnum'
            },
            props: {
                label: 'label',
                value: 'value'
            }
        },
        拒绝理由: {
            name: 'changeChkRea',
            label: '',
            type: 'input',
            maxLength: 20,
            placeholder: '如需拒绝:请输入20字以内的拒绝理由,否则将显示"无"'
        },
        已添加至院内目录: {
            name: 'hospProdNum',
            label: '已添加至院内目录'
        },
        产品相关: {
            name: 'prodNum',
            label: '产品相关'
        },
        项目名称id: {
            name: 'tenditmId',
            label: '项目名称',
            type: 'search',
            http: {
                method: 'post',
                url: '/web/trans/item/list_select',
                params: {
                    default: 'itemname',
                    prodType: 1
                }
            }
        },
        药品统一编码: {
            name: 'drugCode',
            type: 'input'
        },
        药品统一编码yj: {
            label: '药品统一编码',
            name: 'prodCode',
            type: 'input'
        },
        大包装: {
            name: 'prodBigPac',
            type: 'input',
            label: '大包装'
        },
        代理企业id: {
            name: 'dclaEntpCode',
            type: 'search',
            label: '代理企业',
            grid: 3,
            http: {
                url: url.queryOrg,
                params: {
                    default: 'orgName',
                    orgTypeCode: '5'
                },
                method: 'post',
                initSwitch: false
            },
            props: {
                label: 'orgName',
                value: 'entpCode'
            }
        },
        基药属性: {
            name: 'essdrugType',
            type: 'select',
            label: '基药属性',
            options: [
                {
                    value: 1,
                    label: '基药'
                },
                {
                    value: 3,
                    label: '非基药'
                }
            ]
        },
        提交时间: {
            name: 'sbmtTime',
            type: 'daterange',
            noSubmit: true,
            label: '提交时间',
            extraFields: ['sbmtTimeBegin', 'sbmtTimeEnd'],
            grid: 3
        },
        变更医院: {
            name: 'medinsName',
            label: '变更医院'
        },
        调整后价格: {
            name: 'pricAfter',
            label: '调整后价格'
        },
        审核状态: {
            name: 'prodChangeStasDesc',
            label: '审核状态'
        },
        变更时间: {
            name: 'changeTime',
            label: '变更时间'
        },
        医保区划Desc: {
            name: 'admdvsName',
            label: '医保区划'
        },
        变更前配送: {
            name: 'delventpNameBefore',
            label: '变更前配送'
        },
        变更后配送: {
            name: 'delventpName',
            label: '变更后配送'
        },
        变更前价格: {
            name: 'pricBefore',
            label: '变更前价格'
        },
        变更后价格: {
            name: 'pricAfter',
            label: '变更后价格'
        },
        提交人: {
            name: 'crterName',
            label: '提交人'
        },
        基药属性Desc: {
            name: 'essdrugTypeVal',
            label: '基药属性'
        },
        省级集采限价: {
            name: 'provLmtpric',
            label: '省级集采限价'
        },
        产品来源Desc: {
            name: 'prodSoucName',
            label: '产品来源'
        },
        '批准文号/注册证号': {
            name: 'aprvno',
            label: '批准文号/注册证号'
        },
        '2017医保支付标准': {
            name: 'hiPayPric',
            label: '2017医保支付标准'
        },
        是否添至院内目录Desc: {
            name: 'addedHospFlagDesc',
            label: '是否添至院内目录'
        },
        产品采购类型Desc: {
            name: 'amountFlagDesc',
            label: '产品采购类型'
        },
        采购价格: {
            name: 'purcpric',
            label: '采购价格'
        },
        产品来源: {
            name: 'prodSoucVal'
        },
        执行时间: {
            name: 'time',
            label: '执行时间'
        },
        执行区域: {
            name: 'areaList',
            label: '执行区域',
            type: 'tag',
            targetKeys: ['name'],
            contentSeparator: '，'
        },
        产品信息: {
            name: 'product',
            label: '产品信息'
        },
        '2017医保支付价': {
            name: 'hiPayPric'
        },
        年份: {
            name: 'year',
            label: '年份',
            type: 'date',
            extend: {
                type: 'year'
            }
        },
        季度: {
            name: 'quat',
            label: '季度',
            type: 'select',
            options: [
                {
                    label: '第一季度',
                    value: 1
                },
                {
                    label: '第二季度',
                    value: 2
                },
                {
                    label: '第三季度',
                    value: 3
                },
                {
                    label: '第四季度',
                    value: 4
                }
            ]
        },
        流水号: {
            name: 'sn',
            label: '流水号',
            type: 'input'
        },
        通用名称: {
            name: 'genname',
            label: '通用名称',
            type: 'input'
        },
        实际成交价格平均数: {
            name: 'avePric',
            label: '实际成交价格平均数'
        },
        时间成交价额中位数: {
            name: 'medianPric',
            label: '时间成交价额中位数'
        },
        全网最低价: {
            name: 'lowestPric',
            label: '全网最低价'
        },
        医保支付参考价: {
            name: 'hiPaypric',
            label: '医保支付参考价'
        },
        目录最终价格: {
            name: 'finlpric',
            label: '目录最终价格'
        },
        选择生产企业: {
            name: 'prodentpCodes',
            type: 'transfer',
            label: '',
            props: {
                label: 'orgName',
                key: 'entpCode'
            },
            objToString: true,
            extend: {
                filterable: true,
                buttonTexts: ['移除', '添加'],
                titles: ['生产企业', '已选择'],
                renderContent: (h, option) => (
                    <span>
                        {option.orgName}
                    </span>
                )
            }
        },
        // 选择主导医疗机构: {
        //     name: 'medinsCodes',
        //     type: 'tag',
        //     label: '选择主导医疗机构',
        //     options: [],
        //     props: {
        //         label: 'orgName',
        //         key: 'entpCode'
        //     }
        // },
        项目执行时间: {
            name: 'time',
            label: '项目执行时间',
            type: 'daterange',
            noSubmit: true,
            extraFields: ['begntime', 'endtime']
        },
        签约执行时间: {
            name: 'signTime',
            label: '签约执行时间',
            type: 'daterange',
            noSubmit: true,
            extraFields: ['signBegntime', 'signEndtime']
        },
        合同模板: {
            name: 'cntrTmpFileId',
            label: '合同模板',
            type: 'upload',
            required: true
        },
        委托书模板: {
            name: 'entrTmpFileId',
            label: '委托书模板',
            type: 'upload',
            required: true
            // extend: {
            //     limit: 1,
            //     headers: headerConfig()
            // }
        },
        签约生产企业: {
            name: 'signProdentpNum',
            label: '签约生产企业',
            scene: 'dialog',
            target: 'lookDialog2'
        },
        成员医疗机构: {
            name: 'signHospNum',
            label: '成员医疗机构',
            scene: 'dialog',
            target: 'lookDialog1'
        },
        成员医疗机构Desc: {
            name: 'medinsName',
            label: '成员医疗机构'
        },
        签约状态Desc: {
            name: 'cntrStas',
            label: '签约状态',
            render: (h, p) => {
                const { row } = p;
                return <span>{row.cntrStas === '0' ? '待确认' : '已确认'}</span>;
            }
        },
        签约状态signStasDesc: {
            name: 'signStasDesc',
            label: '签约状态'
        },
        绑定关系Desc: {
            name: 'bindStasDesc',
            label: '绑定关系'
        },
        签约状态: {
            name: 'signStas',
            label: '签约状态',
            type: 'select',
            options: [
                {
                    label: '待确认',
                    value: '0'
                },
                {
                    label: '已确认',
                    value: '1'
                }
            ]
        },
        合同下载: {
            name: '合同下载',
            label: '合同下载',
            type: 'uplaod'
        },
        上报文件: {
            name: 'fileId',
            label: '',
            type: 'upload'
        },
        委托书上传: {
            name: 'entrFileId',
            label: '',
            type: 'upload',
            placeholder: '仅支持PDF文档上传',
            // extend: {
            //     accept: '.pdf',
            //     limit: 1,
            //     headers: headerConfig()
            // },
            uploadBefore: file => {
                const flag = limitsFile(file, ['pdf'], '上传附件的格式只能是.pdf!');
                return flag;
            }
        },
        上传合同: {
            name: 'fileId',
            label: '',
            type: 'upload',
            required: true,
            // extend: {
            //     accept: '.pdf',
            //     limit: 1,
            //     headers: headerConfig()
            // },
            uploadBefore: file => {
                const flag = limitsFile(file, ['pdf'], '上传附件的格式只能是.pdf!');
                return flag;
            }
        },
        联系电话: {
            name: 'conerMob',
            label: '联系电话',
            type: 'input',
            rules: [
                {
                    required: true,
                    message: '请输入',
                    trigger: 'blur'
                },
                {
                    validator: verifyPhone,
                    trigger: 'blur'
                }
            ]
        },
        联系电话Search: {
            name: 'conerMob',
            label: '联系电话',
            type: 'input'
        },
        医疗机构名称: {
            name: 'medinsName',
            label: '医疗机构名称',
            type: 'input'
        },
        项目开始时间: {
            name: 'begntime',
            label: '项目开始时间'
        },
        项目结束时间: {
            name: 'endtime',
            label: '项目结束时间'
        },
        签约开始时间: {
            name: 'signBegntime',
            label: '签约开始时间'
        },
        签约结束时间: {
            name: 'signEndtime',
            label: '签约结束时间'
        },
        签约厂商: {
            name: 'signProdentpNum',
            label: '签约厂商',
            scene: 'dialog',
            target: 'opDialog'
        },
        委托文件: {
            name: 'entrFileId',
            label: '委托文件',
            type: 'upload'
        },
        签约时间: {
            name: 'prodentpSignTime',
            label: '签约时间'
        },
        操作机构: {
            name: 'medinsName',
            label: '操作机构'
        },
        附件: {
            name: 'prodentpSignFileId',
            label: '附件'
        },
        主导医疗机构: {
            name: 'prntMedinsName',
            label: '主导医疗机构'
        },
        下载委托书: {
            name: 'entrFileId',
            label: '下载委托书',
            formExtend: {
                type: 'upload',
                readonly: true
            }
        },
        委托书签订状态: {
            name: 'entrStasDesc',
            label: '委托书签订状态'
        },
        商品编码: {
            name: 'mcsCode',
            label: '商品编码'
        },
        产品属性Desc: {
            name: 'listAttrName',
            label: '产品属性'
        },
        生成企业: {
            name: 'prodentpCode',
            label: '生成企业'
        },
        耗材型号: {
            name: 'mcsMol',
            label: '型号'
        },
        耗材包装: {
            name: 'mcsPac',
            label: '包装'
        },
        耗材材质: {
            name: 'mcsMatl',
            label: '材质'
        },
        生产企业编号: {
            name: 'prodentpCode',
            label: '生产企业编号'
        },
        生产企业名称: {
            name: 'prodentpName',
            label: '生产企业名称'
        },
        品牌: {
            name: 'brad',
            label: '品牌'
        },
        注册证有效期: {
            name: 'regcertExpyEndtime',
            label: '注册证有效期'
        },
        产品性能及结构组成: {
            name: 'perfComp',
            label: '产品性能及结构组成'
        },
        类别Desc: {
            name: 'mgtTypeName',
            label: '类别'
        },
        需求量: {
            name: 'fortPurcCnt',
            label: '需求量'
        },
        类别Mcs: {
            name: 'mcsType',
            label: '类别',
            type: 'select',
            http: {
                url: '/web/trans/prod/trnsitemprod/rpup/list_mcstype'
            },
            props: {
                label: 'mcsTypeBigName',
                value: 'mcsTypeBigCode'
            }
        },
        中选价格: {
            name: 'negoPrice',
            label: '中选价格',
            type: 'input'
        },
        本地编码: {
            label: '本地编码',
            name: 'mcsCode',
            type: 'input'
        },
        带量采购常用目录: {
            name: 'prodTag',
            type: 'select',
            http: {
                url: '/web/trans/base/dicdata/list_select',
                params: {
                    grpKey: 'PROD_TAG'
                }
            },
            desc: 'Val'
        },
        医院名称: {
            name: 'medinsName',
            type: 'input'
        },
        产品属性: {
            name: 'listAttrCode',
            type: 'select',
            http: {
                url: '/web/trans/base/dicdata/list_select',
                params: {
                    grpKey: 'LIST_ATTR_CODE'
                }
            }
        },
        安徽省份: {
            name: 'provCode',
            type: 'select',
            http: {
                url: '/web/bdc/comRegion/query',
                method: 'post',
                params: {
                    default: 'prntAdmdvs',
                    defaultValue: '100000'
                }
            },
            props: {
                value: 'admdvs',
                label: 'admdvsName'
            },
            value: '340000',
            disabled: true
        },
        安徽城市: {
            name: 'cityCode',
            label: '城市',
            type: 'select',
            options: [],
            http: {
                url: '/web/bdc/comRegion/query',
                method: 'post',
                params: {
                    default: 'prntAdmdvs',
                    defaultValue: '340000'
                }
            },
            props: {
                value: 'admdvs',
                label: 'admdvsName'
            }
        },
        区县: {
            name: 'cotyCode',
            label: '区县',
            type: 'select',
            options: [],
            http: {
                url: '/web/bdc/comRegion/query',
                method: 'post',
                params: {
                    prntAdmdvs: '-1'
                },
                initSwitch: false
            },
            props: {
                value: 'admdvs',
                label: 'admdvsName'
            },
            depend: {
                name: 'cityCode',
                onChange: (val, that) => {
                    if (val !== null && val !== '' && val !== undefined) {
                        that.materialConfig.http.params.prntAdmdvs = val;
                    } else {
                        that.materialConfig.http.params.prntAdmdvs = -1;
                    }
                }
            }
        },
        选择医疗机构类型: {
            name: '选择医疗机构类型',
            label: '',
            type: 'radio',
            options: [
                {
                    label: '按区域选择医疗机构',
                    value: '1'
                },
                {
                    label: '精准选择医疗机构',
                    value: '2'
                }
            ],
            grid: 1
        },
        '请选择医疗机构后,设置对应的配送企业': {
            name: 'delventpCode',
            type: 'search',
            label: '选择配送企业',
            http: {
                url: url.queryOrg,
                params: {
                    default: 'orgName',
                    orgTypeCode: '4'
                },
                method: 'post',
                initSwitch: false
            },
            props: {
                label: 'orgName'
            }
        },
        单位: {
            name: 'minpacuntName'
        },
        采购限价: {
            name: 'purcLmtpric'
        },
        带量采购限价: {
            name: 'rpupPurcLmtpric'
        },
        带量采购价: {
            name: 'purcPric'
        },
        地区: {
            name: 'admdvsName'
        },
        材质: {
            name: 'prodMatl'
        },
        企业编号: {
            name: 'orgCode'
        },
        企业联系人: {
            name: 'conerName'
        },
        企业联系电话: {
            name: 'conerTel'
        },
        营业执照有效日期: {
            name: 'licExpy'
        },
        生产或经营许可证号: {
            name: 'pmtNo'
        },
        生产或经营许可证有效期: {
            name: 'pmtExpy'
        },
        报名企业编码: {
            name: 'dclaEntpCode'
        },
        报名企业名称: {
            name: 'dclaEntpName'
        },
        产品使用范围: {
            name: 'apbScp'
        },
        本地编码Prod: {
            label: '本地编码',
            name: 'prodCode',
            type: 'input'
        },
        目录名称Prod: {
            label: '目录名称',
            name: 'prodName',
            type: 'input'
        },
        医疗机构性质: {
            name: 'hospNatuCode',
            label: '医疗机构性质',
            type: 'select',
            http: {
                url: '/web/trans/base/enums/query_list',
                params: {
                    enumsName: 'HospNatuCodeEnum'
                },
                onSuccess: res => {
                    if (res.code === 0) {
                        res.data.unshift({
                            label: '全部',
                            value: ''
                        });
                    }
                }
            }
        },
        医疗机构性质Desc: {
            name: 'hospNatuCodeDesc',
            label: '医疗机构性质'
        },
        医疗机构等级: {
            name: 'hospLv',
            label: '医疗机构等级',
            type: 'select',
            http: {
                url: '/web/trans/base/enums/query_list',
                params: {
                    enumsName: 'HospLvEnum'
                },
                onSuccess: res => {
                    if (res.code === 0) {
                        res.data.unshift({
                            label: '全部',
                            value: ''
                        });
                    }
                }
            }
        },
        是否带量: {
            name: 'provAmtTag',
            label: '是否带量',
            extend: {
                formatter(row) {
                    return `${row.provAmtTag === '0' ? '否' : '是'}`;
                }
            }
        },
        需求量合计: {
            name: 'fortPurcCnt',
            label: '需求量合计'
        },
        上报月份: {
            name: 'ym',
            label: '上报月份',
            type: 'month',
            extend: {
                type: 'month',
                'picker-options': {
                    disabledDate: t => {
                        const date = new Date();
                        const time1 = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-01`);
                        if ((t.getTime() > time1)) return true;
                        return false;
                    }
                }
            }
        },
        医疗机构等级Desc: {
            name: 'hospLvName',
            label: '医疗机构等级'
        },
        是否基层: {
            name: 'grstHospFlag',
            label: '是否基层',
            type: 'select',
            options: [
                {
                    value: '',
                    label: '全部'
                },
                {
                    value: '0',
                    label: '否'
                },
                {
                    value: '1',
                    label: '是'
                }
            ]
        },
        是否基层Desc: {
            name: 'grstHospFlagDesc',
            label: '是否基层'
        },
        机构编码: {
            name: 'entpCode',
            label: '机构编码'
        },
        复核人员: {
            name: 'opterName',
            label: '复核人员'
        },
        是否支持医保代付: {
            name: 'setlWayDesc',
            label: '是否支持医保代付'
        },
        确认状态: {
            name: 'cnfmStas',
            label: '确认状态',
            type: 'select',
            options: [
                {
                    value: '',
                    label: '全部'
                },
                {
                    value: '1',
                    label: '未确认'
                },
                {
                    value: '2',
                    label: '已确认'
                },
                {
                    value: '3',
                    label: '已拒绝'
                }
            ]
        },
        确认状态Desc: {
            name: 'cnfmStas',
            label: '确认状态',
            extend: {
                formatter(row) {
                    let value = '';
                    switch (row.cnfmStas) {
                    case '1':
                        value = '未确认';
                        break;
                    case '2':
                        value = '已确认';
                        break;
                    case '3':
                        value = '已拒绝';
                        break;
                    default:
                        value = '';
                    }
                    return value;
                }
            }
        },
        包装规格: {
            name: 'pacmatl',
            label: '包装规格'
        },
        注册证有效期止: {
            name: 'regcertExpy',
            label: '注册证有效期止'
        },
        调整时间: {
            name: 'updtTime',
            label: '调整时间'
        },
        付款户名: {
            name: 'acctName',
            label: '付款户名'
        },
        付款账号: {
            name: 'fromBankCardId',
            label: '付款账号',
            type: 'select'
        },
        开户行别: {
            name: 'subBank',
            label: '开户行别'
        },
        账户余额: {
            name: 'balc',
            label: '账户余额'
        },
        账号备注: {
            name: 'remarks',
            label: '账号备注'
        },
        付款金额: {
            name: 'payAmount',
            label: '付款金额'
        },
        大写金额: {
            name: '大写金额',
            label: '大写金额'
        },
        付款说明: {
            name: 'rceiptRemark',
            label: '付款说明',
            type: 'input'
        },
        账户名称: {
            name: 'acctName',
            label: '账户名称'
        },
        银行卡号: {
            name: 'bankCardNo',
            label: '银行卡号'
        },
        余额: {
            name: 'balc',
            label: '余额'
        },
        下辖管理单位: {
            name: 'crteOptinsNo',
            label: '下辖管理单位'
        },
        收款账号共: {
            name: 'accountCount',
            label: '收款账号共',
            // type: 'input'
            button: [
                {
                    name: 'look',
                    label: '查看收款账户列表'
                }
            ]
        },
        下辖管理单位_账户名称_银行卡号: {
            name: 'acctName',
            label: '',
            type: 'input',
            placeholder: '下辖管理单位/账户名称/银行卡号'
        },
        开户网点: {
            name: 'oaccDot'
        },
        联行号: {
            name: 'bankCode'
        },
        采购数量_form: {
            name: 'purcCnt',
            label: '采购数量'
        },
        医院采购价_form: {
            name: 'purcpric',
            label: '医院采购价',
            formExtend: {
                type: 'number',
                extend: {
                    placeholder: '请输入',
                    controls: false
                }
            }
        },
        医保耗材编码: {
            name: 'prodCode',
            type: 'input'
        },
        应结算月份: {
            name: 'setlYm',
            type: 'month',
            extend: {
                type: 'month',
                valueFormat: 'yyyy-MM'
            }
        },
        数据来源: {
            name: 'dataSource',
            type: 'select',
            http: {
                url: '/web/trans/base/enums/query_list',
                params: {
                    enumsName: 'DataSourceEnum'
                }
            },
            desc: 'Val'
        },
        创建人: {
            name: 'crterName',
            type: 'input'
        },
        创建时间: {
            name: 'crteTime',
            type: 'daterange',
            extraFields: ['crteTimeBegin', 'crteTimeEnd']
        },
        采购数量: {
            name: 'shppRetnCnt'
        },
        质量层次: {
            name: 'qlv'
        },
        是否启用银行数字证书: {
            name: 'certFlag',
            value: '1',
            type: 'radio',
            readonly: false,
            required: true,
            readonlyShow: true,
            options: [
                {
                    label: '是',
                    value: '1'
                },
                {
                    label: '否',
                    value: '0'
                }
            ]
        },
        银行数字证书: {
            name: 'certFileId',
            type: 'upload',
            extend: {
                limit: 10,
                accept: '.cer,.key,.txt',
                headers: headerConfig()
            },
            required: false,
            readonly: false,
            depend: {
                name: 'certFlag',
                value: ['0'],
                visibleSwitch: true
            }
        },
        '收/退货时间': {
            name: 'shppShpTime',
            type: 'daterange',
            extraFields: ['shppShpStartTime', 'shppShpEndTime']
        },
        交易类型: {
            name: 'setlDetlType',
            type: 'select',
            http: {
                url: '/web/trans/base/enums/query_list',
                params: {
                    enumsName: 'DelivTypeEnum'
                }
            }
        },
        结算状态: {
            desc: 'Val',
            name: 'setlDetlStas',
            label: '结算状态',
            type: 'select',
            http: {
                url: '/web/trans/base/enums/query_list',
                params: {
                    enumsName: 'SetlNodeStasEnum'
                }
            }
        },
        结算方式: {
            name: 'setlWay',
            type: 'select',
            http: {
                url: '/web/trans/base/enums/query_list',
                params: {
                    enumsName: 'SetlwayTypeEnum'
                }
            }
        },
        '医院采购价（元）': {
            name: 'purcpric'
        },
        '收货金额（元）': {
            name: 'shppRetnPrice'
        },
        收货数量: {
            name: 'shppRetnCnt'
        },
        批号N: {
            label: '批号',
            name: 'manuLotNum',
            type: 'input'
        },
        票据号: {
            name: 'selInvoTwo',
            type: 'input'
        },
        默认付款账户: {
            name: 'defAcctFlag',
            value: '1',
            type: 'radio',
            readonly: false,
            required: true,
            readonlyShow: true,
            options: [
                {
                    label: '是',
                    value: '1'
                },
                {
                    label: '否',
                    value: '0'
                }
            ]
        },
        是否启用数字签名: {
            name: 'signFlag',
            value: '1',
            type: 'radio',
            readonly: false,
            required: true,
            readonlyShow: true,
            options: [
                {
                    label: '是',
                    value: '1'
                },
                {
                    label: '否',
                    value: '0'
                }
            ]
        },
        支付方式: {
            name: 'payWay',
            type: 'radio',
            readonly: false,
            required: true,
            readonlyShow: true,
            options: [
                {
                    label: '银企直联',
                    value: '1'
                },
                {
                    label: '多银行支付',
                    value: '2'
                }
            ]
        },
        片区: {
            name: 'admdvsName'
        },
        节假日名称: {
            name: 'restdayName',
            type: 'input'
        },
        名称: {
            name: 'restdayName',
            formExtend: {
                type: 'input'
            }
        },
        时间范围: {
            name: 'reastdayrange',
            formExtend: {
                type: 'daterange',
                extraFields: ['restdayBegintime', 'restdayEndtime']
            }
        },
        对账单生成时间: {
            name: 'genaTime'
        },
        对账天数: {
            name: 'stmtDays'
        }
    }
});
