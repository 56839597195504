import View from '@/components/view';

const routes = [
    {
        path: 'mgr',
        component: View,
        meta: {
            title: '结算方案配置'
        },
        children: [
            {
                path: 'settle-configure',
                component: () => import('@/pages/hxcm/settle/mgr/settle-configure/index'),
                meta: {
                    title: '结算方案配置'
                }
            }
        ]
    }
];
export default routes;
