/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2021-08-20 10:51:28
 * @Description: 错误数据处理
 */
import View from '@/components/view';

const org = () => import('@/pages/hxcm/settle/err-data-deal/drug/org');
const hosp = () => import('@/pages/hxcm/settle/err-data-deal/drug/hosp');
const netCatalog = () => import('@/pages/hxcm/settle/err-data-deal/drug/net-catalog');

const routes = [
    {
        path: 'err-data-deal/drug',
        component: View,
        meta: {
            title: '异常数据处理'
        },
        children: [
            {
                path: 'org',
                component: org,
                meta: {
                    title: '配送生产企业'
                }
            },
            {
                path: 'hosp',
                component: hosp,
                meta: {
                    title: '医疗机构'
                }
            },
            {
                path: 'net-catalog',
                component: netCatalog,
                meta: {
                    title: '挂网目录'
                }
            }
        ]
    }
];
export default routes;
