// 备案采购-耗材-管理机构
import View from '@/components/view';

const secondNegotiation = () => import(/* webpackChunkName: "recordUrchase" */ '@/pages/hxcm/settle/second-negotiation/consumable/secondary-bargaining-manage-list');

const routes = [
    {
        component: View,
        path: 'second-negotiation',
        meta: {
            title: '交易管理'
        },
        children: [
            {
                path: 'consumable/secondary-bargaining-manage-list',
                component: secondNegotiation,
                meta: {
                    title: '议价管理'
                }
            }
        ]
    }
];

export default routes;
