import View from '@/components/view';

import LogManage from '@/pages/hxcm/settle/mgr/log-manage';
import TradeReview from './trade-review';
import CheckingManage from './checking-manage';
import TradingManage from './trading-manage';
import ProjectMaintain from './project-maintain';
import ContractSigning from './contract-signing';
import SettleConfigure from './settle-configure';
import PurchaseCatalogue from './purchase-choosed-catalogue';
import CheckingConfiguration from './checking-configuration';

const routes = [
    {
        path: 'mgr',
        component: View,
        meta: {
            title: '管理端'
        },
        children: [
            ...TradeReview,
            ...CheckingManage,
            ...TradingManage,
            ...ProjectMaintain,
            ...ContractSigning,
            ...SettleConfigure,
            ...PurchaseCatalogue,
            ...CheckingConfiguration,
            {
                path: 'log-manage',
                component: LogManage,
                meta: {
                    title: '日志管理'
                }
            },
            {
                path: 'qualification-warn',
                component: () => import('@/pages/hxcm/settle/mgr/qualification-warn'),
                meta: {
                    title: '企业资质预警'
                }
            },
            {
                path: 'qualification-warn/detail',
                component: () => import('@/pages/hxcm/settle/components/consumable/qualification-detail'),
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'product-qualification',
                component: () => import('@/pages/hxcm/settle/mgr/product-qualification'),
                meta: {
                    title: '产品资质预警'
                }
            },
            {
                path: 'product-qualification/detail',
                component: () => import('@/pages/hxcm/settle/components/consumable/product-qualification-detail'),
                meta: {
                    title: '注册证详情',
                    display: 'none'
                }
            },
            {
                path: 'secondary-bargaining-manage-list',
                component: () => import('@/pages/hxcm/settle/mgr/secondary-bargaining-manage-list'),
                meta: {
                    title: '议价管理'
                }
            },
            {
                path: 'medical-catalog-search',
                component: () => import('@/pages/hxcm/settle/mgr/medical-catalog-search'),
                meta: {
                    title: '医疗机构目录查询'
                }
            }
        ]
    }
];
export default routes;
