/*
 * @Author: qiuwt
 * @Email: qiuwt@hxmec.com
 * @Date: 2020-01-03 12:56:28
 * @Last Modified by: zhujial
 * @Last Modified time: 2023-05-06 14:36:31
 * @Description: 微前端方案
 */

import '@babel/polyfill';
import { setPublicPath } from 'systemjs-webpack-interop';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Element from 'element-ui';
import singleSpaVue from 'single-spa-vue';
import VueSwiper from 'vue-awesome-swiper';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import storeData from '@/store';
import fetch from '@/utils/fetch';
import configs from '@/config';
import '@/styles/custom';
/* eslint-disable */
import Viewer from '@heisea/viewer';
import vViewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

import Components from '@/components/index';
import { getUrlParame } from '@/utils/utils';

import AntdInit from './antd-init';
import 'ant-design-vue/dist/antd.css';
AntdInit();

Vue.use(Components);
Vue.use(VueSwiper);

Vue.use(Viewer);
Vue.use(vViewer);
vViewer.setDefaults({
    Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
});

function init({ publicPath = 'hxcm', routes = {} }, callback) {
    Vue.config.productionTip = false;
    const env = process.env.NODE_ENV;
    Vue.config.silent = env !== 'development';
    let menuObj = {}; // 项目内菜单

    const router = new VueRouter({
        mode: 'history',
        // mode:'hash',
        routes
    });
    // sentry初始化
    if(Vue.config.silent){
        Sentry.init({
            Vue,
            dsn: 'https://f5f7cb48de664c429bc6cd63dc9a1fee@sentry.udplat.com/26',
            environment: env,
            integrations: [
              new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router)
              })
            ],
            tracesSampleRate: 1.0
        });
    }
    Vue.use(VueRouter);
    Vue.use(Element);
    if (callback)callback(Vue, router);
    const store = storeData;
    Vue.mixin({
        methods: {
            $fetch(options) {
                // 避免应用互相污染
                return this.$root.fetch(options);
            }
        }
    });
    Vue.prototype.$bkConfig = configs;
    const appOptions = {
        // eslint-disable-next-line
        render: h => <div id="app" class="global-wrapper">
                <router-view></router-view>
        </div>,
        computed: {
            bkConfig: () => configs,
            upmsUserInfo: () => window.Store?.state?.userInfo
        },
        watch: {
            $route() {
                // 微前端 项目内外处理
                this.initMenu();
            }
        },
        methods: {
            fetch: config => fetch(config),
            initMenu() {
                // 微前端 项目内外处理
                if (window.Store) {
                    const projectId = getUrlParame('itemId');
                    const menu = menuObj[projectId];
                    if (menu) {
                        window.Store.commit('GET_APPLY_MENU', menu);
                    } else if (location.pathname.indexOf('project-inner') !== -1) {
                        window.App.$root.fetch({
                            url: '/hxupsc/api/project/menu/inner',
                            params: {
                                projectId
                            }
                        }).then(res => {
                            if (res) {
                                menuObj[projectId] = res.data;
                                window.Store.commit('GET_APPLY_MENU', res.data);
                            }
                        });
                    } else {
                        const { outer } = menuObj;
                        if (outer) {
                            window.Store.commit('GET_APPLY_MENU', outer);
                        } else {
                            window.App.$root.fetch({
                                url: location.pathname.indexOf('purchasing') !== -1 ? '/hxupsc/api/project/menu/outer' : '/upms/menu'
                            }).then(res => {
                                if (res) {
                                    menuObj.outer = res.data;
                                    window.Store.commit('GET_APPLY_MENU', res.data);
                                }
                            });
                        }
                    }
                }
            }
        },
        store,
        router,
        mounted() {
            menuObj = {}
            this.initMenu()
        }
    };

    // 区分项目内，项目外菜单
    router.beforeEach((to, from, next) => {
        const { path: toPath, query } = to;
        const { itemId, gCode } = query;
        const { itemId: fromItemId, projectId, id, projectFullName, gCode: fGcode } = from.query;
        const token = localStorage.getItem('token');
        const loginPath = ['/login', '/portal', '/aup'];

        const inner = 'project-inner';

        // 回到登录
        if (!token && to.path !== loginPath) {
            next({
                path: loginPath,
                query: {
                    path: to.fullPath
                }
            });
        }
        console.log(itemId, fromItemId, projectId, id, projectFullName)

        // 执行跳转
        if (toPath.indexOf(inner) !== -1 && !itemId && fromItemId) {
            next({
                path: toPath,
                query: {
                    itemId: fromItemId,
                    projectId,
                    gCode: gCode || 'dTrans',
                    id,
                    projectFullName,
                    ...to.query
                }
            });
        } else if (!gCode && (!(to.path === '/login'))) {
            next({
                path: toPath,
                query: {
                    gCode: fGcode || 'dTrans',
                    ...to.query
                }
            });
        } else {
            next();
        }
    });

    let vueLifecycles = {}; // 微前端

    if (env === 'development') {
        window.App = new Vue(appOptions).$mount('#app'); // 手动挂载
    } else {
        setPublicPath(publicPath); // 注册应用
        window.Store.commit('SAVE_ROUTE_DATA', routes); // 保存路由信息到全局
        window.Store.commit('SET_BACK_OUTER', {
            path: '/hxcm/purchasing/list'
        });
        vueLifecycles = singleSpaVue({
            Vue,
            appOptions
        });
    }


    return vueLifecycles;
}

// 项目启动的钩子
export default init;
