import View from '@/components/view';


const administrationList = () => import(/* webpackChunkName: "administrationList" */ '@/pages/hxcm/trade/three-tripartite/drug/administration-list');
const distributionList = () => import(/* webpackChunkName: "distributionList" */ '@/pages/hxcm/trade/three-tripartite/drug/distribution-list');
const medicalList = () => import(/* webpackChunkName: "medicalList" */ '@/pages/hxcm/trade/three-tripartite/drug/medical-list');
const productionList = () => import(/* webpackChunkName: "productionList" */ '@/pages/hxcm/trade/three-tripartite/drug/production-list');
// 新增三方协议
const agreetmentProductList = () => import(/* webpackChunkName: "medicalTreatmentAlliance" */ '@/pages/hxcm/trade/three-tripartite/drug/agreetment-product-list');
// 修改约定量
const agreedQuantity = () => import(/* webpackChunkName: "administrationList" */ '@/pages/hxcm/trade/three-tripartite/drug/agreed-quantity');
// 三方协议-签章
const sign = () => import(/* webpackChunkName: "sign" */ '@/pages/hxcm/trade/three-tripartite/drug/sign');

const routes = [
    {
        path: 'drug/three-tripartite',
        component: View,
        meta: {
            title: '三方协议管理'
        },
        children: [
            {
                path: 'administration-list',
                component: administrationList, // 管理端
                meta: {
                    title: '三方协议管理'
                }
            },
            {
                path: 'administration-list/quantity',
                component: agreedQuantity, // 管理修改约定量
                meta: {
                    title: '修改约定量',
                    display: 'none'
                }
            },
            {
                path: 'distribution-list',
                component: distributionList, // 配送
                meta: {
                    title: '三方协议管理'
                }
            },
            {
                path: 'medical-list',
                component: medicalList, // 医疗
                meta: {
                    title: '三方协议管理'
                }
            },
            {
                path: 'medical-list/add-agreement',
                component: agreetmentProductList, // 医疗新增三方协议
                meta: {
                    title: '选择产品',
                    display: 'none'
                }
            },
            {
                path: 'production-list', // 生产
                component: productionList,
                meta: {
                    title: '三方协议管理'
                }
            },
            {
                path: 'sign',
                component: sign, // 签章
                meta: {
                    title: '签订',
                    display: 'none'
                }
            }

        ]
    }
];

export default routes;