/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2021-06-02 14:30:03
 * @Last Modified by: xujh
 * @Last Modified time: 2021-12-24 10:53:38
 * @Description: Description
 * @Route: /trade/mgr/log-manage
 */
<template>
    <div>
        <bk-list :search-config="searchConfig"
                 :table-config="tableConfig" />
    </div>
</template>

<script>
import field from '@/mixins/field';


export default {
    mixins: [field],
    data() {
        const table = field.getTableInfo(['操作信息Desc', '操作时间', '操作人']);
        const search = field.getFormInfo(['操作信息', '操作人', '操作时间', '所属角色']);
        return {
            searchConfig: {
                layout: 'flow',
                results: {
                    orgType: '2',
                    optTimeBegin: '',
                    optTimeEnd: ''
                },
                clear: true,
                data: [
                    {
                        data: search
                    }
                ]
            },
            tableConfig: {
                selection: false,
                http: {
                    url: '/web/trans/mms/opt-log/page',
                    method: 'post',
                    onSuccess: (res, tb) => {
                        if (res.code === 0) {
                            tb.totalCount = res.data.count;
                        }
                    }
                },
                data: table
            }
        };
    },
    created() {
        const time = new Date();
        const current = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()} 23:59:59`;
        const sevendays = 7 * 24 * 60 * 60 * 1000;
        const before = new Date(time.getTime() - sevendays);
        const beforeTime = `${before.getFullYear()}-${before.getMonth() + 1}-${before.getDate()} 00:00:00`;
        this.searchConfig.results.optTimeBegin = beforeTime;
        this.searchConfig.results.optTimeEnd = current;
    }

};
</script>
