/*
 * @Author: yanshuai
 * @Email: yanshuai@guahao.com
 * @Date: 2021-03-03 10:36:28
 * @Last Modified by: shandan
 * @Last Modified time: 2021-03-11 16:03:46
 * @Description: 耗材 - 支付模块 - 管理官位
 */
import View from '@/components/view';

const paymentSummary = () => import(/* webpackChunkName: "payment-summary" */ '@/pages/hxcm/settle/payment-manage/consumables/management-unit/pay-module/payment-summary');
// 管理单位结算查询
const managementSettlement = () => import(/* webpackChunkName: "management-settlement" */ '@/pages/hxcm/settle/payment-manage/consumables/management-unit/pay-module/management-settlement');
// 配送企业应收汇总
const distributionSummary = () => import(/* webpackChunkName: "distribution-summary" */ '@/pages/hxcm/settle/payment-manage/consumables/management-unit/pay-module/distribution-summary');
// 交易流水查询
const transAction = () => import(/* webpackChunkName: "distribution-summary" */ '@/pages/hxcm/settle/payment-manage/consumables/management-unit/pay-module/transaction');
// 医疗机构应付汇总
const medicalSummary = () => import(/* webpackChunkName: "medical-summary" */ '@/pages/hxcm/settle/payment-manage/consumables/management-unit/pay-module/medical-summary');
// 收款付款管理
const List = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/settle/payment-manage/consumables/management-unit/collection/list');
const PayDetail = () => import(/* webpackChunkName: "createPay" */ '@/pages/hxcm/settle/settlement-payment/consumable/pay-detail');
// 支付管理
const paymenyList = () => import(/* webpackChunkName: "list" */ '@/pages/hxcm/settle/payment-manage/consumables/management-unit/paymeny/list');

export default [
    {
        path: 'management-unit',
        component: View,
        meta: {
            title: '支付模块 - 耗材'
        },
        children: [
            {
                path: 'payment-summary',
                component: paymentSummary,
                meta: {
                    title: '应付汇总'
                }
            },
            {
                path: 'management-settlement',
                component: managementSettlement,
                meta: {
                    title: '管理单位结算查询'
                }
            },
            {
                path: 'distribution-summary',
                component: distributionSummary,
                meta: {
                    title: '配送企业应收汇总'
                }
            },
            {
                path: 'transaction',
                component: transAction,
                meta: {
                    title: '交易流水查询'
                }
            },
            {
                path: 'medical-summary',
                component: medicalSummary,
                meta: {
                    title: '医疗机构应付汇总'
                }
            },
            {
                path: 'collection/list',
                component: List,
                meta: {
                    title: '支付单审核'
                }
            },
            {
                path: 'collection/list/detail',
                component: PayDetail,
                meta: {
                    title: '支付单明细',
                    display: 'none'
                }
            },
            {
                path: 'paymeny/list',
                component: paymenyList,
                meta: {
                    title: '支付管理'
                }
            }
        ]
    }
];
