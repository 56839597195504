import Chosed from '@/pages/hxcm/trade/product/choosed';

const drugRoutes = [
    {
        path: 'chosed',
        component: Chosed,
        meta: {
            title: '中选药品查询'
        }
    }
];
const consumableRoutes = {};
export {
    drugRoutes,
    consumableRoutes
};